@use "settings" as s;
@use "sass:math";
// Default Variables
// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"
// $slick-font-path: "./fonts/" !default;
// $slick-font-family: "slick" !default;
$slick-loader-path: "/pic-labo/" !default;
$slick-arrow-color: s.$color-green !default;
$slick-dot-color: s.$color-green !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "" !default;
$slick-next-character: "" !default;
$slick-dot-character: "" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}
@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}
/* Slider */
.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}
/* Icons */
// @if $slick-font-family == "slick" {
//   @font-face {
//     font-family: "slick";
//     src: slick-font-url("slick.eot");
//     src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
//     font-weight: normal;
//     font-style: normal;
//   }
// }
/* Arrows */
.slick-next,
.slick-prev {
  z-index: 2;
  position: absolute;
  display: block;
  height: 4rem;
  width: 4rem;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;

  &:focus,
  &:hover {
    outline: none;
    background: transparent;
    color: transparent;

    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }

  &:before {
    // font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: calc(calc(calc(100vw - 120rem) / 2) - 4.5rem);
  transform: translateX(125%);

  [dir="rtl"] & {
    left: auto;
    right: calc(calc(calc(100vw - 120rem) / 2) - 4.5rem);
  }

  &:before {
    content: url('/pic-labo/icon_previous.png');

    [dir="rtl"] & {
      content: url('/pic-labo/icon_next.png');
    }
  }
}

.slick-next {
  right: calc(calc(calc(100vw - 120rem) / 2) - 4.5rem);
  transform: translateX(-125%);

  [dir="rtl"] & {
    left: calc(calc(calc(100vw - 120rem) / 2) - 4.5rem);
    right: auto;
  }

  &:before {
    content: url('/pic-labo/icon_next.png');

    [dir="rtl"] & {
      content: url('/pic-labo/icon_previous.png');
    }
  }
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  left: 50%;
  bottom: -2.5rem;
  transform: translateX(-50%);
  list-style: none;
  display: flex;
  width:100%;
  max-width: 112rem;
  @include s.media-sp {
    justify-content: center;
  }
  @include s.media-pc {
    justify-content: flex-end;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @include s.media-intermidiate {
  }

  li {
    position: relative;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-left: 2rem;
    padding: 0;
    cursor: pointer;
    button {
      display: block;
      height: 1rem;
      width: 1rem;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      border: solid .1rem $slick-dot-color;
      border-radius: 50%;
      &:focus,
      &:hover {
        outline: none;
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-active button {
      height: 1.2rem;
      width: 1.2rem;
      background: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
