$sp-design-width: 768;
$pc-design-width: 1500;
$pc-content-width: 120rem;

// color
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #333333;
$color-grey02: #666666;
$color-grey03: #999999;
$color-grey04: #cccccc;
$color-grey05: #eeeeee;
$color-lightgrey: #dddddd;
$color-palegrey: #f6f6f6;
$color-navy: #171C61;
$color-green: #218c3d;
$color-lightgreen: #4dac27;
$color-palegreen: #f1f8ee;
$color-lightgreen02: #e8f3eb;
$color-orange: #ff9900;
$color-beige: #fefaf4;

// typography
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900&display=swap&subset=japanese');
$noto-sans: 'Noto Sans JP', "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP');
$noto-serif: 'Noto Serif JP', "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;

$sans-serif: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, 'Lucida Grande', "Segoe UI", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, Osaka, "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$yuu-gothic: "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$yuu-mincho: "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
