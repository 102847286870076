@use "settings" as s;
@use "sass:math";

/*
  top
----------------------------------*/
/* slider */
section #guide ul {
  margin: 0;
}
.c-topSlider {
  @include s.fullsize;
  &__item {
    @include s.media-sp {
    }
    @include s.media-wideScreen {
      padding: 0 5rem;
    }
    &:not(.slick-current) {
      position: relative;
      &::after {
        @include s.media-sp {
        }
        @include s.media-pc {
          z-index: 1;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: block;
          content: '';
          background-color: s.$color-palegreen;
          opacity: .8;
        }
      }
    }
  }
  &__img {
    display: block;
    width: 100%;
  }
}
.c-topBlock {
  @include s.fullsize;
  @include s.media-sp {
  }
  @include s.media-pc {
  }
  &--voice {
    @include s.media-sp {
      margin-top: 10.5rem;
    }
    @include s.media-pc {
      margin-top: 15rem;
      background: url('/pic-labo/top_voice_bg.png') calc(50vw + 41rem) 10rem / auto 40rem no-repeat;
    }
  }
  &--about {
    @include s.media-sp {
      margin-top: 15rem;
    }
    @include s.media-pc {
      padding-top: 8rem;
      padding-bottom: 3rem;
      margin-top: 13rem;
      background: url('/pic-labo/top_about_bg.png') calc( 50vw - 51.8rem - 44rem ) 0 / auto 36rem no-repeat;
    }
  }
  &--brand, &--news, &--recommend {
    @include s.media-sp {
      background: s.$color-beige url('/pic-labo/top_pattern_bg_sp.png') center center/auto 100% no-repeat;
    }
    @include s.media-pc {
      background: s.$color-beige url('/pic-labo/top_pattern_bg.png') center center/192rem auto no-repeat;
    }
  }
  &--brand {
    margin-top: 15rem;
    padding-top: 14rem;
    padding-bottom: 14rem;
    @include s.media-pc {
      background: s.$color-beige url('/pic-labo/top_pattern_bg_brand.png') center center/192rem auto no-repeat;
    }
  }
  &--news {
    @include s.media-sp {
      padding-top: 14rem;
      padding-bottom: 14rem;
    }
    @include s.media-pc {
      padding-top: 12rem;
      padding-bottom: 12rem;
      background: s.$color-beige url('/pic-labo/top_pattern_bg_news.png') center center/192rem auto no-repeat;
    }
  }
  &--recommend {
    padding-top: 14rem;
    padding-bottom: 14rem;
    @include s.media-pc {
      background: s.$color-beige url('/pic-labo/top_pattern_bg_recommend.png') center center/192rem auto no-repeat;
    }
  }
  &--purpose {
    padding-top: 15rem;
    padding-bottom: 15rem;
    background: url('/pic-labo/top_purpose_bg.png') center center repeat;
  }
  &--campaign {
    @include s.media-sp {
      padding-top: 15rem;
      padding-bottom: 16rem;
    }
    @include s.media-pc {
      margin-top: 15rem;
      padding-bottom: 7rem;
      background: url('/pic-labo/top_campaign_bg_pc.png') calc(50vw + 36rem) 0 / auto 36rem no-repeat;
    }
  }
  &--contents {
    @include s.media-sp {
    }
    @include s.media-pc {
      margin-bottom: 15rem;
      padding-top: 8rem;
      padding-bottom: 10rem;
      background: url('/pic-labo/top_contents_bg.png') calc( 50vw - 52rem - 44.2rem ) 0 / auto 36rem no-repeat;
    }
  }
  &__container {
    &--voice, &--campaign {
      @include s.media-pc {
        display: flex;
        justify-content: space-between;
      }
    }
    &--about, &--contents {
      @include s.media-sp {
      }
      @include s.media-pc {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
  &__inner {
    &--voice {
      @include s.media-sp {
      }
      @include s.media-pc {
        width: math.div(680, 1200) * 100%;
      }
    }
    &--about {
      @include s.media-sp {
      }
      @include s.media-pc {
        width: math.div(620, 1200) * 100%;
      }
    }
    &--campaign {
      @include s.media-sp {
      }
      @include s.media-pc {
        width: math.div(545, 1200) * 100%;
      }
    }
    &--contents {
      @include s.media-sp {
      }
      @include s.media-pc {
        width: math.div(620, 1200) * 100%;
      }
    }
  }
}
.c-topImgHolder {
  @include s.media-sp {
    display: flex;
    align-items: flex-start;
  }
  &--voice {
    @include s.media-sp {
      justify-content: flex-end;
      margin-top: 5rem;
    }
    @include s.media-pc {
      padding-top: 17.5rem;
    }
    img {
      @include s.media-sp {
        width: 61.5rem;
      }
    }
  }
  &--about {
    @include s.media-pc {
      padding-right: 4rem;
    }
    img {
      @include s.media-sp {
        width: 70rem;
      }
    }
  }
  &--campaign {
    @include s.media-sp {
      flex-direction: column;
      align-items: center;
      margin-top: 5rem;
      padding: 8rem 11.5rem 0;
      background: url('/pic-labo/top_campaign_bg_sp.png') right top/35.4rem auto no-repeat;
    }
    @include s.media-pc {
      padding-top: 8rem;
    }
    img {
      @include s.media-sp {
        width: 65.5rem;
      }
    }
  }
  &--contents {
    @include s.media-sp {
      margin-top: 5rem;
    }
    @include s.media-pc {
      padding-right: 4.5rem;
    }
    img {
      @include s.media-sp {
        width: 65rem;
      }
    }
  }
}
.c-topImgLink {
  display: block;
  background-color: #fff;
}
.c-topVoiceList {
  display: flex;
  &:not(:first-child) {
    @include s.media-sp {
      margin-top: 5rem;
    }
    @include s.media-pc {
      margin-top: 3rem;
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
    width: 10.5rem;
    padding-right: 4rem;
  }
  &__en {
    display: block;
    color: s.$color-lightgreen;
    @include s.media-sp {
      font-size: 2.4rem;
    }
    @include s.media-pc {
      font-size: 2rem;
    }
  }
  &__num {
    display: block;
    color: s.$color-lightgreen;
    @include s.media-sp {
      font-size: 5.6rem;
    }
    @include s.media-pc {
      font-size: 5rem;
    }
  }
  &__content {
    flex: 1;
  }
  &__name {
    font-weight: 500;
    @include s.media-sp {
    }
    @include s.media-pc {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
  }
  &__text {
    line-height: 1.75;
  }
}
.c-topAboutText, .c-topcampaignText, .c-topContentsText {
  line-height: 1.75;
}
.c-topBtnHolder {
  margin-top: 5rem;
}
.c-topBrandList {
  display: flex;
  justify-content: center;
  &__item {
    @include s.media-sp {
    }
    @include s.media-pc {
    }
    &:not(:first-child) {
      @include s.media-sp {
        margin-left: 4rem;
      }
      @include s.media-pc {
        margin-left: 8rem;
      }
    }
  }
  &__link {
    transition-duration: .4s;
    @include s.media-sp {
    }
    @include s.media-pc {
    }
    &:hover {
      opacity: .8;
    }
  }
  &__img {
    display: block;
    @include s.media-sp {
      width: 32.4rem;
    }
    @include s.media-pc {
    }
  }
}
.c-topPurposeList {
  display: flex;
  @include s.media-sp {
    flex-wrap: wrap;
  }
  @include s.media-pc {
    justify-content: center;
  }
  &__item {
    @include s.media-sp {
    }
    @include s.media-pc {
      width: calc(100% - 4rem * 4);
    }
    &:not(:first-child) {
      @include s.media-sp {
      }
      @include s.media-pc {
        margin-left: 4rem;
      }
    }
    &:nth-child(2n) {
      @include s.media-sp {
        margin-left: 4rem;
      }
    }
    &:nth-child(n + 3) {
      @include s.media-sp {
        margin-top: 6rem;
      }
    }
  }
  &__link {
    transition-duration: .4s;
    @include s.media-sp {
    }
    @include s.media-pc {
    }
    &:hover {
      opacity: .8;
    }
  }
  &__img {
    display: block;
    @include s.media-sp {
      width: 32.4rem;
    }
    @include s.media-pc {
    }
  }
  &__text {
    display: block;
    @include s.media-sp {
      margin-top: 2rem;
    }
    @include s.media-pc {
      margin-top: 1rem;
      font-size: 1.8rem;
    }
  }
}
.c-topNewsList {
  @include s.media-sp {
  }
  @include s.media-pc {
    display: flex;
    justify-content: center;
  }
  &__item {
    @include s.media-sp {
    }
    @include s.media-pc {
    }
    &:not(:first-child) {
      @include s.media-sp {
        margin-top: 4rem;
      }
      @include s.media-pc {
        margin-left: 8rem;
      }
    }
  }
  &__link {
    transition-duration: .4s;
    @include s.media-sp {
    }
    @include s.media-pc {
    }
    &:hover {
      opacity: .8;
    }
  }
  &__img {
    display: block;
    @include s.media-sp {
      margin: 0 auto;
    }
    @include s.media-pc {
    }
  }
}
.c-topRecommendList {
  display: flex;
  @include s.media-sp {
    flex-wrap: wrap;
  }
  @include s.media-pc {
    justify-content: space-between;
  }
  &__item {
    @include s.media-sp {
    }
    @include s.media-pc {
    }
    &:not(:first-child) {
      @include s.media-sp {
      }
      @include s.media-pc {
        margin-left: 4rem;
      }
    }
    &:nth-child(2n) {
      @include s.media-sp {
        margin-left: 4rem;
      }
    }
    &:nth-child(n + 3) {
      @include s.media-sp {
        margin-top: 4rem;
      }
    }
  }
  &__link {
    transition-duration: .4s;
    @include s.media-sp {
    }
    @include s.media-pc {
    }
    &:hover {
      opacity: .8;
    }
  }
  &__img {
    display: block;
    @include s.media-sp {
      width: 32.4rem;
    }
    @include s.media-pc {
    }
  }
}

#main-column {
  & > section {
    &:nth-of-type(5) {
      background: url('/pic-labo/top_purpose_bg.png') center center repeat;
      @include s.media-sp {
      }
      @include s.media-pc {
        margin-top: 15rem;
      }
    }
  }
  .ranking {
    @include s.media-sp {
      padding: 14.5rem 4rem 8rem !important;
    }
    @include s.media-intermidiate {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include s.media-pc {
      padding-top: 15rem;
      padding-bottom: 12.5rem;
    }
    .column-set {
      max-width: s.$pc-content-width;
      margin: 0 auto;
    }
  }
  .news {
    @include s.media-sp {
      padding: 15rem 4rem !important;
    }
    @include s.media-pc {
      padding: 15rem 0 !important;
    }
  }
  .news-contents {
    max-width: s.$pc-content-width;
    margin: 0 auto;
    @include s.media-sp {
    }
    @include s.media-pc {
      padding-left: 10rem;
      padding-right: 10rem;
    }
  }
  dl.news-box {
    border-bottom: dotted .1rem s.$color-lightgrey;
    @include s.media-sp {
      padding: 3rem 0;
    }
    @include s.media-pc {
      padding: 3rem 1.5rem;
    }
    &:first-child {
      border-top: dotted .1rem s.$color-lightgrey;
    }
    & > dd {
      font-family: s.$sans-serif;
      @include s.media-pc {
        display: flex;
        align-items: flex-start;
      }
    }
    a {
      color: s.$color-green;
      text-decoration: underline;
    }
  }
  .news-box {
    &__date {
      line-height: 1.75;
      letter-spacing: tracking(50);
      @include s.media-sp {
        display: block;
      }
      @include s.media-pc {
        flex: none;
        width: 13.5rem;
      }
    }
    &__title {
      line-height: 1.75;
      letter-spacing: tracking(50);
      @include s.media-sp {
        display: block;
      }
      @include s.media-pc {
        display: inline-block;
      }
    }
    &__latest {
      display: inline-block;
      margin-right: .5rem;
      color: s.$color-orange;
    }
  }
}
