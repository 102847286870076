@use "settings" as s;
@use "sass:math";

/*
  button
----------------------------------*/
.c-btnHolder {
  display: flex;
  @include s.media-sp {
    justify-content: center;
  }
  &--center {
    justify-content: center;
  }
  &--right {
    @include s.media-pc {
      justify-content: flex-end;
    }
  }
}
.c-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition-duration: .4s;
  &:hover {
    opacity: .8;
  }
  &--oval {
    @include s.media-sp {
      height: 8rem;
      padding: 0 4rem;
      border-radius: 4rem;
    }
    @include s.media-pc {
      height: 4rem;
      padding: 0 2rem;
      border-radius: 2rem;
    }
  }
  &--largeOval {
    @include s.media-sp {
      height: 8rem;
      padding: 0 4.5rem;
      font-size: 2.7rem;
      border-radius: 4rem;
    }
    @include s.media-pc {
      height: 6rem;
      padding: 0 3rem;
      font-size: 2rem;
      border-radius: 3rem;
    }
  }
  &--green {
    color: #ffffff !important;
    background-color: s.$color-green;
  }
}
