@use "sass:math";
@use "variables" as v;
@use "functions" as f;

%overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%absolute-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);
}

%absolute-Y-center{
  position: absolute;
  top: 50%;
  transform:translateY(-50%);
}

%container{
  width:100%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
}

%headings {
  font-weight: bold;
}
