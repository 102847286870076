@use "settings" as s;
@use "sass:math";

/*
  mypage
----------------------------------*/

table#enter {
  line-height: 180%;
}
table#enter {
  #center-seo #bread, #center3 #bread, #center-seo .centerbox, #center3 .centerbox {
    line-height: 180%;
    @include s.media-sp {
      font-size: 14px;
    }
  }
}

#center-seo input[type='submit'], #center-seo input[type='button'], #center3 input[type='submit'], #center3 input[type='button'], #contact #center1 .centerbox .mainCont input[type='button'], #contact #center1 .centerbox .mainCont input[type='submit'] {
  @include s.media-sp {
    font-size: 14px;
  }
}

#center-seo h3.title1, #center3 h3.title1 {
  @include s.media-sp {
    font-size: 120%;
  }
}
