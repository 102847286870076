@use "settings" as s;
@use "sass:math";

header {
  border-bottom: solid s.$color-grey04 .2rem;
  @include s.media-sp {
  }
  @include s.media-pc {
  }
}
