@use "settings" as s;
@use "sass:math";

/*
  sidenav
----------------------------------*/
#sub-column {
  @include s.media-sp {
    padding: 14.5rem 3.5rem 9rem;
  }
  @include s.media-pc {
  }
  .sideNav {
    border-bottom: solid .2rem #fff;
    &__title {
      position: relative;
      font-weight: normal;
      color: #fff;
      @include s.media-sp {
        padding: 3rem 5.5rem 3rem 0;
        font-size: 3rem;
      }
    }
    &:not(:first-of-type) {
      .sideNav__title {
        &::after {
          @include s.media-sp {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 3.4rem;
            height: 1.8rem;
            content: '';
            background: url('/pic-labo/icon_arrow_down_white.png') center center/contain no-repeat;
            transition-duration: .4s;
          }
        }
        &.is-active {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
    &__titleLink {
      display: block;
      color: #fff !important;
    }
    &__content {
      display: none;
      height: 0;
      color: #fff;
      opacity: 0;
      &.is-open {
        display: block;
        height: auto;
        opacity: 1;
      }
    }
    &__list {
      color: inherit;
    }
    &__item {
      padding-bottom: 2rem;
    }
    &__link {
      display: block;
      padding: 2.5rem 0 2.5rem 3rem;
      font-size: 2.8rem;
      color: #fff !important;
    }
  }
  .sideSubNav {
    margin-top: 9rem;
    color: #fff;
    &__item {
      font-size: 2.6rem;
      color: inherit;
      &:not(:first-child) {
        margin-top: 3rem;
      }
    }
    &__link {
      color: inherit !important;
    }
  }
  .sideContact {
    margin-top: 9rem;
    margin-bottom: 13rem;
    color: #fff;
    &__title {
      font-size: 2.6rem;
      font-weight: normal;
      color: inherit;
    }
    &__content {
      color: inherit;
    }
    &__link {
      display: block;
      margin-top: 2.5rem;
      font-size: 5.8rem;
      color: inherit !important;
    }
    &__hour {
      display: block;
      margin-top: 2.5rem;
      font-size: 2.6rem;
      color: inherit;
    }
  }
  .sideSNSTitle {
    margin-top: 9rem;
    text-align: center;
    font-size: 2.4rem;
    color: #fff;
  }
  .sideSNS {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    &__item {
      &:not(:first-child) {
        margin-left: 8rem;
      }
    }
    &__link {

    }
    &__img {
      &--instagram {
        width: 5rem;
      }
      &--twitter {
        width: 4.5rem;
      }
      &--facebook {
        width: 2.1rem;
      }
      &--youtute {
        width: 5.9rem;
      }
    }
  }
}
