@use "settings" as s;
@use "sass:math";

/*
  searchbox
----------------------------------*/
.leftSearch {
  color: #fff;
  @include s.media-sp {
  }
  @include s.media-pc {
  }
  #searchbox {
    & > p {
      margin-bottom: 1.5rem;
      & > img {
        filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%);
      }
    }
    form {
      input {
        &[type="image"] {
          background-color: #fff;
          mix-blend-mode: difference;
        }
      }
    }
  }
}
