@use "settings" as s;
@use "sass:math";

/*
  free page
----------------------------------*/
#Journal {
  @extend %container;
  @include s.media-sp {
    margin-top: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @include s.media-intermidiate {
    margin-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include s.media-pc {
    margin-top: 4rem;
  }
  article {
    h2 {
      text-align: center;
      @include s.media-sp {
        padding-bottom: 4rem;
        font-size: 4.6rem;
      }
      @include s.media-pc {
        margin-bottom: 3rem;
        font-size: 3.6rem;
      }
    }
    .journal-cont {
      max-width: 90rem;
      margin: 3rem auto;
      @include s.media-pc {
        margin: 6rem auto;
      }
      a {
        color: s.$color-green;
      }
      h3 {
        margin: 6rem 0 3rem;
        line-height: 1.5;
        @include s.media-sp {
          font-size: 4.2rem;
        }
        @include s.media-pc {
          font-size: 3rem;
        }
      }
      h4 {
        margin: 6rem 0 3rem;
        line-height: 1.5;
        @include s.media-sp {
          font-size: 3.8rem;
        }
        @include s.media-pc {
          font-size: 2rem;
        }
      }
      p, ul, li, dt, dd {
        line-height: 1.75;
        @include s.media-pc {
          font-size: 1.6rem;
        }
      }
      ul {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        li {
        }
        &.list-disc {
          li {
            &::before {
              display: inline-block;
              content: '・';
            }
          }
        }
      }
      ol {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        &.list-step {
          margin-left: 0;
          counter-reset: step;
          li {
            list-style-type: none;
            &:not(:first-child) {
              margin-top: 1.5rem;
            }
            &::before {
              display: block;
              counter-increment: step 1;
              content: 'Step.' counter(step);
              font-size: 1.5em;
            }
          }
        }
      }
      dl {
        &.table-row {
          display: table-row;
          padding: .8rem 0;
          border: 0 none;
          &:not(:first-child) {
            dt {
              @include s.media-sp {
                margin-top: 5rem;
              }
            }
          }
          dt, dd {
            @include s.media-pc {
              display: table-cell;
              padding: 1.6rem 0;
              border-bottom: 1px dotted #ccc;
            }
          }
          dt {
            font-weight: normal;
            @include s.media-pc {
              width: 30%;
            }
          }
        }
      }
    }
  }
}
