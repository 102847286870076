@use "settings" as s;
@use "sass:math";

/*
  itemlist
----------------------------------*/
#itemList .column4 .sps-wishAddItem img, #itemList .column5 .sps-wishAddItem img, #itemList .column4 .sps-wishAddItemOk img, #itemList .column5 .sps-wishAddItemOk img {
  width: 10%;
}
#itemList_wrap {
  position: relative;
  width: 100%;
  @include s.media-sp {
    margin-top: -3rem;
    padding: 0 4rem;
  }
  @include s.media-intermidiate {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include s.media-pc {
    max-width: s.$pc-content-width;
    margin: 0 auto;
  }
  #pager-top {
    z-index: 1;
    position: absolute;
    left: 0;
    padding-top: 0;
    @include s.media-sp {
      top: 18rem;
      padding: 0 4rem;
    }
    @include s.media-intermidiate {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include s.media-pc {
      top: 15rem;
    }
    .selectbox {
      @include s.media-sp {
        margin-bottom: 4rem;
      }
      @include s.media-pc {
        margin-bottom: 3rem;
      }
     .select01 {
       position: relative;
       padding: 0 3.5rem 0 1rem;
       background: url('/pic-labo/icon_arrow_down_dark.png') right 1rem center no-repeat;
       @include s.media-sp {
         width: 100% !important;
         height: 8rem;
         margin: 2rem 0 4rem;
       }
       @include s.media-pc {
         height: 4rem;
         margin-right: 3rem;
       }
     }
   }
  }
  #itemList, #itemList2 {
    h1 {
      @include s.media-sp {
        padding-bottom: 4rem;
        font-weight: 500;
      }
    }
    .column-set {
      @include s.media-sp {
        margin-top: 51rem;
      }
      @include s.media-pc {
        margin-top: 15rem;
      }
    }
  }
  #pager-bm {
    border-top: none;
    margin-top: 5rem;
  }
}
