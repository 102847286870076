@use "settings" as s;
@use "sass:math";

/*
  feature
----------------------------------*/
.c-feature {
  @include s.media-sp {
  }
  @include s.media-pc {
  }
}
