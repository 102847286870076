@use "settings" as s;
@use "sass:math";

/*
  detail
----------------------------------*/
#itemDetail-wrap {
  margin: 4rem auto 12rem !important;
  @include s.media-sp {
    padding: 0 4rem;
  }
  @include s.media-intermidiate {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include s.media-pc {
    max-width: s.$pc-content-width;
  }
  #itemDetail-cont {
    @include s.media-sp {
      margin-bottom: 17rem;
    }
    @include s.media-pc {
      margin-bottom: 12rem;
    }
  }
  .shopping_form {
    @include s.media-pc {
      display: flex;
    }
  }
  .itemThumb-wrap {
    @include s.media-pc {
      width: 50%;
      padding-right: 10rem;
    }
  }
  .itemOutline {
    @include s.media-sp {
    }
    @include s.media-pc {
      width: 50%;
    }
    .fx_price, .price, .spec {
      th, td {
        vertical-align: middle;
        @include s.media-sp {
          padding: 2rem 0 !important;
          font-size: 2.9rem;
        }
        @include s.media-pc {
          padding: 1.5rem 0 !important;
          font-size: 1.8rem;
        }
        input {
          margin-right: .5em;
          line-height: 1;
          @include s.media-sp {
            padding-left: 3rem;
            padding-right: 3rem;
            min-width: 12.8rem;
            font-size: 2.9rem;
          }
          @include s.media-pc {
            padding-left: 2rem;
            padding-right: 2rem;
            min-width: 7.8rem;
            font-size: 1.8rem;
          }
        }
      }
      th {
        @include s.media-sp {
          width: 32rem !important;
        }
        @include s.media-pc {
          width: 25rem !important;
        }
      }
      .selling_price {
        @include s.media-sp {
          font-size: 4.8rem;
        }
        @include s.media-pc {
          font-size: 3rem;
        }
      }
    }
    .cartArea {
      & > div {
        border-bottom: solid .1rem s.$color-grey04;
        @include s.media-sp {
          padding-bottom: 8rem;
        }
        @include s.media-pc {
          padding-bottom: 5rem;
        }
        input[type="image"] {
          display: block;
          margin: 0 auto;
          @include s.media-sp {
            width: 60.8rem;
          }
          @include s.media-pc {
            width: 30rem;
          }
        }
      }
      .variation {
        th, td {
          vertical-align: middle;
        }
        .t-variation {
          th, td {
            min-width: 10rem;
            letter-spacing: s.tracking(50);
            color: s.$color-grey02;
            white-space: nowrap;
            @include s.media-sp {
              padding: 2rem;
              font-size: 2.8rem;
            }
            @include s.media-pc {
              padding: 1.2rem 3rem;
              font-size: 1.6rem;
            }
          }
        }
        tr {
          &:not(.t-variation) {
            th, td {
              line-height: 1.3;
              letter-spacing: s.tracking(50);
              color: s.$color-grey02;
              @include s.media-sp {
                padding: 2rem 1rem;
                font-size: 2.8rem;
              }
              @include s.media-pc {
                padding: 1rem 2rem;
                font-size: 1.6rem;
              }
            }
            td {
              input[type="image"] {
                @include s.media-sp {
                  width: 30rem;
                }
                @include s.media-pc {
                  width: 22rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .sps-wishAddItem, .sps-wishAddItemOk {
    display: block;
    margin: 0 auto;
    @include s.media-sp {
      width: 60.8rem;
    }
    @include s.media-pc {
      width: 36rem;
    }
    img {
      width: 100%;
    }
  }
  .wishBtnHidden {
    display: none !important;
  }
  .cancel {
    a {
      position: relative;
      display: inline-block;
      @include s.media-sp {
        margin-left: 6rem;
      }
      @include s.media-pc {
        margin-left: 3.5rem;
      }
      &::before {
        position: absolute;
        top: 0;
        display: block;
        content: '';
        background: url('/pic-labo/icon_return.png') center center/contain no-repeat;
        @include s.media-sp {
          left: -6rem;
          width: 4.2rem;
          height: 3.6rem;
        }
        @include s.media-pc {
          left: -3.5rem;
          width: 2.6rem;
          height: 2.2rem;
        }
      }
      &::after {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: .1rem;
        content: '';
        background-color: s.$color-grey02;
        @include s.media-sp {
          bottom: -1rem;
        }
        @include s.media-pc {
          bottom: -.5rem;
        }
      }
    }
  }
  .infoArea {
    display: flex;
    justify-content: center;
    li {
      flex: none;
      @include s.media-sp {
        width: 28.8rem;
      }
      @include s.media-pc {
        width: 18rem;
      }
      &:not(:first-child) {
        @include s.media-sp {
          margin-left: 3rem;
        }
        @include s.media-pc {
          margin-left: 1rem;
        }
      }
      img {
        width: 100%;
      }
    }
  }
  .description {
    line-height: 1.4;
    img, center {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .itemDetailSizeBtn {
    display: block;
    @include s.media-sp {
      width: 60.8rem;
      margin: 0 auto 4rem;
    }
    @include s.media-pc {
      width: 36rem;
      margin: 0 auto 2rem;
    }
    img {
      width: 100%;
    }
    &:hover {
      opacity: .8;
    }
  }
}
.itemDetailModal {
  display: none;
  &__bg {
    z-index: 999;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#999, .2);
  }
  &__content {
    z-index: 1000;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 100rem;
    padding: 4rem;
    max-height: 90vh;
    background-color: #fff;
    overflow-y: scroll;
    @include s.media-sp {
    }
    @include s.media-pc {
    }
  }
}
.itemDetailModalLogo {
  @include s.media-sp {
    width: 32rem;
    margin-bottom: 10rem;
  }
  @include s.media-pc {
    width: 20rem;
    margin-bottom: 6rem;
  }
  &__img {
    width: 100%;
  }
}
.itemDetailModalHeading {
  text-align: center;
  @include s.media-sp {
  }
  @include s.media-pc {
  }
  &__icon {
    display: block;
    @include s.media-sp {
      width: 11.2rem;
      margin: 0 auto 5rem;
    }
    @include s.media-pc {
      width: 7rem;
      margin: 0 auto 3rem;
    }
  }
  &__text {
    line-height: 1.75;
    font-weight: 500;
    @include s.media-sp {
      font-size: 5rem;
    }
    @include s.media-pc {
      letter-spacing: s.tracking(50);
      font-size: 3.6rem;
    }
  }
}
.itemDetailModalLead {
  text-align: center;
  @include s.media-sp {
    margin-top: 5rem;
  }
  @include s.media-pc {
    margin-top: 2rem;
  }
  &__text {
    line-height: 1.75;
    letter-spacing: s.tracking(50);
    @include s.media-sp {
      font-size: 3rem;
    }
    @include s.media-pc {
      font-size: 1.8rem;
    }
  }
}
.itemDetailModalForm {
  width: 100%;
  margin: 0 auto;
  border-top: solid .2rem s.$color-green;
  @include s.media-sp {
    margin-top: 5rem;
    padding-top: 8rem;
  }
  @include s.media-pc {
    max-width: 72rem;
    margin-top: 3rem;
    padding-top: 5rem;
  }
  &__text {
    margin: 0 auto;
    letter-spacing: s.tracking(50);
    text-align: right;
    @include s.media-sp {
    }
    @include s.media-pc {
      width: 36.5rem;
      font-size: 2.4rem;
    }
    &:not(:first-child) {
      @include s.media-sp {
        margin-top: 6rem;
      }
      @include s.media-pc {
        margin-top: 4rem;
      }
    }
  }
  &__label {
    display: inline-block;
    letter-spacing: s.tracking(50);
    @include s.media-sp {
      font-size: 3.8rem;
    }
    @include s.media-pc {
      font-size: 2.4rem;
    }
  }
  &__input {
    text-align: left;
    font-size: .8em;
    @include s.media-sp {
      display: inline-block !important;
      width: 19.5rem;
      margin: 0 3rem 0 2.5rem;
    }
    @include s.media-pc {
      display: inline-block;
      width: 12rem;
      margin: 0 2rem 0 1.5rem;
    }
    &__error {
      display: none;
      color: #FF0000;
      &.is-active {
        display: block;
      }
    }
  }
  &__btn {
    @include s.media-sp {
      margin-top: 8rem;
    }
    @include s.media-pc {
      margin-top: 5rem;
    }
  }
}
.itemDetailModalFormResult {
  display: none;
  background-color: s.$color-lightgreen02;
  @include s.media-sp {
    margin-top: 8rem;
    padding: 7.5rem 0 8rem;
    border-radius: 2.5rem;
  }
  @include s.media-pc {
    margin-top: 5rem;
    padding: 5rem 0;
    border-radius: 1.8rem;
  }
  &__heading {
    text-align: center;
    @include s.media-sp {
      margin-bottom: 7rem;
      font-size: 4rem;
    }
    @include s.media-pc {
      margin-bottom: 4rem;
      font-size: 2.4rem;
    }
  }
  &__content {
    text-align: center;
  }
  &__area {
    display: none;
    &.is-active {
      display: inline;
    }
  }
  &__type {
    display: inline-block;
    font-weight: bold;
    @include s.media-sp {
      font-size: 8rem;
    }
    @include s.media-pc {
      font-size: 5rem;
    }
  }
  &__text {
    display: inline-block;
    @include s.media-sp {
      margin-left: 2rem;
      font-size: 4rem;
    }
    @include s.media-pc {
      margin-left: 1.5rem;
      font-size: 2.4rem;
    }
  }
  &__separator {
    display: inline-block;
    @include s.media-sp {
      margin: 0 2rem;
      font-size: 4rem;
    }
    @include s.media-pc {
      margin: 0 1.5rem;
      font-size: 2.4rem;
    }
  }
}
.itemDetailModalFormNote {
  padding-left: 1.2em;
  @include s.media-sp {
    margin-top: 8rem;
  }
  @include s.media-pc {
    margin-top: 4rem;
  }
  &__item {
    text-indent: -1.2em;
    @include s.media-sp {
    }
    @include s.media-pc {
      line-height: 1.75;
      letter-spacing: tracking(50);
      font-size: 1.4rem;
    }
    &::before {
      content: '●';
      color: s.$color-green;
    }
  }
}
.itemDetailModalClose {
  @include s.media-sp {
    margin-top: 8rem;
  }
  @include s.media-pc {
    margin-top: 6rem;
  }
  &__icon {
    display: inline-block;
    @include s.media-sp {
      width: 5rem;
      margin: 0 3rem 0 0 !important;
      font-size: 3rem;
    }
    @include s.media-pc {
      width: 3rem;
      margin: 0 2.5rem 0 0 !important;
      font-size: 2rem;
    }
  }
}
