@use "settings" as s;
@use "sass:math";

/**********************************
  layout
***********************************/

@use "container";
@use "inner";
@use "header";
@use "main";
@use "side";
@use "footer";
@use "mypage";
