@use "settings" as s;
@use "sass:math";

/*
  sideContact
----------------------------------*/
#sub-column {
  @include s.media-sp {
  }
  @include s.media-pc {
    float: none;
    display: none;
  }
}
