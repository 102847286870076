@use "settings" as s;
@use "sass:math";

/*
  global
----------------------------------*/
html {
  font-size: #{(math.div(10,768) * 100) * 1}vw;
  scroll-behavior:smooth;
  @include s.media-min(769px) {
    font-size: #{(math.div(10,1080) * 100) * 1}vw
  }
  @include s.media-min(1088px) {
      font-size: 62.5%;
  }
}
body {
  position: relative;
  font-family: s.$noto-sans;
  font-feature-settings: 'palt' 1;
  color: s.$color-grey;
  @include s.media-sp {
    font-size: 2.8rem;
  }
  @include s.media-pc {
    font-size: 1.6rem;
  }
}
img {
  max-width: 100%;
  height: auto;
  transition-duration: .4s;
}
a, input {
  transition-duration: .4s;
}
