@use "settings" as s;
@use "sass:math";

/*
  text
----------------------------------*/
.u-text-nowrap {
  white-space: nowrap !important;
}

.u-text-wordbreak {
  word-break: break-word !important;
}

.u-text-normal {
  font-weight: normal !important;
}

.u-text-bold {
  font-weight: bold !important;
}

.u-text-align-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

.u-underline {
  text-decoration: underline !important;
}

.u-br {
  &::before {
    content: '\A';
    white-space: pre;
  }
  &-sp {
    @include s.media-sp {
      &::before {
        content: '\A';
        white-space: pre;
      }
    }
  }
  &-pc {
    @include s.media-pc {
      &::before {
        content: '\A';
        white-space: pre;
      }
    }
  }
}
.u-color-orange {
  color: s.$color-orange;
}
