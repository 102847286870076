@use "settings" as s;
@use "sass:math";

/*
  lineup
----------------------------------*/
.c-lineup {
  @include s.media-sp {
  }
  @include s.media-pc {
  }
}
