@use "settings" as s;
@use "sass:math";

/*
  breadcrumb
----------------------------------*/
#bread-crumb {
  display: none;
  // margin: 1.5rem 0;
  @include s.media-sp {
  }
  @include s.media-pc {
  }
}
