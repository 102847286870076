@use "settings" as s;
@use "sass:math";

.l-container {
  @extend %container;
  @include s.media-sp {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @include s.media-intermidiate {
    padding-left: 2rem;
    padding-right: 2rem;
  }
&-pc {
  @include s.media-intermidiate {
    padding-left: 2rem;
    padding-right: 2rem;
  }
    @include s.media-pc {
      width: 100%;
      max-width: 120rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &-sp {
    @include s.media-sp {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}
