// =================== Media Query - Responsive ==================
@mixin min($breakpoint) {
  @media (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin max($breakpoint) {
  @media (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin sp() {
  @include max(768px) {
    @content;
  }
}
@mixin md() {
  @media (min-width: 425px) and (max-width: 579px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 580px) and (max-width: 1079px) {
    @content;
  }
}

@mixin intermidiate() {
  @media (min-width: 769px) and (max-width: 1199px) {
    @content;
  }
}

@mixin pc() {
  @include min(769px) {
    @content;
  }
}

@mixin largeScreen() {
  @include min(1080px) {
    @content;
  }
}

@mixin wideScreen() {
  @include min(1200px) {
    @content;
  }
}


// from original theme

@mixin origin-x-sm {
  @media screen and (max-width: 479px) {
    @content;
  }
}

@mixin origin-sm {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin origin-md {
  @media screen and (max-width: 580px) {
    @content;
  }
}

@mixin origin-lg {
  @media screen and (max-width: 960px) {
    @content;
  }
}
