@use "settings" as s;
@use "sass:math";

/*
  mypage
----------------------------------*/
table#enter {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
