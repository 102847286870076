@use "settings" as s;
@use "sass:math";

/*
  gnav
----------------------------------*/
.gnav {
  @include s.media-sp {
    display: none;
  }
  @include s.media-pc {
    position: relative;
    padding: 1.5rem 0 0;
  }
  &__list {
    @include s.media-pc {
      display: flex;
      justify-content: space-between;
    }
  }
  &__inner {
    z-index: 100;
    position: absolute;
    left: 0;
    top: 100%;
    display: none;
    width: 100%;
    background-color: s.$color-grey05;
    &:hover {
      display: block;
    }
  }
  &__sublist {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  &__subitem {
    flex: none;
    display: flex;
    align-items: center;
    width: 27rem;
    height: 12rem;
    background-color: #fff;
    &:not(:nth-child(4n+1)) {
      margin-left: calc(calc(100% - 27rem * 4) / 3);
    }
    &:nth-child(n + 5) {
      margin-top: 2rem;
    }
  }
  &__sublink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1rem;
    transition-duration: .4s;
    &:hover {
      opacity: .7;
      .gnav__subimg, .gnav__subtitle {
        opacity: .7;
      }
    }
    &--inactive {
      &:hover {
        opacity: 1;
        .gnav__subimg, .gnav__subtitle {
          opacity: 1;
        }
      }
    }
  }
  &__subfig {
    flex: none;
    width: 10rem;
  }
  &__subimg {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  &__subtitle {
    display: block;
    margin-left: 1.5rem;
    line-height: math.div(24, 18);
    letter-spacing: tracking(50);
    font-size: 1.8rem;
  }
  // &__radio {
  //   display: none;
  //   &:checked {
  //     & + .gnav__inner {
  //       display: block;
  //     }
  //   }
  // }
  &__link, &__label, &__btn {
    position: relative;
    display: block;
    padding-bottom: 1.5rem;
    &:hover {
      color: s.$color-green;
      &::after {
        position: absolute;
        bottom: 1rem;
        left: 0;
        display: block;
        width: 100%;
        height: .1rem;
        content: '';
        background-color: s.$color-green;
      }
    }
  }
  &__btn {
    &:hover {
      & + .gnav__inner {
        display: block;
      }
    }
  }
}
