@use "settings" as s;
@use "sass:math";

/*
  cart
----------------------------------*/
// #EST_SPS_cart {
//   .gnav {
//     display: none;
//   }
// }
.cart-article {
  @extend %container;
  @include s.media-sp {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @include s.media-intermidiate {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

#contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td,
#contact .mainFrame .mainCont a,
#contact .mainFrame .mainCont #selectaddressee div.layoutp3 table.layoutp3 tbody td label,
#contact #selectaddressee table.layoutp3 label div .note_nextp,
#contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td,
div.cart-article p {
  line-height: 180%;
  font-size: 1.6rem;
  @include s.media-sp {
    font-size: 14px;
  }
}

.sps-inputTable input[type="checkbox"] {
  transform: translateY(-50%);
}

#contact #selectaddressee table.layoutp3 tbody tr td input {
  transform: translateY(50%);
}
