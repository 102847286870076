@use "settings" as s;
@use "sass:math";

/*
  footer
----------------------------------*/
.footerContainer {
  &--01 {
    @include s.media-sp {
    }
    @include s.media-pc {
    }
  }
  &--02 {
    @include s.media-sp {
    }
    @include s.media-pc {
      display: flex;
      justify-content: space-between;
    }
  }
  &--03 {
    @include s.media-sp {
    }
    @include s.media-pc {
    }
  }
}
.footerCta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8rem 0;
  background-color: s.$color-palegrey;
  &__title {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 3rem;
  }
  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include s.media-sp {
    }
    @include s.media-pc {
    }
  }
  &__icon {
    display: inline-block;
    @include s.media-sp {
      height: 5rem;
      margin-right: 1rem;
    }
    @include s.media-pc {
      margin-right: .5rem;
    }
  }
  &__tel {
    display: inline-block;
    @include s.media-sp {
      font-size: 6.4rem;
    }
    @include s.media-pc {
      font-size: 5.2rem;
    }
  }
  &__hour {
    margin-top: 3rem;
    text-align: center;
    @include s.media-sp {
      font-size: 2.4rem;
    }
    @include s.media-pc {
      font-size: 2rem;
    }
  }
  &__btnHolder {
    @include s.media-sp {
      margin-top: 5rem;
    }
    @include s.media-pc {
      margin-top: 3rem;
    }
  }
}
.footerMain, .footerCopyright {
  color: #fff;
  background-color: s.$color-grey02;
  a {
    color: #fff;
  }
  @include s.media-sp {
  }
  @include s.media-pc {
  }
}
.footerMain {
  @include s.media-sp {
  }
  @include s.media-pc {
    padding: 5rem 0 13rem;
  }
}
.footerNav, .footerSubNav, .footerSNS {
  @include s.media-pc {
    flex: none;
  }
}
.footerNav {
  @include s.media-sp {
  }
  @include s.media-pc {
  }
  &:not(:first-child) {
    @include s.media-sp {
      border-top: solid .1rem s.$color-grey03;
    }
  }
  &:last-of-type {
    @include s.media-sp {
      border-bottom: solid .1rem s.$color-grey03;
    }
  }
  &__title {
    @include s.media-sp {
      position: relative;
      display: flex;
      align-items: center;
      height: 10rem;
    }
    @include s.media-pc {
      font-size: 1.6rem;
    }
    &::after {
      @include s.media-sp {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 3.4rem;
        height: 1.8rem;
        content: '';
        background: url('/pic-labo/icon_arrow_down_white.png') center center/contain no-repeat;
        transition-duration: .4s;
      }
    }
    &.is-active {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  &__titleLink {
    display: block;
    color: #fff !important;
  }
  &__content {
    @include s.media-sp {
      display: none;
    }
    @include s.media-pc {
    }
    &.is-open {
      @include s.media-sp {
        display: block;
        height: auto;
        opacity: 1;
      }
    }
  }
  &__item {
    @include s.media-sp {
    }
    @include s.media-pc {
      margin-top: 2rem;
      font-size: 1.4rem;
    }
  }
  &__link {
    @include s.media-sp {
      display: block;
      padding: 3rem 0;
    }
    @include s.media-pc {
    }
  }
}
.footerSubNav {
  @include s.media-sp {
    margin-top: 9rem;
    display: flex;
    flex-wrap: wrap;
  }
  @include s.media-pc {
  }
  &__item {
    display: flex;
    align-items: center;
    @include s.media-sp {
      width: 50%;
      margin-bottom: 5rem;
    }
    @include s.media-pc {
      font-size: 1.4rem;
    }
    &:not(:first-child) {
      @include s.media-sp {
      }
      @include s.media-pc {
        margin-top: 2rem;
      }
    }
  }
  &__link {
    @include s.media-sp {
    }
    @include s.media-pc {
    }
  }
  &__icon {
    flex: none;
    &--external {
      @include s.media-sp {
        width: 1em;
        margin-left: .5em;
        transform: translateY(-.2em);
      }
      @include s.media-pc {
        height: 1.3rem;
        margin-left: 1.2rem;
        transform: translateY(-.2rem);
      }
    }
  }
}
.footerSNS {
  @include s.media-sp {
    margin-top: 9rem;
    padding-bottom: 3rem;
  }
  @include s.media-pc {
  }
  &__title {
    @include s.media-sp {
      margin-bottom: 3rem;
      text-align: center;
      font-size: 2.4rem;
    }
    @include s.media-pc {
      margin-bottom: .5rem;
      font-size: 1.4rem;
    }
  }
  &__list {
    display: flex;
    @include s.media-sp {
      justify-content: center;
      padding-bottom: 9rem;
    }
    @include s.media-pc {
    }
    &--01 {
      @include s.media-sp {
      }
      @include s.media-pc {
        margin-bottom: 4rem;
      }
    }
    &--02 {
      @include s.media-sp {
      }
      @include s.media-pc {
      }
    }
  }
  &__item {
    flex: none;
    @include s.media-sp {
    }
    @include s.media-pc {
      display: flex;
      align-items: center;
      height: 2.5rem;
    }
    &:not(:first-child) {
      @include s.media-sp {
        margin-left: 8rem;
      }
      @include s.media-pc {
        margin-left: 3rem;
      }

    }
  }
  &__icon {
    @include s.media-pc {
      height: 2.5rem;
    }
  }
}
.footerCopyright {
  @include s.media-sp {
    padding: 3rem 0;
    text-align: center;
  }
  @include s.media-pc {
    padding: 1rem 0;
  }
  &__credit {
    @include s.media-sp {
      font-size: 2rem;
    }
    @include s.media-pc {
      font-size: 1.2rem;
      font-family: s.$yuu-gothic;
      letter-spacing: s.tracking(50);
    }
  }
}
