@use "settings" as s;
@use "sass:math";

/*
  heading
----------------------------------*/
#main-column {
  .c-headingTop {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 0 5rem;
    padding-bottom: 3.5rem;
    line-height: math.div(50, 40);
    font-feature-settings: 'palt' 1;
    @include s.media-sp {
    }
    @include s.media-pc {
    }
    &::after {
      position: absolute;
      bottom: 0;
      display: block;
      width: 4rem;
      height: .4rem;
      content: '';
      background-color: s.$color-green;
    }
    &--left {
      align-items: flex-start;
      &::after {
        left: 0;
        @include s.media-sp {
        }
        @include s.media-pc {
        }
      }
    }
    &--center {
      align-items: center;
      &::after {
        left: 50%;
        transform: translateX(-50%);
        @include s.media-sp {
        }
        @include s.media-pc {
        }
      }
    }
    &__en {
      display: block;
      margin-bottom: 2rem;
      color: s.$color-lightgreen;
      @include s.media-sp {
        font-size: 2.6rem;
      }
      @include s.media-pc {
        font-size: 1.8rem;
      }
    }
    &__jp {
      display: block;
      @include s.media-sp {
        letter-spacing: s.tracking(0);
        font-size: 4.6rem;
      }
      @include s.media-pc {
        letter-spacing: s.tracking(50);
        font-size: 4rem;
      }
    }
    &__jpSmall {
      @include s.media-sp {
        font-size: 2.6rem;
      }
      @include s.media-pc {
        font-size: 2.4rem;
      }
    }
    &__jpSub {
      display: block;
      @include s.media-sp {
        font-size: 2.6rem;
      }
      @include s.media-pc {
        font-size: 2.4rem;
      }
    }
  }
  .ranking h2, .news h2, .newItem h2, .pickup h2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
    line-height: 1;
    font-family: s.$sans-serif !important;
    font-feature-settings: 'palt' 1;
    @include s.media-sp {
      letter-spacing: s.tracking(0);
      font-size: 4.6rem;
    }
    @include s.media-pc {
      padding-bottom: 3.5rem;
      letter-spacing: s.tracking(50);
      font-size: 4rem;
    }
    &::before {
      display: block;
      margin-bottom: 2rem;
      letter-spacing: s.tracking(0);
      color: s.$color-lightgreen;
      @include s.media-sp {
        font-size: 2.6rem;
      }
      @include s.media-pc {
        font-size: 1.8rem;
      }
    }
    &::after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      display: block;
      width: 4rem;
      height: .4rem;
      content: '';
      background-color: s.$color-green;
    }
  }
  .ranking h2 {
    &::before {
      content: 'Ranking';
    }
  }
  .news h2 {
    &::before {
      content: 'Information';
    }
  }
}
article h1 {
  padding-bottom: 5rem;
  line-height: 1.75;
  font-family: s.$sans-serif;
  color: s.$color-grey;
  border-bottom: solid .1rem s.$color-grey04;
  @include s.media-sp {
    font-size: 4.6rem;
  }
  @include s.media-pc {
    margin-bottom: 3rem;
    font-size: 3.6rem;
  }
}
