@use "settings" as s;
@use "sass:math";

/*
  header
----------------------------------*/
.headerInner {
  @include s.media-sp {
    padding: 3rem;
  }
}
.headerTop {
  @include s.media-sp {
    display: none;
  }
  @include s.media-pc {
    padding: 1.8rem 0;
    color: #fff;
    background-color: s.$color-green;
  }
  a {
    color: #fff;
  }
  &__container {
    @include s.media-sp {
    }
    @include s.media-pc {
      display: flex;
      justify-content: space-between;
    }
  }
}
.headerCatchCopy {
  font-size: 1.4rem;
}
.headerTel {
  @include s.media-sp {
  }
  @include s.media-pc {
    display: flex;
    align-items: center;
  }
  &__text, &__hour {
    @include s.media-sp {
    }
    @include s.media-pc {
      display: inline-block;
      font-size: 1.4rem;
    }
  }
  &__num {
    display: inline-block;
    margin: 0 1rem;
    font-size: 2.6rem;
  }
}
.headerMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include s.media-pc {
    padding-top: 1rem;
    padding-bottom: 4rem;
  }
}
.headerLogo {
  @include s.media-sp {
    width: 29rem;
  }
  @include s.media-pc {
    width: 26rem;
    padding-top: 4rem;
  }
}
.headerNavArea {
  @include s.media-sp {
  }
  @include s.media-pc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.headerMainNav {
  display: flex;
  @include s.media-sp {
  }
  @include s.media-pc {
    padding-top: 2.5rem;
  }
  &__item {
    &:not(:first-child) {
      @include s.media-sp {
      }
      @include s.media-pc {
        display: flex;
        align-items: center;
        margin-left: 3rem;
      }
    }
    &:not(:last-child) {
      @include s.media-pc {
      }
    }
    &--guide, &--favorite, &--search {
      @include s.media-sp {
        display: none;
      }
    }
    &--login {
      @include s.media-sp {
        width: 4.4rem;
      }
    }
    &--cart {
      @include s.media-sp {
        width: 5.8rem;
        margin-left: 4rem;
      }
    }
    &--search {
      @include s.media-pc {
        position: relative;
        display: flex;
        align-items: center;
      }
    }
  }
  &__icon {
    display: inline-block;
    margin-right: 1rem;
    &--guide {
      @include s.media-pc {
        width: 1.3rem;
      }
    }
    &--login {
      @include s.media-sp {
        width: 4.4rem;
      }
      @include s.media-pc {
        width: 1.8rem;
      }
    }
    &--favorite {
      @include s.media-pc {
        width: 1.9rem;
      }
    }
    &--cart {
      @include s.media-sp {
        width: 5.8rem;
      }
      @include s.media-pc {
        width: 2.3rem;
      }
    }
    &--search {
      @include s.media-pc {
        width: 2rem;
      }
    }
  }
  &__text {
    @include s.media-sp {
      display: none;
    }
    @include s.media-pc {
      font-size: 1.3rem;
    }
  }
}
.HeaderSearchbox {
  &__form {
    position: absolute;
    right: 0;
    top: calc(100% + 1.5rem);
    display: flex;
    align-items: center;
    opacity: 0;
    transition-duration: .4s;
    &.is-open {
      opacity: 1;

    }
  }
  &__input {
    width: 30rem;
    height: 4rem;
    padding: 0 3.5rem 0 1rem !important;
  }
  &__submit {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    @include s.media-intermidiate {
      font-size: 1.4rem;
    }
  }
  &__text {
    @include s.media-pc {
      z-index: 1;
      position: relative;
      display: block;
      margin-left: 1rem;
    }
    &:hover {
      cursor: pointer;
    }
    // &.is-active {
    //   display: none;
    // }
  }
}
#header-inner {
  #hNav {
    @include s.media-sp {
      display: flex;
      align-items: center;
      width: auto;
      margin: 0;
    }
  }
}
.headerDrawer {
  @include s.media-sp {
    margin-left: 5.3rem;
  }
  @include s.media-pc {
    display: none;
  }
  .spsDrawerClose, .spsDrawerOpen {
    z-index: 1000;
    @include s.media-sp {
      position: relative;
      right: auto;
      top: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 7rem;
      height: 5rem;
      border: none;
    }
  }
  &__line {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: .6rem;
    background-color: s.$color-green;
    transition-duration: .4s;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-child(3) {
      bottom: 0;
    }
  }
  .spsDrawerClose {
  }
  .spsDrawerOpen {
    .headerDrawer__line {
      background-color: #fff;
      &:nth-child(1) {
        transform: rotate(45deg) translateY(2.5rem + .6rem);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translateY(-2.5rem - .6rem);
      }
    }
  }
}
