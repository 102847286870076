@use "settings" as s;
@use "sass:math";

/*
  display
----------------------------------*/
@include s.media-pc{
  .u-only-sp {
    display: none !important;
  }
}

@include s.media-sp{
  .u-only-pc {
    display: none !important;
  }
}

.u-display-block {
  display: block !important;
  &-sp {
    @include s.media-sp {
      display: block !important;
    }
  }
  &-pc {
    @include s.media-pc {
      display: block !important;
    }
  }
}

.u-display-inline-block {
  display: inline-block !important;
  &-sp {
    @include s.media-sp {
      display: inline-block !important;
    }
  }
  &-pc {
    @include s.media-pc {
      display: inline-block !important;
    }
  }
}

.u-display-inline {
  display: inline !important;
  &-sp {
    @include s.media-sp {
      display: inline !important;
    }
  }
  &-pc {
    @include s.media-pc {
      display: inline !important;
    }
  }
}
