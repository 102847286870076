@charset "UTF-8";

@use "settings" as s;
@use "sass:math";

 /* CSS Document */
 /*------------------------------------------------------------
   Browser Default Initialization responsive1
 ------------------------------------------------------------*/
 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
 }
 html {
   overflow-y: scroll;
   height: 100%;
 }
 body {
   margin: 0;
   padding: 0;
   /*font-family: 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
   *font-family: 'メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;*/
   color: #333;
   font-size: 16px;
   line-height: 125%;
   text-align: left;
   -webkit-text-size-adjust: 100%;
   background-color: #FFF;
   height: 100%;
   word-wrap: break-word;
 }
 div, dl, dt, dd, ul, ul li, ol, ol li, h1, h2, h3, h4, h5, h6,
 pre, form, fieldset, p, blockquote, th, td,
 section, nav, article, aside, hgroup, header, address,
 figure, figcaption {
   margin: 0;
   padding: 0;
 }
 address, caption, cite, code, dfn, em, strong, th, var {
   font-style: normal;
   font-weight: normal;
 }
 table {
   border-collapse: collapse;
   border-spacing: 0;
   width: 100%;
 }
 caption, th {
   text-align: left;
 }
 q::before, q::after {
   content: '';
 }
 object, embed {
   vertical-align: top;
 }
 hr {
   display: none;
 }
 h1, h2, h3, h4, h5, h6 {
   font-size: 100%;
 }
 img, abbr, acronym, fieldset {
   border: 0;
 }
 img {
   -ms-interpolation-mode: bicubic;
   border: none;
   vertical-align: top;
   line-height: 0;
 }
 ul li, ol li {
   list-style-type: none;
 }
 input[type="text"], input[type="password"], input[type="number"]  {
   border: 1px solid #A9A9A9;
   box-sizing: border-box;
   font-family: 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
   padding: 0 2%;
 }
 select {
   background-color: #FFF;
   border: 1px solid #A9A9A9;
   box-sizing: border-box;
 }
 textarea {
   border: 1px solid #A9A9A9;
   box-sizing: border-box;
   padding: 2%;
 }
 a {
   outline: none;
   text-decoration: none;
 }
 a:link {
   color: #333;
   text-decoration: none;
 }
 a:visited {
   color: #333;
   text-decoration: none;
 }
 a:hover {
   color: #666;
   text-decoration: none;
 }
 a:hover img {
   opacity: .70;
   filter: alpha(opacity=70);
   -ms-filter: "alpha(opacity=70)";
   -khtml-opacity: .70;
   -moz-opacity: .70;
   cursor: pointer;
   transition: opacity .3s;
 }
 a:active {
   text-decoration: none;
   color: #c00;
 }
 img {
   max-width: 100%;
   height: auto;
   width: auto;
 }
 input[type="button"], input[type="submit"], input[type="image"] {
   -webkit-appearance: none;
   // border-radius: 0;
   box-sizing: border-box;
 }
 input[type="text"], input[type="password"], input[type="number"]  {
   padding: 0 2%;
 }
 input[type="radio"], input[type="checkbox"] {
   position: relative;
   top: 3px;
 }
 #wrapper {
   width: 100%;
   background-color: #FFF;
   min-height: 100%;
   overflow: hidden;
 }
 article h1 {
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   border: none;
   font-size: 140%;
   color: #222222;
   /*border-bottom: 3px solid #222222;*/
   letter-spacing: 0.05em;
   padding: 0px 0 10px;
   margin-bottom: 20px;
 }
 article h2 {
   background: none repeat scroll 0 0 transparent;
   /*border-top: 3px solid #333;*/
   font-size: 80%;
 }
 .w100 {
   width: 100%;
 }
 .bold {
   font-weight: bold;
 }
 #contents-pop {
   width: auto;
 }
 #contents {
   // width: 1200px;
   width: 100%;
   margin: 0 auto;
   // padding-top: 20px;
   padding-top: 0;
   overflow: auto;
   *zoom: 1;
   padding-bottom: 20px;
 }
 // #sub-column {
 //   box-sizing: border-box;
 //   float: left;
 //   margin-right: 40px;
 //   padding-bottom: 80px;
 //   *zoom: 1;
 //   word-break: break-all;
 //   width: 260px;
 // }
 // #main-column {
 //   box-sizing: border-box;
 //   float: right;
 //   width: 900px;
 // }
 #main-column-full {
   width: 100%;
   margin: 0 auto 60px;
 }
 #main-column h2 {
   border: none;
   font-size: 160%;
   color: #222222;
   border: none;
   letter-spacing: 0.1em;
   padding: 0px 0 10px;
   margin-bottom: 30px;
 }
 @include s.media-sp {
   #main-column h2 {
     font-size: 140%;
   }
 }
 article section,
 div#wrapper div#enquete-section,
 div.cart-article div.cart-section {
   padding: 0;
 }
 article section:first-child, div#wrapper div#enquete-section:first-child, div.cart-article div.cart-section:first-child {
   border-top: none;
 }
 article p {
   color: #333;
   font-size: 100%;
 }
 div.cart-article p {
   color: #333;
   font-size: 110%;
 }
 article a:hover p, div.cart-article a:hover p {
   color: #666;
 }
 div#wrapper div#enquete-section ul, div.cart-article div.cart-section ul {
   font-size: 85%;
   line-height: 160%;
   color: #333;
 }
 #mainVisual {
   width: 100%;
 }
 /*パンくずリスト*/
 #bread-crumb {
   margin: 0 0 15px 0;
 }
 #bread-crumb-listTop {
   width: 100%;
 }
 .bread-crumb-listCate {
   padding: 0;
   margin: 0 0 12px 0;
   width: 100%;
 }
 #bread-crumb-listTop ol, .bread-crumb-listCate ol {
   width: 100%;
   margin: 0;
   padding: 0;
   overflow: hidden;
   *zoom: 1;
 }
 #bread-crumb-listTop ol li {
   background: url(../img/breadcrumb_bg.gif) no-repeat left center;
   float: left;
   *float: none;
   *display: inline;
   font-size: 60%;
   letter-spacing: 0.05em;
   padding: 0 10px 0 13px;
   *zoom: 1;
 }
 #bread-crumb-listTop ol li:first-child {
   background: none;
   padding-left: 0;
 }
 .bread-crumb-listCate ol li {
   background: url(../img/breadcrumb_bg.gif) no-repeat left center;
   float: left;
   *float: none;
   *display: inline;
   font-size: 60%;
   letter-spacing: 0.05em;
   padding: 0 10px 0 13px;
   *zoom: 1;
 }
 /*一覧表示*/
 .newItem .column-set, #itemList .column-set,
 .pickup .column-set, .ranking .column-set, .recommend .column-set {
   width: 100%;
   overflow: hidden;
 }
 .column4 {
   float: left;
   width: 19%;
   margin: 0 8% 45px 0;
   padding-bottom: 20px;
 }
 .column4:nth-child(3n+1) {
   clear: none;
 }
 .column4:nth-child(4n) {
   margin: 0 0 20px 0;
 }
 .column4:nth-child(4n+1) {
   clear: both;
 }
 .column5 {
   float: left;
   width: 16%;
   margin: 0 5% 20px 0;
   padding-bottom: 10px;
 }
 .column5:nth-child(4n+1) {
   clear: none;
 }
 .column5:nth-child(5n) {
   margin: 0 0 20px 0;
 }
 .column5:nth-child(5n+1) {
   clear: both;
 }
 .column4 .itemThumb-wrap, .column5 .itemThumb-wrap {
   margin: 0 0 10px 0;
 }
 .column4 .itemThumb, .column5 .itemThumb {
   margin-bottom: 5px;
   text-align: center;
 }
 .column4 .itemThumb a, .column5 .itemThumb a {
   height: 100%;
   width: 100%;
   display: block;
 }
 .column4 .itemThumb a img, .column5 .itemThumb a img {
   height: 100%;
   max-height: 100%;
   max-width: 100%;
   width: 100%;
   /*border: solid 1px #DDD;*/
 }
 .column4 .item-list-span-img {
   display: inline-block;
 }
 .column5 .item-list-span-img {
   display: inline-block;
 }
 .column4 h3, .column5 h3 {
   font-size: 100%;
   line-height: 130%;
   margin-bottom: 15px;
 }
 @include s.media-sp {
   .column4 h3, .column5 h3 {
     font-size: 90%;
   }
 }
 .column4 p, .column5 p {
   font-size: 75%;
   text-align: right;
 }
 .column4 img, .column5 img {
   vertical-align: bottom;
 }
 .column4 p.price, .column5 p.price, .column4 .fixed, .column5 .fixed, .column4 p.fx_price, .column5 p.fx_price {
   text-align: right;
 }
 #main-column .column4 p.price, #main-column .column5 p.price, #main-column .column4 p.sp_price, #main-column .column5 p.sp_price #main-column .column4 p.fx_price, #main-column .column5 p.fx_price, #main-column .column4 p.rg_price, #main-column .column5 p.rg_price {
   font-size: 90%;
   margin-bottom: 10px;
 }
 .column4 p.sp_price, .column5 p.sp_price {
   color: #111111;
   text-align: right;
 }
 p.fx_price span.strike {
   text-decoration: line-through;
 }
 table.variation span.selling_price, table.variation span.special_price {
   white-space: nowrap;
 }
 p.sp_price span.special_price_str, p.sp_price span.selling_price_str,
 p.sp_price span.selling_price, p.sp_price span.taxin, p.sp_price span.discount_rate {
   color: #111111;
 }
 p.price span.selling_price, p.price span.taxin {
   color: #111111;
 }
 p.price span{
   color:#333 !important;
 }
 .column4 p.inner-catch, .column5 p.inner-catch {
   line-height: 150%;
   text-align: left;
   margin-bottom: 7px;
 }
 section .newItem h2, section .pickup h2, section .ranking h2, section .group h2, section .category h2 {
   /*min-height: 25px;*/
 }
 .newItem {
   position: relative;
   margin-bottom: 40px;
 }
 #main-column .newItem h2::after {
   /*content: "New Arrivals";
   display: inline-block;
   font-size: 14px;
   margin-left: 15px;
   */
 }
 .newItem .new-Title {
   position: absolute;
   right: 0px;
   top: 3px;
 }
 .column3 .badge, .column4 .badge, .column5 .badge {
   height: 35px;
   padding: 4px 0 0 4px;
   position: absolute;
   width: 35px;
   z-index: 10;
 }
 .pickup {
   position: relative;
   margin-bottom: 40px;
 }
 #main-column .pickup h2::after {
   /*content: "Pick Up";
   display: inline-block;
   font-size: 14px;
   margin-left: 15px;
   */
 }
 .pickup .pickup-Title {
   position: absolute;
   right: 0px;
   top: 4px;
 }
 .ranking {
   position: relative;
 }
 #main-column .ranking h2::after {
   /*content: "Ranking";
   display: inline-block;
   font-size: 14px;
   margin-left: 15px;
   */
 }
 .ranking .ranking-Title {
   position: absolute;
   right: 0px;
   top: 4px;
 }
 .ranking .column5 {
   margin-bottom: 20px;
 }
 .ranking .column5 .itemThumb {
   position: relative;
 }
 /*センター用2分割フリーエリア*/
 .half-cont_wrap01, .half-cont_wrap02 {
   overflow: hidden;
 }
 .half-cont {
   float: left;
   width: 48%;
   margin-right: 4%;
 }
 .half-cont:nth-child(2n) {
   float: left;
   width: 48%;
   margin-right: 0;
 }
 #main-column .half-cont_wrap01 .half-cont .category h2,
 #main-column .half-cont_wrap02 .half-cont .category h2,
 #main-column .half-cont_wrap01 .half-cont .group h2,
 #main-column .half-cont_wrap02 .half-cont .group h2 {
   padding: 29px 0 40px;
   font-size: 120%;
 }
 /*ショップからのお知らせ*/
 .news {
   padding-bottom: 20px;
   margin-bottom: 30px;
 }
 #main-column .news h2 {
   margin-bottom: 0px;
 }
 #main-column .news h2::after {
   /*content: "NEWS";
   display: inline-block;
   font-size: 14px;
   margin-left: 15px;
   */
 }
 .news .news-contents {
   overflow: hidden;
   padding: 5px 0 0;
 }
 dl.news-box {
   font-size: 85%;
   color: #333;
   padding: 10px 0;
   border-bottom: 1px solid #DDD;
 }
 dl.news-box:first-child {
   /*border-top: 1px dotted #B9B9B9;*/
 }
 .news-box dd {
   line-height: 145%;
 }
 .news-box dd a {
   color: #000;
 }
 .news-box dd a:hover {
   color: #666;
 }
 /*ショップからのお知らせ センターエリア*/
 .w100 .news {
   float: none;
   width: 100%;
   margin-right: 0;
   padding: 0 0 15px;
 }
 .w100 .news h2 {
   display: block;
   width: 100%;
 }
 /*店長日記*/
 #main-column .shopDiary h2::after {
   content: "Shop Diary";
   display: inline-block;
   font-size: 14px;
   margin-left: 15px;
 }
 .freeArea2.shopDiary p {
   font-size: 85%;
 }
 #main-column .freeArea2.shopDiary p a {
   border: 1px solid #333;
   border-radius: 3px;
   box-sizing: border-box;
   display: block;
   font-size: 90%;
   margin: 15px 0 0 80%;
   padding: 0.5%;
   text-align: center;
   width: 20%;
 }
 #main-column .half-cont_wrap01 .half-cont .freeArea2.shopDiary p a,
 #main-column .half-cont_wrap02 .half-cont .freeArea2.shopDiary p a {
   border: 1px solid #333;
   border-radius: 3px;
   box-sizing: border-box;
   display: block;
   font-size: 90%;
   margin: 15px 0 0 66%;
   padding: 1%;
   text-align: center;
   width: 34%;
 }
 #sub-column .shopDiary {
   background: none;
 }
 #sub-column .freeArea2.shopDiary p a {
   border: 1px solid #333;
   border-radius: 3px;
   box-sizing: border-box;
   display: block;
   font-size: 90%;
   margin: 15px 0 0 45%;
   padding: 2% 1%;
   text-align: center;
   width: 55%;
 }
 /*
 .freeArea2 {
   margin-bottom: 20px;
 }
 .freeArea2 .free-banner {
   margin-bottom: 5px;
 }
 .freeArea2 .free-contents p {
   line-height: 160%;
 }
 .w100 .freeArea2 {
   float: none;
   width: 100%;
   padding-bottom: 20px;
   margin-bottom: 30px;
 }
 .freeArea2 .free-contents ul {
   margin: 0px 40px;
 }
 .freeArea2 .free-contents ul li {
   list-style-type: square;
 }
 .freeArea2 .free-contents ol {
   margin: 0px 40px;
 }
 .freeArea2 .free-contents ol li {
   list-style-type: decimal;
 }
 */
 #main-column .group, #main-column .category, #main-column .shopDiary {
   padding-bottom: 20px;
   margin-bottom: 30px;
 }
 #main-column .group h2 {
   font-size: 100%;
 }
 .group .group-Title {
   position: absolute;
   right: 0px;
   top: 4px;
 }
 .group .group-banner {
   margin-bottom: 5px;
 }
 .group ul {
   line-height: 200%;
   margin-top: -5px;
 }
 .group ul li {
   display: inline-block;
   *display: inline;
   *zoom: 1;
   font-size: 100%;
   padding: 0 17px 0 0;
   background: url(../img/slash_000.gif) no-repeat right center;
 }
 .group ul li:last-child {
   background-image: none;
   padding: 0;
 }
 .w100 .group {
   width: 100%;
 }
 .w100 .group .group-Title {
   top: 3px;
 }
 .w100 .group h2 {
   display: block;
   width: 100%;
 }
 .category .category-Title {
   position: absolute;
   right: 0px;
   top: 4px;
 }
 .category .category-banner {
   margin-bottom: 5px;
 }
 .category ul {
   line-height: 110%;
 }
 .category ul li {
   display: inline-block;
   *display: inline;
   *zoom: 1;
   font-size: 75%;
   padding: 0 3px 0 12px;
   background: url(../img/slash_000.gif) no-repeat left center;
 }
 .category ul li:first-child {
   background-image: none;
   padding: 0 3px 0 0;
 }
 .w100 .category {
   width: 100%;
 }
 .w100 .category h2 {
   display: block;
   font-size: 100%;
   width: 100%;
 }
 .category h3 {
   border-bottom: 1px dotted #B9B9B9;
   color: #555;
   font-size: 90%;
   font-weight: bold;
   line-height: 120%;
   padding: 0 0 5px;
   margin: 5px 0 15px;
 }
 .w100 .category ul li {
   font-size: 100%;
 }
 .freeArea {
   width: 100%;
   height: 300px;
   background-color: #EEE;
 }
 // #sub-column h2 {
 //   /*border-top: 3px double #6B6B6B;*/
 //   background: #FFF;
 //   color: #222222;
 //   font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
 //   font-size: 120%;
 //   letter-spacing: 1px;
 //   padding: 0px;
 //   text-align: left;
 //   margin-bottom: 10px;
 // }
 // #sub-column .category h2 a::after {
 //   content: "";
 //   background: url(../img/rsp_arrow01.png);
 //   background-size: cover;
 //   display: inline-block;
 //   height: 16px;
 //   margin: -2px 0 0 8px;
 //   vertical-align: middle;
 //   width: 9px;
 // }
 // #sub-column .group, #sub-column .category, #sub-column #followUs, #sub-column .newItem, #sub-column .link {
 //   border: none;
 //   left: 0;
 //   margin: 0;
 //   padding: 0;
 //   top: 0;
 //   width: 100%;
 // }
 // #sub-column .category {
 //   margin-bottom: 50px;
 // }
 // #sub-column .column-set {
 //   display: block;
 // }
 // #sub-column #sps-itemListAllCategory {
 //   margin-top: 25px;
 // }
 // #sub-column .category h3 {
 //   border: none;
 //   font-size: 80%;
 //   font-weight: bold;
 //   padding: 0;
 //   margin: 0 0 7px;
 // }
 // #sub-column .group nav, #sub-column .category nav, #sub-column .link nav {
 //   border-top: none;
 //   margin-bottom: 25px;
 // }
 // #sub-column .group nav ul, #sub-column .category nav ul, #sub-column .link nav ul {
 //   line-height: 160%;
 // }
 // #sub-column .group nav ul li, #sub-column .category nav ul li, #sub-column .link nav ul li {
 //   background-image: none;
 //   border-bottom: 1px solid #D4D4D4;
 //   background-color: #FFF;
 //   display: block;
 //   font-size: 90%;
 //   padding: 0;
 // }
 // #sub-column .group nav ul li:first-child, #sub-column .category nav ul li:first-child, #sub-column .link nav ul li:first-child {
 //   border-top: 1px solid #D4D4D4;
 // }
 // #sub-column .category section#sps-itemListAllCategory section:last-child nav ul li {
 //   border-bottom: none;
 // }
 // #sub-column .link nav ul li {
 //   line-height: 180%;
 // }
 // #sub-column .group #sps-itemListAllCategory section:first-child nav ul li.listcat1:first-child, #sub-column .category #sps-itemListAllCategory section:first-child nav ul li.listcat1, #sub-column .link #sps-itemListAllCategory section:first-child nav ul li.listcat1:first-child {
 //   border-top: 1px solid #111;
 // }
 // #sub-column .group nav ul li.listcat1, #sub-column .category nav ul li.listcat1, #sub-column .link nav ul li.listcat1 {
 //   border-top: none;
 // }
 // #sub-column .keyword nav ul {
 //   padding: 5px 0;
 // }
 // #sub-column .group nav ul li a, #sub-column .category nav ul li a, #sub-column .link nav ul li a {
 //   color: #222222;
 //   display: block;
 //   padding: 10px 5px 10px 15px;
 //   position: relative;
 //   line-height: 130%;
 // }
 // #sub-column .group nav ul li a::after, #sub-column .category nav ul li a::after, #sub-column .link nav ul li a::after {
 //   content: "";
 //   background: url(../img/rsp_arrow02.png);
 //   background-size: cover;
 //   display: inline-block;
 //   height: 13px;
 //   margin: -2px 0 0 0px;
 //   position: absolute;
 //   left: 1%;
 //   top: 39%;
 //   vertical-align: middle;
 //   width: 8px;
 // }
 // #sub-column .group nav ul li a:hover, #sub-column .group nav ul li a:active, #sub-column .category nav ul li a:hover, #sub-column .category nav ul li a:active, #sub-column .link nav ul li a:hover, #sub-column .link nav ul li a:active, #sub-column .sitelink a:hover {
 //   opacity: .70;
 //   filter: alpha(opacity=70);
 //   -ms-filter: "alpha(opacity=70)";
 //   -khtml-opacity: .70;
 //   -moz-opacity: .70;
 // }
 // #sub-column .category nav ul li.cat2 a {
 //   zoom: 1;
 //   display: block;
 //   padding: 3px 2px 3px 30px;
 //   margin: 0px 0;
 //   line-height: 100%;
 //   color: #000;
 // }
 #snav_calendar h2 {
   background-image: none;
 }
 #subc_calendar {
   background-color: #EFEFEF;
   margin-bottom: 20px;
   width: 100%;
 }
 div.subc_cal_cont {
   background-color: #FFF;
 }
 div#subc_calendar h2 {
   padding: 20px 0 10px;
   position: relative;
   text-align: left;
   background: none;
   color: #333;
   display: block;
 }
 div#subc_calendar h2::before {
   content: "CALENDAR";
 }
 div#subc_calendar h2::after {
   bottom: 0;
   content: "カレンダー";
   font-size: 80%;
   height: initial;
   left: 0;
   line-height: initial;
   position: absolute;
   right: 0;
   top: 39px;
 }
 div.cal_wrapper {
   padding: 7px 2% 0;
 }
 div.cal_wrapper table.cal {
   width: 100%;
   line-height: 130%;
 }
 div.cal_wrapper table.cal th {
   height: 17px;
   position: relative;
 }
 div.cal_wrapper table.cal tr th p.month {
   font-size: 88%;
   position: absolute;
   text-align: center;
   top: -2px;
   width: 100%;
 }
 div.cal_wrapper table.cal tr th input {
   border: none;
   z-index: 1;
 }
 div.cal_wrapper table.cal tr th input.prev {
   background: url(../img/calendar_pleft.gif) no-repeat left bottom #FFF;
   height: 17px;
   position: absolute;
   left: 0;
   top: 0;
   vertical-align: middle;
   width: 17px;
 }
 div.cal_wrapper table.cal tr th input.next {
   background: url(../img/calendar_pright.gif) no-repeat right bottom #FFF;
   height: 17px;
   position: absolute;
   right: 0;
   top: 0;
   vertical-align: middle;
   width: 17px;
 }
 div.cal_wrapper table.cal tr td {
   border: solid 1px #CCC;
   font-size: 70%;
   text-align: center;
 }
 div.cal_wrapper table.cal tr.headline td {
   border: none;
   padding: 10px 0 2px 0;
 }
 div.subc_cal_cont .cap {
   font-size: 60%;
   padding: 0 2%;
   width: auto;
 }
 div.subc_cal_cont .cap ul {
   margin: 3px 0 0;
 }
 div.subc_cal_cont .cap li {
   display: inline-block;
   line-height: 140%;
   overflow: hidden;
 }
 div.subc_cal_cont .cap li span {
   margin: 0 10px 0 0;
 }
 div.subc_cal_cont .cap .ico_cap01 {
   font-size: 120%;
   vertical-align: middle;
   margin: 0 2px 0 0;
   color: #FF5078;
 }
 div.subc_cal_cont .cap .ico_cap02 {
   font-size: 120%;
   vertical-align: middle;
   margin: 0 2px 0 0;
   color: #39F;
 }
 div.subc_cal_cont .cap .cap-info {
   background-color: #FFF;
   line-height: 140%;
   padding: 10px 3%;
 }
 div.cal_wrapper table.cal tr td div.Deli {
   background-color: #EFE;
 }
 div.cal_wrapper table.cal tr td div.Holyday {
   background-color: #FF5078;
   color: #FFF;
 }
 div.cal_wrapper table.cal tr td div.backward {
   color: #CCC;
   font-weight: normal;
   background-color: #FFF;
 }
 div.cal_wrapper table.cal tr td div.pointer {
   cursor: pointer;
 }
 div.cal_wrapper table.cal tr td div.pointer:hover {
   background-color: #EEE;
 }
 #sub-column #snav_mailMag {
   background-color: #F5F5F5;
   margin-bottom: 20px;
   width: 100%;
 }
 #sub-column #snav_mailMag h2 {
   background-image: none;
 }
 #sub-column div.snav_mailMag_cont {
   border-top: 3px double #6B6B6B;
   padding: 15px 4%;
   text-align: center;
 }
 #sub-column div.snav_mailMag_cont::before {
   content: "Mail Magazine";
   font-weight: bold;
 }
 #sub-column div.snav_mailMag_cont h2 {
   width: 161px;
   text-align: center;
   padding: 0 0 3px 0;
   margin: 0 0 7px 0;
   border-bottom: 1px dotted #333;
 }
 #sub-column div.snav_mailMag_cont p {
   font-size: 72%;
   line-height: 130%;
 }
 #sub-column div.snav_mailMag_cont > p:first-child {
   padding: 5px 0 10px;
   text-align: left;
 }
 #sub-column div.snav_mailMag_cont > p:first-child::before {
   content: "メールマガジンのご登録";
   display: block;
   font-weight: bold;
   padding-bottom: 20px;
   text-align: center;
 }
 #sub-column div.snav_mailMag_cont input.mailAddress {
   border: 1px solid #777;
   box-sizing: border-box;
   display: inline-block;
   height: 40px;
   position: relative;
   vertical-align: middle;
   width: 78%;
 }
 #sub-column div.snav_mailMag_cont input.submit_mailMag {
   background-color: #333;
   border: none;
   box-sizing: border-box;
   color: #FFF;
   font-size: 75%;
   margin-left: -5px;
   height: 40px;
   text-align: center;
   width: 20%;
 }
 #sub-column div.snav_mailMag_cont input.submit_mailMag:hover {
   background-color: #666;
 }
 #sub-column div.snav_mailMag_cont p:last-child {
   background-color: #FFF;
   border: 1px solid #333;
   border-radius: 3px;
   box-sizing: border-box;
   height: 3em;
   line-height: 3em;
   margin: 15px 0 10px 34px;
 }
 #sub-column div.snav_mailMag_cont p:last-child::after {
   content: ">>";
   padding-left: 3px;
 }
 #sub-column #snav_followUs {
   border-top: 3px double #6B6B6B;
   padding: 19px 2%;
   margin-bottom: 20px;
 }
 #sub-column #snav_followUs nav ul {
   overflow: hidden;
 }
 #sub-column #snav_followUs nav ul li {
   float: left;
   margin: 0 5px 0 0;
   padding: 0;
 }
 #sub-column #snav_followUs nav ul li img {
   vertical-align: bottom;
 }
 #sub-column #snav_followUs nav ul li a:hover img {
   opacity: .70;
   filter: alpha(opacity=70);
   -ms-filter: "alpha(opacity=70)";
   -khtml-opacity: .70;
   -moz-opacity: .70;
   cursor: pointer;
 }
 #subc_freeArea {
   margin: 0 0 20px 0;
   padding-bottom: 10px;
 }
 #subc_freeArea img {
   margin-bottom: 10px;
 }
 #sub-column .newItem {
   position: relative;
   margin: 0;
   line-height: 90%;
 }
 #sub-column .newItem .new-Title {
   position: absolute;
   right: 0px;
   top: 1px;
 }
 #sub-column .column4, #sub-column .column5 {
   box-sizing: border-box;
   padding: 0 20px 10px;
   margin: 0 0 25px 0;
   float: none;
   width: 100%;
 }
 #sub-column .newItem .column4 .itemThumb-wrap {
   margin: 0 0 10px;
 }
 #sub-column .newItem .column4 .itemThumb {
   height: auto;
   width: 100%;
   display: table-cell;
   text-align: center;
   vertical-align: middle;
 }
 #sub-column .newItem .column4 .itemThumb a img {
   max-height: auto;
   max-width: 100%;
   width: 100%;
 }
 #sub-column .newItem h3 {
   text-align: left;
   font-weight: normal;
 }
 #sub-column .newItem p.price, #sub-column .pickup p.price, #sub-column .ranking p.price, #sub-column .recommend p.price {
   text-align: right;
   margin: 0;
 }
 #sub-column .newItem p.sp_price {
   text-align: right;
 }
 #sub-column .newItem p.sp_price {
   color: #C00;
 }
 #sub-column .newItem .column4_fourth .itemThumb {
   margin: 0 20px 5px 20px;
   position: relative;
 }
 #sub-column .pickup {
   position: relative;
   line-height: 90%;
 }
 #sub-column .pickup .pickup-Title {
   position: absolute;
   right: 0px;
   top: 1px;
 }
 #sub-column .pickup .column5 .itemThumb-wrap {
   margin: 0 0 10px;
 }
 #sub-column .pickup .column5 .itemThumb {
   margin: 0 0 5px 0;
   position: relative;
   text-align: center;
   height: auto;
   width: 100%;
 }
 #sub-column .pickup .column5 .itemThumb img {
   max-height: 100%;
   max-width: 100%;
 }
 #sub-column .ranking {
   position: relative;
   line-height: 90%;
 }
 #sub-column .ranking .ranking-Title {
   position: absolute;
   right: 0px;
   top: 1px;
 }
 #sub-column .ranking .column5 .itemThumb-wrap {
   margin: 0 0 10px;
   height: 100%;
   width: 100%;
 }
 #sub-column .ranking .column5 .itemThumb {
   margin-bottom: 5px;
   position: relative;
   text-align: center;
 }
 #sub-column .ranking .column5 .itemThumb img {
   max-height: 100%;
   max-width: 100%;
 }
 #sub-column .ranking .column5 .itemThumb {
   margin-bottom: 5px;
   position: relative;
 }
 /*
 div#cart-footer,
 footer {
   background-color: #222222;
   clear: both;
   margin:0 auto;
   line-height: 100%;
   position: relative;
   width: 100%;
 }
 #footer-inner {
   background-color: #222222 !important;
   width: 1200px;
   margin: 0 auto;
   overflow: hidden;
 }
 footer #footer-inner{
 }
 div#cart-footer #footer-inner .footer_gnavi,
 footer #footer-inner .footer_gnavi {
   float: left;
   padding:20px;
 }
 div#cart-footer #footer-inner .footer_gnavi ul li,
 footer #footer-inner .footer_gnavi ul li {
   display: inline-block;
   *display: inline;
   *zoom: 1;
   *margin-bottom: 12px;
   padding: 0 3px 0 18px;
   line-height: 100%;
   font-size: 60%;
   color: #999;
   background: url(../img/slash_ccc.gif) no-repeat left center;
 }
 div#cart-footer #footer-inner .footer_gnavi ul li:first-child,
 footer #footer-inner .footer_gnavi ul li:first-child {
   background-image: none;
   padding: 0 3px 0 0;
 }
 div#cart-footer #footer-inner .footer_gnavi ul li a,
 footer #footer-inner .footer_gnavi ul li a {
   color: #FFF;
 }
 div#cart-footer div.cart-nav,
 footer nav, #copyright {
   float: right;
   font-size: 67%;
   text-align: right;
   color: #FFF;
   padding:20px
 }
 #copyright ul li {
   color: #FFF;
   background-image: none;
   padding: 0;
   text-align: center;
 }
 div#cart-footer #footer-inner .footer_gnavi ul li a:hover,
 footer #footer-inner .footer_gnavi ul li a:hover {
   color: #FFF;
   text-decoration: underline;
 }
 */
 /*-トップイメージ -*/
 section #guide p {
   font-size: 90%;
   line-height: 175%;
   margin: 5px 0 10px;
 }
 section #guide .guide01 {
   padding-bottom: 10px;
 }
 section #guide .guide01 h2 {
   background: none;
   border-bottom: 1px dotted #6B6B6B;
   border-top: none;
   height: 2.3em;
   line-height: 2.3em;
   padding: 0;
   font-size: 110%;
   margin-bottom: 10px;
 }
 section #guide .guide01 ul {
   font-size: 90%;
   list-style-type: disc;
   margin-top: 10px;
 }
 section #guide .inner {
   overflow: hidden;
   margin-bottom: 10px;
 }
 section #guide .inner .fL {
   float: left;
   width: auto;
   margin-right: 20px;
   max-width: 350px;
 }
 section #guide .inner .fR {
   float: right;
   width: auto;
   max-width: 350px;
 }
 section #guide .chart .ttlShop {
   font-size: 80%;
   font-weight: bold;
   border: none;
   margin: 15px 0 0;
 }
 section #guide table {
   line-height: 120%;
   border-top: 1px solid #CCC;
   table-layout: fixed;
 }
 section #guide table th {
   background-color: #EEE;
   border-top: 1px solid #CCC;
   color: #2B2B2B;
   font-size: 75%;
   font-weight: bold;
   padding: 15px 20px;
   width: 100px;
 }
 section #guide table td {
   font-size: 75%;
   border-top: 1px solid #CCC;
   padding: 15px 20px;
   width: 530px;
 }
 section #guide table tr:last-child {
   border-bottom: 1px solid #CCC;
 }
 #shopGuide_wrap section #guide table td {
   width: 50%;
 }
 section #guide table th.shopGuidePointClass {
   width: 50%;
 }
 section #guide .sps-shoppingGuide-rankList table th,
 section #guide .sps-shoppingGuide-rankList table td {
   box-sizing: border-box;
   width: 33%;
   padding: 15px 10px;
 }
 section #guide .sps-shoppingGuide-rankList table th:nth-child(2),
 section #guide .sps-shoppingGuide-rankList table td:nth-child(2) {
   width: 34%;
 }
 section #guide ul {
   margin: 0px 40px;
 }
 section #guide ul li {
   list-style-type: square;
 }
 section #guide ol {
   margin: 0px 40px;
 }
 section #guide ol li {
   list-style-type: decimal;
 }
 /*-- 店長日記/フリーページ --*/
 /*日付/コメント左寄せ 記事一覧*/
 .state_l {
   border-top: 1px dotted #333;
   padding: 10px 0 0;
   font-size: 65%;
 }
 /*日付/コメント右寄せ*/
 .state_r {
   border-top: 1px dotted #333;
   padding: 10px 0 0;
   margin: 0 0 25px;
   text-align: right;
   font-size: 65%;
 }
 .state_l ul, .state_r ul {
   line-height: 90%;
 }
 .state_l li, .state_r li {
   display: inline-block;
   *display: inline;
   *zoom: 1;
   padding: 0 3px 0 0;
 }
 .state_l li.comment, .state_r li.comment {
   border-left: 1px solid #333;
   padding: 0 0 0 5px;
 }
 .state_l li.cate, .state_r li.cate {
   border-left: 1px solid #333;
   padding: 0 5px;
 }
 .state_l li.name {
   border-right: 1px solid #333;
   padding: 0 5px 0;
   margin: 0 5px 0 0;
 }
 /*コメントをするボタン*/
 #Journal .more {
   background-color: #333;
   border-radius: 3px;
   box-sizing: border-box;
   color: #FFF;
   cursor: pointer;
   display: block;
   width: 160px;
   height: 40px;
   font-size: 75%;
   font-size: 0.75rem;
   text-align: center;
   vertical-align: middle;
   margin: 10px 0 0;
   padding: 10px 0;
 }
 #Journal a .more:hover {
   width: 160px;
   height: 40px;
   background-color: #666;
 }
 #Journal .more img {
   padding: 0 5px 0;
   vertical-align: middle;
 }
 #JournalList .comment-t, #Journal .comment-t {
   font-size: 80%;
   font-weight: bold;
   margin: 0 0 10px;
 }
 #Journal .comment-cont {
   margin: 0 0 15px;
 }
 #Journal fieldset {
   margin-bottom: 5px;
 }
 #Journal legend {
   background: none repeat scroll 0 0 transparent;
   font-size: 75%;
   line-height: 200%;
   padding: 0;
   width: 100%;
 }
 #Journal fieldset p {
   margin-bottom: 5px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 #Journal fieldset input {
   margin: 0 3px 2px 0;
 }
 #Journal input.name {
   width: 300px;
   height: 40px;
   margin: 0;
   padding: 5px;
 }
 #Journal input.url {
   width: 300px;
   height: 40px;
   margin: 0;
   padding: 5px;
 }
 #Journal textarea {
   width: 360px;
   height: 100px;
   margin: 0;
   padding: 5px;
 }
 #Journal .btn-confirm {
   margin: 10px 0 0 120px;
 }
 /*投稿するボタン*/
 input[type="submit"].btn-confirmPost {
   background-color: #333;
   border: 1px solid #333;
   border-radius: 3px;
   color: #FFF;
   height: 40px;
   padding: 10px 0;
   font-size: 80%;
   font-size: 0.8rem;
   text-align: center;
   width: 120px;
 }
 input[type="submit"].btn-confirmPost:hover {
   background-color: #666;
 }
 #comment-form #comment-post {
   margin: 0;
 }
 #comment-form #comment-post h2 {
   background: none;
   border-bottom: 1px dotted #6B6B6B;
   border-top: none;
   height: 2.3em;
   line-height: 2.3em;
   padding: 0;
 }
 #comment-form #comment-post .comment-cont {
   padding: 10px 0;
 }
 #comment-form legend {
   background: none repeat scroll 0 0 transparent;
   font-size: 75%;
   line-height: 200%;
   padding: 0;
   width: 100%;
   font-weight: bold;
 }
 #comment-form p {
   margin-bottom: 15px;
 }
 #comment-form .name {
   width: 215px;
   height: 16px;
 }
 #comment-form .comment-txt {
   width: 360px;
   padding: 0;
   height: auto;
 }
 #comment-post {
   margin: 35px 0;
 }
 .comment-box {
   margin: 30px 0 0;
 }
 div.caution p {
   color: #d51414;
   font-size: 70%;
   line-height: 145%;
 }
 input[type="button"].btn-confirmBack {
   background-color: #333;
   border: 1px solid #333;
   border-radius: 3px;
   color: #FFF;
   height: 40px;
   padding: 10px 0;
   font-size: 80%;
   font-size: 0.8rem;
   text-align: center;
   width: 120px;
 }
 input[type="button"].btn-confirmBack:hover {
   background-color: #666;
 }
 @-moz-document url-prefix() {
   .btn-confirmPost {
     padding: 0 5px 2px;
   }
   .btn-confirmBack {
     padding: 0 2px 2px 8px;
   }
 }
 #JournalList h2, #Journal h2 {
   font-size: 85%;
 }
 #JournalList .journal-cont, #Journal .journal-cont {
   margin: 0 0 15px;
 }
 #JournalList article {
   margin: 0 0 40px;
 }
 #JournalList .journal-cont ul, #Journal .journal-cont ul {
   margin: 0px 40px;
 }
 #JournalList .journal-cont ul li, #Journal .journal-cont ul li {
   list-style-type: square;
 }
 #JournalList .journal-cont ol, #Journal .journal-cont ol {
   margin: 0px 40px;
 }
 #JournalList .journal-cont ol li, #Journal .journal-cont ol li {
   list-style-type: decimal;
 }
 /*-- サイトマップ --*/
 section#document_wrap h2 {
   background: none;
   border-bottom: 1px dotted #6B6B6B;
   border-top: none;
   font-size: 85%;
   font-weight: bold;
   height: 3em;
   line-height: 3em;
   margin: 0 0 20px;
   padding: 0;
 }
 section#document_wrap h3 {
   font-size: 75%;
 }
 section #document li {
   font-size: 75%;
   font-size: 0.75rem;
 }
 section #document .txt-list01 {
   padding-bottom: 30px;
 }
 section #document .txt-list01 li.c1 {
   display: inline-block;
   *display: inline;
   *zoom: 1;
   padding: 0 25px 0 0;
 }
 section #document .txt-list02 {
   padding-bottom: 10px;
   overflow: hidden;
 }
 section #document .txt-list02 h3 {
   font-weight: bold;
 }
 section #document .txt-list02 section.tree00 {
   float: left;
   padding: 0 35px 20px 0;
   width: 156px;
 }
 section #document .txt-list02 ul.tree01 {
   padding: 0 0 0 12px;
   overflow: hidden;
 }
 section #document .txt-list02 ul.tree01 li.c2 {
   padding: 0 0 0 14px;
   margin: 0 0 0 3px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 section #document .txt-list02 ul.tree01 li.c3 {
   padding: 0 0 0 28px;
   margin: 0 0 0 3px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 section #document .txt-list02 ul.tree01 li.c4 {
   padding: 0 0 0 42px;
   margin: 0 0 0 3px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 section #document .txt-list02 ul.tree01 ul.tree02 li.c5 {
   padding: 0 0 0 56px;
   margin: 0 0 0 3px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 section #document .txt-list02 ul.tree01 li.sp1 {
   float: left;
   padding: 0 0 0 14px;
   margin: 0 0 0 3px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 section #document .txt-list02 ul.tree01 li.sp2 {
   float: left;
   padding: 0 0 0 2px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 section #document .txt-list02 ul.tree01 li.sp3 {
   float: left;
   padding: 0 0 0 2px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 section #document .txt-list02 ul.tree01 ul.tree02 li.c5 a {
   display: inline;
   *display: inline;
   *zoom: 1;
   padding: 0 10px 0 0;
   font-size: 110%;
   font-size: 0.75rem;
 }
 section #document .txt-list02 ul.iB {
   padding: 5px 0 0 12px;
   float: left;
   line-height: 120%;
 }
 section #document .txt-list02 ul.iB li {
   padding: 0 10px 0 0;
   float: left;
 }
 section #document .txt-list03 {
   padding-bottom: 30px;
   overflow: hidden;
 }
 section #document .txt-list03 section.tree00 {
   float: left;
   padding: 0 35px 0 0;
   width: 156px;
 }
 section #document .txt-list03 ul.tree01 li {
   padding: 0 0 0 7px;
   margin: 0 0 0 5px;
   background: url(../img/ico_list01.gif) no-repeat left center;
 }
 #contact div.caution {
   margin-bottom: 15px;
 }
 #contact div.caution p {
   color: #d51414;
   font-size: 70%;
   line-height: 145%;
 }
 #formArea_cc #contact form {
   margin-top: 30px;
 }
 #contents-pop #contact {
   width: auto;
   margin: 10px 20px;
 }
 /*メールマガジン登録・解除*/
 #contact {
   margin: 0 auto;
 }
 #contact h4 {
   font-size: 75%;
 }
 #contact .txtBox {
   margin: 25px 0 10px;
 }
 section #contact .text-center {
   text-align: center;
   margin: 50px 0;
 }
 #contact .txtBox p.txt-T {
   font-size: 80%;
   font-weight: bold;
   margin-bottom: 5px;
 }
 section #document li {
   font-size: 100%;
   font-size: 0.75rem;
 }
 #contact table {
   width: 100%;
   margin: 10px 0 0;
   word-break: break-all;
 }
 #contents-pop table {
   width: 100%;
 }
 #contact table th {
   width: 28%;
   padding: 20px;
   font-size: 75%;
   font-weight: bold;
   color: #2B2B2B;
   background-color: #EEE;
   border-top: 1px solid #DEDEDE;
   border-bottom: 1px solid #DEDEDE;
 }
 #contact em {
   color: #C23E42;
   margin-left: 5px;
   font-size: 90%;
   font-size: 0.7rem;
 }
 #contact table td {
   width: 72%;
   font-size: 75%;
   font-size: 90% \9;
   padding: 20px;
 }
 #contact table td input.name, #contact table td input.tel {
   height: 16px;
   width: 40%;
   padding: 2px 5px;
 }
 #contact table td input.mailAddress, #contact table td input.mailAddress2 {
   height: 20px;
   width: 60%;
 }
 #contact .weaktxt {
   color: #333;
   font-size: 90%;
   padding: 5px 0 0;
 }
 #contact .note {
   color: #333;
   font-size: 95%;
   font-size: 0.7rem;
   line-height: 150%;
   margin: 0 0 15px;
 }
 #contact .txtBox .note {
   font-size: 70%;
   font-size: 0.7rem;
 }
 #contact table td textarea {
   box-sizing: border-box;
   height: 150px;
   padding: 5px !important;
   width: 100% !important;
 }
 #contact table td .text-area {
   display: block;
   margin: 0 0 15px;
 }
 #contact .btn-confirm {
   text-align: center;
   padding: 20px;
   border-bottom: none;
   font-size: 100%;
 }
 #contact .btn-confirm-enq {
   padding: 20px;
   font-size: 100%;
 }
 #contact input.btn-confirmNext {
   height: 25px;
   width: 150px;
   color: #FFF;
   font-size: 75%;
   font-size: 0.75rem;
   text-align: center;
   background-color: #333;
   border: none;
   margin: 0 5px;
 }
 #contact input.btn-confirmNext:hover {
   background-color: #666;
   repeat-x: left top;
 }
 #contact .btn-enq input.btn-confirmNext {
   height: 25px;
   width: 200px;
   color: #FFF;
   padding: 0;
   font-size: 75%;
   font-size: 0.75rem;
   background-color: #333;
   border: none;
   margin: 0 5px;
 }
 #contact .btn-enq input.btn-confirmNext:hover {
   background-color: #666;
   repeat-x: left top;
 }
 #contact input.btn-confirmBack {
   height: 25px;
   width: 80px;
   color: #FFF;
   padding: 0 0 0 8px;
   font-size: 75%;
   font-size: 0.75rem;
   text-align: center;
   background: url(../img/btn-back_bg.gif) no-repeat left top;
   border: none;
   margin: 0 5px;
 }
 #contact input.btn-confirmBack:hover {
   background: url(../img/btn-back_bg_on.gif) no-repeat left top;
 }
 #contact input.btn-confirmCancel {
   height: 25px;
   width: 120px;
   color: #FFF;
   padding: 0 0 0 8px;
   font-size: 75%;
   font-size: 0.75rem;
   text-align: center;
   background: url(../img/btn-confirm_bg.gif) repeat-x left top;
   border: none;
   margin: 0 5px;
 }
 #contact .btn-confirmCancel:hover {
   background: url(../img/btn-confirm_bg_on.gif) repeat-x left top;
 }
 #contact .btn-confirmClose {
   height: 25px;
   width: 120px;
   color: #FFF;
   padding: 0 0 0 8px;
   font-size: 80%;
   font-size: 0.8rem;
   text-align: center;
   background: url(../img/btn-close_bg.gif) no-repeat left top;
   border: none;
   margin: 0 5px;
 }
 #contact .btn-confirmClose:hover {
   background: url(../img/btn-close_bg_on.gif) no-repeat left top;
 }
 @-moz-document url-prefix() {
   #contact .btn-enq input.btn-confirmNext {
     padding: 0 5px 2px;
   }
   #contact input.btn-confirmNext {
     padding: 0 5px 2px;
   }
   #contact input.btn-confirmBack {
     padding: 0 2px 2px 8px;
   }
   #contact input.btn-confirmCancel {
     padding: 0 2px 3px 8px;
   }
   #contact input.btn-confirmClose {
     padding: 0 2px 4px 8px;
   }
 }
 #contact fieldset {
   margin-bottom: 25px;
 }
 #contact legend {
   background: none repeat scroll 0 0 transparent;
   border-bottom: 1px dotted;
   font-size: 75%;
   font-weight: bold;
   line-height: 200%;
   margin-bottom: 10px;
   padding: 0;
   width: 100%;
 }
 #contact fieldset p {
   margin-bottom: 5px;
   font-size: 75%;
   font-size: 0.75rem;
 }
 #contact fieldset ul {
   overflow: hidden;
 }
 #contact fieldset li {
   font-size: 75%;
   font-size: 0.75rem;
 }
 #contact fieldset li.fL {
   float: left;
   margin-right: 20px;
 }
 #contact fieldset input {
   margin: 0 3px 2px 0;
 }
 #contact fieldset label {
   margin-right: 10px;
   font-size: 100%;
   font-size: 0.75rem;
 }
 #contact input.txtF {
   height: 16px;
   margin: 0;
   padding: 2px 5px;
 }
 #contact input.mailAddress {
   width: 300px;
   margin: 0;
 }
 #contact input.name_sei {
   margin: 0 10px 0 0;
 }
 #contact select {
   margin: 0 0 5px;
   padding: 3px 1px;
   min-width: 110px;
 }
 #contact fieldset textarea {
   width: 70%;
   height: 150px;
   padding: 5px;
 }
 #contact .commentItem {
   padding: 10px 0 0;
 }
 #contact table td.commentWite {
   border-bottom: 1px dotted #000;
   border-top: 1px dotted #000;
   font-size: 100%;
   padding: 15px 20px 20px;
   width: 72%;
 }
 #contact .commentItem {
   font-weight: 150%;
 }
 #contact .memberF {
   padding: 20px 20px 10px;
   margin: 20px 0 0 0;
   border: 10px solid #EEE;
 }
 #contact .atttxt {
   color: #333;
   font-size: 75%;
   padding: 5px 0 0;
 }
 #contact .memberF input {
   width: 40%;
   height: 16px;
   margin: 0;
   padding: 2px 5px;
 }
 #contact .memberF th {
   padding: 10px 20px;
 }
 #contact .memberF td {
   padding: 10px 20px;
   font-size: 100%;
 }
 #contact .memberF input.btn-confirm160 {
   margin: 10px 0 0;
 }
 .memberName {
   border-bottom: 1px dotted;
   font-size: 75%;
   font-weight: bold;
   line-height: 200%;
   margin-bottom: 5px;
   padding: 0;
 }
 #contact input.btn-confirm120 {
   height: 25px;
   width: 120px;
   color: #FFF;
   padding: 0;
   font-size: 75%;
   font-size: 0.75rem;
   background: url(../img/btn-confirm_bg.gif) repeat-x left top;
   border: none;
   margin: 0 5px;
 }
 #contact input.btn-confirm120:hover {
   background: url(../img/btn-confirm_bg_on.gif) repeat-x left top;
 }
 #contact input.btn-confirm160 {
   height: 25px;
   width: 160px;
   color: #FFF;
   padding: 0 0 0 8px;
   font-size: 75%;
   font-size: 0.75rem;
   text-align: center;
   background: url(../img/btn-confirm_bg.gif) repeat-x left top;
   border: none;
 }
 #contact input.btn-confirm160:hover {
   background: url(../img/btn-confirm_bg_on.gif) repeat-x left top;
 }
 #contact input.btn-confirmReg {
   height: 25px;
   width: 160px;
   color: #FFF;
   padding: 0 0 0 8px;
   font-size: 75%;
   font-size: 0.75rem;
   text-align: center;
   background: url(../img/btn-confirm_bg.gif) repeat-x left top;
   border: none;
 }
 #contact input.btn-confirmReg:hover {
   background: url(../img/btn-confirm_bg_on.gif) repeat-x left top;
 }
 #contact input.btn-confirmBackItem {
   height: 25px;
   width: 120px;
   color: #FFF;
   padding: 0 5px 2px 15px;
   font-size: 75%;
   font-size: 0.75rem;
   text-align: center;
   background: url(../img/btn-back_bg120.gif) no-repeat left top;
   border: none;
   margin: 0 5px;
 }
 #contact input.btn-confirmBackItem:hover {
   background: url(../img/btn-back_bg120_on.gif) no-repeat left top;
 }
 @-moz-document url-prefix() {
   #contact input.btn-confirm120, #contact input.btn-confirm160, #contact input.btn-confirmReg {
     padding: 0 5px 2px;
   }
   #contact input.btn-confirmBackItem {
     padding: 0 2px 2px 8px;
   }
 }
 /*-- メールマガジン登録・解除 --*/
 #contact .mailm:first-child {
   margin: 3px 0 0;
 }
 #contact .mailm {
   margin: 30px 0 0 0;
 }
 #contact .mailm .txtBox {
   margin: 0;
 }
 #contact .mailm .txtBox h2 {
   border-bottom: 1px dotted #DEDEDE;
   border-top: none;
   margin: 0 0 20px;
   padding: 0 0 10px;
 }
 #contact .mailm .caution {
   margin: 0 0 5px;
 }
 #contact table.mailMF td {
   overflow: hidden;
   font-size: 100%;
   border-top: solid 1px #DEDEDE;
   border-bottom: solid 1px #DEDEDE;
 }
 #contact table.mailMF input.mailAddress {
   float: left;
   margin: 0 10px 0 0;
   height: 40px;
 }
 #contact table.mailMF span.btn-confirm {
   display: block;
   float: left;
   padding: 0;
 }
 #contact table.mailMF input.btn-confirmNext {
   background-color: #333;
   border-radius: 3px;
   float: left;
   height: 40px;
   padding: 0 5px;
 }
 #contact .mailMC input.btn-confirmNext {
   background-color: #333;
   border-radius: 3px;
   float: left;
   height: 40px;
   padding: 0 5px;
 }
 #contact .mailMF input.btn-confirmNext:hover, #contact .mailMC input.btn-confirmNext:hover {
   background-color: #666;
 }
 @-moz-document url-prefix() {
   #contact table.mailMF input.btn-confirmNext, #contact .mailMC input.btn-confirmNext {
     padding: 0 5px 1px;
   }
 }
 #contact table.mailMF td.n1 {
   border-bottom: none;
   padding: 20px 20px 10px;
 }
 #contact table.mailMF td.n2 {
   border-top: none;
   padding: 10px 20px 20px;
 }
 #contact table.mailMF td p {
   float: left;
   font-size: 80%;
   padding: 10px 0;
   margin-right: 10px;
 }
 #contact input.mailAddress_new {
   float: left;
   height: 40px;
   margin: 0 10px 0 0;
   width: 60%;
 }
 #contact .mailMC span.btn-confirm {
   display: block;
   padding: 0;
 }
 #contact.sps-mailMagazine .sps-mailMagazine-freeArea {
   border: 1px solid #DDD;
   box-sizing: border-box;
   margin: 60px 5% 0;
   padding: 2%;
   width: 90%;
 }
 #contact .freeArea {
   margin: 60px 50px 0;
   width: 620px;
   background-color: #FFF;
 }
 #contact .ann-input {
   margin: 5px 0 0;
   font-size: 100%;
 }
 #contact .ann-input input {
   margin: 0 3px 2px 0;
 }
 #itemList_wrap {
   margin: 0 0 20px 0;
 }
 #itemList {
   margin: 40px 0 0 0;
   position: relative;
 }
 #main-column .column4 h2 {
   background: none;
   border: none;
   font-size: 95%;
   font-weight: normal;
   height: 3em;
   line-height: 3em;
   margin-bottom: 5px;
   padding: 0;
 }
 article #main-column #itemList section.column4 h2, article #main-column #itemList section.column4 h2, article #main-column #itemList section.column4 h2 {
   font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", メイリオ, Meiryo, sans-serif;
 }
 /*写真のみ*/
 article #main-column #itemList section.column4 h2,
 article #main-column #itemList section.column4 h2,
 article #main-column #itemList section.column4 h2 {
   border: none;
   height: auto;
   line-height: 125%;
   font-weight: bold;
   letter-spacing: 1px;
 }
 // #itemList .column4 .sps-wishAddItem img, #itemList .column5 .sps-wishAddItem img,
 // #itemList .column4 .sps-wishAddItemOk img, #itemList .column5 .sps-wishAddItemOk img {
 //   margin: 10px 0 0 80%;
 //   width: 20%;
 // }
 /*説明付き*/
 #itemList2 {
   margin: 40px 0 0 0;
   position: relative;
 }
 #itemList2 .column4 {
   border-bottom: 1px solid #EEE;
   float: none;
   width: 100%;
   height: auto !important;
   overflow: hidden;
   *zoom: 1;
 }
 #itemList2 .column4:first-child {
   border-top: 1px solid #EEE;
   padding-top: 20px;
 }
 #itemList2 .column4:last-child {
   border-bottom: none;
 }
 #itemList2 .column4 .itemThumb-wrap {
   float: left;
   text-align: left;
   max-width: 300px;
   width: 24%;
 }
 #itemList2 .column4 .itemThumb, #itemList2 .column5 .itemThumb,
 #itemList2 .column4 .itemThumb-wrap, #itemList2 .column5 .itemThumb-wrap {
   margin: 0;
 }
 // #itemList2 .column4 .sps-wishAddItem img, #itemList2 .column5 .sps-wishAddItem img,
 // #itemList2 .column4 .sps-wishAddItemOk img, #itemList2 .column5 .sps-wishAddItemOk img {
 //   margin-left: 92%;
 //   width: 8%;
 // }
 .column4 p.sps-itemList-stockDisp, .column5 p.sps-itemList-stockDisp {
   margin: 6px 0 0;
 }
 .column4 p.sps-itemList-stockDisp a, .column5 p.sps-itemList-stockDisp a {
   border: 1px solid #333;
   border-radius: 3px;
   box-sizing: border-box;
   display: block;
   padding: 1% 3%;
   text-align: center;
 }
 .column4 p.sps-itemList-stockDisp a::after, .column5 p.sps-itemList-stockDisp a::after {
   content: ">>";
   padding-left: 3px;
 }
 .column4 p.sps-itemList-stockDisp:empty, .column5 p.sps-itemList-stockDisp:empty,
 .column4 p.sps-itemList-stockDisp a:empty, .column5 p.sps-itemList-stockDisp a:empty {
   display: none;
 }
 #main-column #itemList2 .column4 h2, #main-column #itemList2 .column5 h2 {
   font-size: 105%;
   height: auto;
   line-height: 140%;
 }
 #main-column #itemList2 .column4 p.price, #main-column #itemList2 .column5 p.price, #main-column #itemList2 .column4 p.sp_price, #main-column #itemList2 .column5 p.sp_price #main-column #itemList2 .column4 p.fx_price, #main-column #itemList2 .column5 p.fx_price, #main-column #itemList2 .column4 p.rg_price, #main-column #itemList2 .column5 p.rg_price {
   font-size: 95%;
 }
 #itemList2 .column4 .inner-catch {
   line-height: 140%;
   margin-top: 35px;
 }
 #itemList_wrap #pager-top {
   width: 100%;
   padding: 30px 0 0 0;
   overflow: hidden;
   line-height: 100%;
 }
 #itemList_wrap div.pagerlist #pager-bm {
   width: 100%;
   padding: 0;
   margin: 0 0 20px 0;
   overflow: hidden;
   line-height: 100%;
 }
 #itemList_wrap #pager-bm {
   border-top: 1px solid #333;
   overflow: hidden;
   padding: 15px 0 30px;
   width: 100%;
 }
 #itemList_wrap #pager-top .quantity {
   float: left;
   font-size: 80%;
   line-height: 100%;
   @include s.media-sp {
     font-size: 3rem;
   }
   @include s.media-pc {
   }
 }
 #itemList_wrap #pager-bm .quantity {
   float: left;
   font-size: 80%;
   line-height: 100%;
   margin: 1px 30px 0 0;
 }
 #itemList_wrap #pager-top .selectbox, #itemList_wrap #pager-bm .selectbox {
   font-size: 70%;
   @include s.media-sp {
     font-size: 3rem;
   }
   @include s.media-pc {
   }
 }
 #itemList_wrap #pager-top .selectbox {
   margin-bottom: 10px;
 }
 #itemList_wrap #pager-top .selectbox .select01 {
   // height: 34px;
   // margin-right: 10px;
   padding: 0;
 }
 #itemList_wrap #pager-top .pagerlist, #itemList_wrap #pager-bm .pagerlist {
   float: right;
 }
 #itemList_wrap #pager-top ul, #itemList_wrap #pager-bm ul {
   margin: 0;
   padding: 0;
   overflow: hidden;
   *zoom: 1;
 }
 #itemList_wrap #pager-top ul li, #itemList_wrap #pager-bm ul li {
   float: left;
   *float: none;
   *display: inline;
   padding: 0 4px;
   margin: 0;
   line-height: 120%;
   *zoom: 1;
   font-size: 80%;
 }
 #itemList_wrap #pager-top ul li.pagericon, #itemList_wrap #pager-bm ul li.pagericon {
   float: left;
   *float: none;
   *display: inline;
   padding: 0;
   margin: 0;
   line-height: 120%;
   *zoom: 1;
 }
 #pager-top ul li.selected, #pager-bm ul li.selected {
   font-weight: bold;
   color: #333;
   text-decoration: none;
 }
 /*-- 商品詳細ページ --*/
 #itemDetail-cont {
   overflow: hidden;
 }
 /*商品画像*/
 #itemDetail-cont .itemThumb-wrap {
   // float: left;
   // width: 400px;
   // margin: 0 50px 0 0;
 }
 .itemThumb-main {
   text-align: left;
 }
 #itemDetail-cont .itemThumb-wrap .itemThumb-main img {
   height: auto;
   margin: 0 0 5px 0;
   /*border: solid 1px #DDD;*/
 }
 #itemDetail-cont .itemThumb-wrap .itemThumb ul {
   overflow: hidden;
 }
 #itemDetail-cont .itemThumb-wrap .itemThumb ul li {
   float: left;
   padding: 0;
   height: 76px;
   width: 76px;
   margin: 0 5px 5px 0;
   overflow: hidden;
   /*border: solid 1px #DDD;*/
 }
 #itemDetail-cont .itemThumb-wrap .itemThumb ul li:nth-child(5n) {
   margin: 0 0 5px 0;
 }
 /*商品名/価格/カートボタンエリア*/
 #itemDetail-cont .itemOutline {
   // float: left;
   // width: 450px;
 }
 article#Detail #main-column h1 {
   background: none;
   border: none;
   font-weight: normal;
   color: #000;
   height: auto;
   line-height: 140%;
   // margin-bottom: 20px;
   padding: 0;
   @include s.media-sp {
     margin-bottom: 5rem;
     font-size: 5.8rem;
   }
   @include s.media-pc {
     margin-bottom: 3rem;
     font-size: 3.6rem;
   }
 }
 article#Detail section#itemDetail-wrap p.price {
   font-size: 90%;
   margin-bottom: 3px;
 }
 article#Detail section#itemDetail-wrap p.sp_price {
   font-size: 90%;
   color: #C00;
   font-weight: bold;
   margin-bottom: 3px;
 }
 article#Detail section#itemDetail-wrap p.regular_price {
   // font-size: 80%;
   // margin-bottom: 0;
   color: #2A78D0;
 }
 // section#itemDetail-wrap table.spec td input {
 //   height: 34px !important;
 //   width: 20%;
 // }
 // section#itemDetail-wrap table.spec tr.option_area td select.option_parts,
 // section#itemDetail-wrap table.spec tr.option_area td input.option_parts,
 // section#itemDetail-wrap table.spec tr.option td input {
 //   height: 34px !important;
 //   width: 85%;
 // }
 section#itemDetail-wrap table.matrix tr td {
   padding: 5px;
 }
 #itemDetail-cont table.matrix td > a.announceMail,
 #itemDetail-cont table.matrix td > a.announceMailSamePrc {
   display: block;
   margin: 0 auto;
   width: 110px;
 }
 section#itemDetail-wrap table.matrix tr td span.nostock {
   text-align: left;
 }
 section#itemDetail-wrap table.spec tr.quantityArea td select.quantity {
   height: 34px;
   padding: 1px 2px;
   width: 85%;
 }
 #itemDetail-cont .cartArea input:hover,
 #itemDetail-cont .cartArea .variation a:hover {
   opacity: .70;
   filter: alpha(opacity=70);
   -ms-filter: "alpha(opacity=70)";
   -khtml-opacity: .70;
   -moz-opacity: .70;
   cursor: pointer;
 }
 #itemDetail-cont .itemOutline .icons {
   margin: 0 0 20px 0;
 }
 #itemDetail-cont .itemOutline ul.ico_salesMethod {
   overflow: hidden;
   margin: 0 0 10px;
 }
 #itemDetail-cont .itemOutline ul.ico_salesMethod li {
   float: left;
   width: 60px;
 }
 #itemDetail-cont .itemOutline ul.ico_itemType {
   overflow: hidden;
 }
 #itemDetail-cont .itemOutline ul.ico_itemType li {
   float: left;
   width: 35px;
   height: 36px;
 }
 #itemDetail-cont table.snsArea {
   width: auto;
   height: auto;
 }
 // section#itemDetail-wrap table.spec {
 //   line-height: 250%;
 //   margin: 15px 0 15px;
 //   font-size: 85%;
 // }
 // section#itemDetail-wrap table.spec tr {
 //   border: none;
 // }
 // /*備考欄*/
 // section#itemDetail-wrap table.spec th {
 //   line-height: 125%;
 //   padding: 5px 5px 5px 0;
 //   vertical-align: top;
 //   width: 40%;
 // }
 // section#itemDetail-wrap table.spec td {
 //   line-height: 150%;
 //   padding: 5px 5px 5px 0;
 // }
 // section#itemDetail-wrap table.spec th.option th {
 //   line-height: 140%;
 //   padding-bottom: 15px;
 // }
 // section#itemDetail-wrap table.spec tr.option td {
 //   padding-bottom: 15px;
 // }
 section#itemDetail-wrap table.spec tr.option td input {
   display: inline;
 }
 // section#itemDetail-wrap table.spec tr.date {
 //   line-height: 150%;
 // }
 // section#itemDetail-wrap table.spec td input {
 //   padding: 0 2%;
 // }
 /*入荷連絡ボタン*/
 // section#itemDetail-wrap table.spec td a img {
 //   height: 30px;
 //   margin-left: 10px;
 // }
 // section#itemDetail-wrap #spsVarpriceBox p {
 //   font-size: 80%;
 // }
 // section#itemDetail-wrap table.date {
 //   margin: 15px 0 3px;
 // }
 // section#itemDetail-wrap table.jan {
 //   margin: 0 0 15px;
 // }
 // section#itemDetail-wrap table.price {
 //   line-height: 170%;
 //   margin: 0 0 20px;
 // }
 // section#itemDetail-wrap table.price th {
 //   font-size: 85%;
 //   padding: 0 0 1px;
 // }
 // section#itemDetail-wrap table.price td {
 //   font-size: 80%;
 // }
 // section#itemDetail-wrap table.price td a img {
 //   display: block;
 //   width: 110px;
 // }
 // table.price tr.fx_price span.strike span.fixed_price,
 // table.price span.strike {
 //   text-decoration: line-through;
 // }
 // table.price tr.price span.selling_price, table.price tr.price span.taxin,
 // table.price tr.price span.discount_rate, table.price tr.sp_price span.special_price,
 // table.price tr.sp_price span.taxin, table.price tr.sp_price span.discount_rate {
 //   color: #000;
 //   font-size: 120%;
 //   font-weight: bold;
 //   margin-bottom: 3px;
 // }
 // table.price tr.rg_price span.regular_price_str {
 //   color: #2A78D0;
 //   margin-bottom: 3px;
 // }
 // table.price tr.rg_price span.regular_price,
 // table.price tr.rg_price span.taxin {
 //   color: #2A78D0;
 //   margin-bottom: 3px;
 // }
 // table.price tr.rg_price span.publish_point {
 //   color: inherit;
 // }
 /*カートボタン*/
 section#itemDetail-wrap .cartArea {
   // margin-bottom: 10px;
   margin-top: 5rem;
   margin-bottom: 5rem;
 }
 // section#itemDetail-wrap .cartArea img {
 //   max-width: 110px;
 // }
 section#itemDetail-wrap .cartArea input, sps-wishAddItem img {
   max-width: 100%;
 }
 section#itemDetail-wrap #spsVarpriceBox .cartArea input {
   margin-left: 0;
 }
 // section#itemDetail-wrap .cartArea img.nostock, section#itemDetail-wrap .cartArea input.dialogSStock {
 //   width: 110px;
 //   height: auto;
 // }
 section#itemDetail-wrap .cartArea table.variation {
   width: 100%;
   word-break: break-all;
 }
 section#itemDetail-wrap .cartArea tr.t-variation {
   // background-color: #efefef;
   background-color: #eeeeee;
   background-repeat: x-repeat;
   background-position: left top;
 }
 section#itemDetail-wrap .cartArea table.variation th {
   border: 1px solid #CCC;
   box-sizing: border-box;
   // font-size: 70%;
   // font-weight: bold;
   // padding: 3px 5px;
   text-align: center;
   // line-height: 150%;
   width: auto !important;
 }
 section#itemDetail-wrap .cartArea table.variation th.sps-variation-cart {
   // width: 141px !important;
 }
 section#itemDetail-wrap .cartArea table.variation tr {
   border-bottom: 1px dotted #CCCCCC;
 }
 section#itemDetail-wrap .cartArea table.variation tr.t-variation {
   border: none;
 }
 section#itemDetail-wrap .cartArea table.variation td {
   // font-size: 75%;
   // padding: 5px;
   text-align: center;
   // line-height: 150%;
   border: 1px solid #CCCCCC;
 }
 section#itemDetail-wrap .cartArea table.variation td input[type="image"] {
   // max-width: 110px;
 }
 section#itemDetail-wrap .cartArea table.variation span.nostock {
   color: #F00;
 }
 /*返品について カートボタン下*/
 section#itemDetail-wrap p.cancel {
   // padding: 2px 0 2px 20px;
   // background-image: url(/pic-labo/icon_return.png);
   // background-repeat: no-repeat;
   // background-size: 15px;
   // background-position: left center;
   // margin-bottom: 5px;
   margin-top: 8rem;
   margin-bottom: 8rem;
   text-align: center;
   @include s.media-pc {
     margin-bottom: 4rem;
     font-size: 1.6rem;
   }
 }
 /*お客様の声 カートボタン下*/
 section#itemDetail-wrap p.review_txt {
   font-size: 70%;
   padding: 2px 0 2px 20px;
   background-image: url(../img/ico_review.gif);
   background-repeat: no-repeat;
   background-size: 15px;
   background-position: left center;
   margin-bottom: 15px;
 }
 // section#itemDetail-wrap p.cancel a, section#itemDetail-wrap p.review_txt a {
 //   display: block;
 // }
section#itemDetail-wrap p.review_txt a {
   display: block;
 }
 /*SNS/問合わせ/紹介 カートボタン下*/
 .itemOutline ul.snsArea {
   overflow: hidden;
   margin-bottom: 10px;
   height: 30px;
 }
 // article#Detail .itemOutline ul.infoArea {
 //   overflow: hidden;
 //   margin: 10px 0;
 //   height: 28px;
 // }
 // section#itemDetail-wrap .itemOutline li {
 //   float: left;
 //   margin-right: 5px;
 //   width: 140px;
 // }
 /*商品紹介文*/
 section#itemDetail-wrap .description {
   // margin: 20px 0 0;
   width: 100%;
   max-width: 80rem;
   margin: 0 auto;
   padding: 0;
 }
 // section#itemDetail-wrap .description p {
 //   font-size: 100%;
 //   line-height: 160%;
 // }
 section#itemDetail-wrap .description div#itempage_contents h1 {

 }
 article#Detail #main-column .spec-g_txt_table_wrap {
   width: 100%;
   overflow: hidden;
 }
 article#Detail #main-column section.spec-g_txt {
   float: left;
   margin-right: 4%;
   width: 48%;
   padding: 20px 0 0;
 }
 article#Detail #main-column section.spec-g_txt p {
   font-size: 70%;
   padding-top: 10px;
   line-height: 160%;
 }
 article#Detail #main-column section.spec-g_table {
   float: right;
   width: 48%;
   padding: 20px 0 0;
 }
 article#Detail #main-column section.spec-g_table table {
   width: 100%;
 }
 article#Detail #main-column section.spec-g_table th {
   width: 70px;
   max-width: 70px;
   min-width: 25px;
   padding: 5px 0;
 }
 article#Detail #main-column section.spec-g_table tr {
   border-bottom: 1px dotted #CCC;
   width: 100%;
   font-size: 65%;
 }
 article#Detail #main-column section.spec-g_table td {
   padding: 5px 0;
 }
 article#Detail #main-column .w100 section.spec-g_txt {
   float: none;
   width: 100%;
 }
 article#Detail #main-column .w100 section.spec-g_txt p {
   font-size: 70%;
   padding-top: 10px;
   line-height: 160%;
 }
 article#Detail #main-column .w100 section.spec-g_table {
   float: none;
   width: 100%;
 }
 article#Detail #main-column .w100 table.spec-g_cont tr {
   border-bottom: 1px dotted #CCC;
   width: 100%;
 }
 article#Detail #main-column .w100 table.spec-g_cont th {
   padding: 5px 0;
 }
 article#Detail #main-column .w100 table.spec-g_cont td {
   padding: 5px 0;
 }
 /*お客様の声-商品詳細ページ*/
 section.userreview {
   font-family: 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
   *font-family: 'メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
   padding: 20px 0 0;
   margin-bottom: 30px;
 }
 section.userreview h2 {
   /*border-top: 3px solid #333;*/
   color: #333;
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-size: 124% !important;
   font-weight: bold;
   letter-spacing: 0.05em;
   padding: 0;
   margin-bottom: 15px;
   display: block;
 }
 @include s.media-sp {
   section.userreview h2 {
     font-size: 110% !important;
   }
 }
 section.userreview table.userreview_list {
   width: 100%;
   margin: 10px 0 0 0;
   line-height: 160%;
   border-bottom: 1px dotted #CCC;
 }
 section.userreview div.more {
   width: 100%;
   height: 30px;
   margin: 10px 0 0 0;
   text-align: right;
 }
 section.userreview div.more a img {
   width: 110px;
   height: 30px;
   vertical-align: middle;
 }
 section.userreview table.userreview_list tr td.userreview_user {
   font-size: 80%;
   text-align: left;
 }
 section.userreview table.userreview_list tr td.userreview_date {
   font-size: 80%;
   text-align: right;
 }
 section.userreview table.userreview_list td.userreview_rate {
   font-size: 80%;
   font-weight: bold;
 }
 section.userreview table.userreview_list td.userreview_rate img {
   vertical-align: middle;
 }
 section.userreview table.userreview_list td.userreview_comment {
   padding: 10px 0 20px;
 }
 section.userreview .userreview_comment .reviewBox_item {
   margin: 0;
 }
 article #main-column section.userreview table.userreview_list td.userreview_comment .comment h3 {
   background: none;
   font-size: 75%;
   font-weight: bold;
   margin: 0;
   padding: 10px 0 0 10px;
 }
 article #main-column section.userreview table.userreview_list td.userreview_comment .comment p {
   margin: 0;
 }
 article#Detail #main-column section.recommend {
   padding: 20px 0 0;
 }
 article#Detail section.column5 {
   border: none;
 }
 .column-set .quantityArea {
   margin-top: 5px;
   text-align: right;
 }
 .column-set .quantityArea span {
   font-size: 70%;
 }
 .column-set .quantityArea input,
 .column-set .quantityArea select {
   height: 32px;
   text-align: right;
 }
 article #main-column .pageNav_top {
   overflow: hidden;
   font-size: 70%;
   padding-bottom: 5px;
   border-bottom: solid 1px #CBCBCB;
 }
 article #main-column .pageNav {
   font-size: 70%;
 }
 article #main-column .pageL {
   float: left;
   width: 100%;
 }
 article #main-column .pageNav_top .pageL {
   width: 50%;
 }
 article #main-column .pageR {
   float: right;
 }
 article #main-column section.rating {
   padding-bottom: 30px;
   margin-bottom: 5px;
   border-bottom: solid 1px #CBCBCB;
 }
 article #main-column section.rating .reviewBox {
   overflow: hidden;
   margin: 15px 0;
 }
 article #main-column section.rating .tmb {
   float: left;
   margin-right: 15px;
   width: 140px;
 }
 article #main-column section.rating .reviewTxt {
   float: right;
   width: 745px;
 }
 article #main-column section.rating .reviewTxtNoImg {
   float: left;
   width: 100%;
 }
 article #main-column section.rating .ttlArea {
   display: table;
 }
 article #main-column section.rating h2 {
   border: none;
   display: table-cell;
   font-size: 110%;
   font-weight: bold;
   letter-spacing: 0.05em;
   line-height: 140%;
   padding: 0 15px 0 0;
   margin: 0;
   vertical-align: top;
   width: 560px;
 }
 article #main-column section.rating .reviewBox_item p,
 article #main-column section.rating .reviewTxt p {
   font-size: 90%;
   line-height: 140%;
 }
 article #main-column section.rating .btnMore {
   display: table-cell;
   min-width: 170px;
   vertical-align: top;
   width: 170px;
 }
 article #main-column section.rating .btnMore a {
   display: inline-block;
 }
 article #main-column section.rating .btnMore a img {
   width: 170px;
 }
 article #main-column section.rating .reviewTxt ul,
 article #main-column section.rating .reviewTxtNoImg ul {
   margin: 5px 0;
   vertical-align: middle;
   line-height: 120%;
   overflow: hidden;
 }
 article #main-column section.rating .reviewTxt ul li,
 article #main-column section.rating .reviewTxtNoImg ul li {
   float: left;
   margin-right: 5px;
   font-size: 80%;
 }
 article #main-column section.userreview .comment,
 article #main-column section.rating .comment {
   background: #e8f3ff;
   color: #555;
   display: inline-block;
   font-size: 16px;
   margin: 1.5em 0 0;
   max-width: 100%;
   min-width: 120px;
   padding: 7px 10px;
   position: relative;
 }
 article #main-column section.userreview .comment::before,
 article #main-column section.rating .comment::before {
   border: 10px solid transparent;
   border-bottom: 15px solid #e8f3ff;
   content: "";
   left: 4%;
   position: absolute;
   top: -23px;
 }
 article #main-column section.rating .comment h3 {
   background: none;
   font-size: 80%;
   font-weight: bold;
   margin: 0;
   padding: 10px 0 0 10px;
 }
 article #main-column section.userreview .comment p,
 article #main-column section.rating .comment p {
   background: none;
   font-size: 85%;
   padding: 5px 10px 10px;
 }
 article #main-column .pageNav .pageL strong, article #main-column .pageNav_top .pageL strong {
   font-weight: bold;
 }
 /*-- お客様の声 --*/
 article #main-column #itemBox {
   background-color: #efefef;
   overflow: hidden;
   padding: 15px 2%;
   margin-bottom: 15px;
 }
 article #itemBox h2 {
   border-top: none;
   float: left;
   font-size: 90%;
   font-weight: normal;
   padding: 0;
 }
 article #itemBox p {
   float: right;
 }
 article #main-column #itemBox p.btnMore img {
   width: 166px;
 }
 div#main-column div#itemBox p.review_txt img {
   vertical-align: -0.1em;
 }
 article #main-column section.rating .reviewBox_item h2 {
   color: #0C3;
   float: left;
   font-size: 75%;
   background: none;
   font-weight: bold;
   padding: 0;
   margin: 0;
   border-bottom: none;
 }
 article #main-column section.rating .reviewBox_item {
   margin: 15px 0;
 }
 article #main-column section.rating .reviewBox_item ul {
   overflow: hidden;
   vertical-align: middle;
   line-height: 120%;
 }
 article #main-column section.rating .reviewBox_item ul li {
   float: left;
   margin-right: 5px;
   margin-bottom: 10px;
   font-size: 80%;
 }
 article #main-column section.rating .reviewBox_item ul li.point {
   margin-right: 20px;
   color: #F60;
   font-weight: bold;
 }
 article #main-column section.rating ul li.space {
   margin-right: 20px;
 }
 　
 .ui-dialog {
   padding: 1em;
   box-sizing: border-box;
 }
 .fl_main {
   min-height: 40px;
 }
 .fl_thumbnail {
   max-width: 152px !important;
 }
 .fl_t_item {
   padding: 5px !important;
 }
 .fl_img_selected {
   background-color: #C9E1F4 !important;
   border: none !important;
   margin: 5px !important;
   padding: 6px !important;
 }
 #thum_prev {
   padding-left: 0 !important;
   padding-right: 5px !important;
 }
 #thum_next {
   padding-left: 5px !important;
   padding-right: 0 !important;
 }
 /*ダイアログ-商品を追加しました*/
 div[aria-describedby='sps-cartaddModal'] {
   border-radius: 4px !important;
 }
 div.sps-cartaddErrDetail {
   width: 90%;
   margin: 22px auto 40px;
   box-sizing: border-box;
 }
 /*ポップアップ全般*/
 .ui-front {
   z-index: 250 !important;
 }
 .ui-widget-overlay {
   z-index: 200 !important;
 }
 .ui-widget-content {
   box-sizing: border-box;
   padding: 1em !important;
 }
 div#sps-cartaddModal {
   padding: .5em 1em !important;
   width: 720px !important;
 }
 #contact #center1 .centerbox {
   margin: 0px;
 }
 #contact #center1 .centerbox .mainCont .step_navi01 {
   background-image: url("../img/navi01.gif");
   background-repeat: no-repeat;
 }
 #contact #center1 .centerbox .mainCont .step_navi02 {
   background-image: url(../img/navi02.gif);
   background-repeat: no-repeat;
 }
 #contact #center1 .centerbox .mainCont .step_navi03 {
   background-image: url(../img/navi03.gif);
   background-repeat: no-repeat;
 }
 #contact #center1 .centerbox .mainCont .step_navi04 {
   background-image: url(../img/navi04.gif);
   background-repeat: no-repeat;
 }
 #contact #center1 .centerbox .mainCont .step_navi01, #contact #center1 .centerbox .mainCont .step_navi02, #contact #center1 .centerbox .mainCont .step_navi03, #contact #center1 .centerbox .mainCont .step_navi04 {
   height: 83px;
   text-align: center;
   margin: 0 auto;
   width: 400px;
 }
 #contact #center1 .center {
   text-align: center !important;
 }
 #contact #center1 p.center a#logout {
   float: none;
 }
 #contact #center1 .centerbox .mainCont input[name="PZIP_SEEK"] {
   width: 180px;
 }
 /*お届けスケジュール*/
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan {
   background-color: #fff;
 }
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.first,
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.second,
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.third,
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.deli_plan_calday,
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.border_none td {
   box-sizing: border-box;
   border: none;
   overflow: hidden;
 }
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td {
   background: #efefef;
 }
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td table caption {
   text-align: center;
 }
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td table tbody td {
   background-color: #fff;
 }
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td table td.day,
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td table td.sat,
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td table td.sun {
   text-align: right !important;
 }
 /*お気に入りリスト*/
 div.wishlist-column .sps-wishBtnArea .wishDeleteItem, div.wishlist-column .sps-wishBtnArea .wishAddCart {
   background-position: right top;
 }
 #allItemDelete {
   text-align: right;
   height: 32px;
   margin: -20px 0 20px;
 }
 input[type="button"].wishAllDelBtn {
   background: none !important;
   background-color: #333 !important;
   border: 1px solid #333 !important;
   border-radius: 3px;
   color: #FFF !important;
   height: 32px !important;
   margin: 0 !important;
   min-width: 120px;
 }
 input[type="button"].wishAllDelBtn:hover {
   background-color: #666 !important;
   border: 1px solid #666 !important;
 }
 /*ポップアップ center3*/
 #center3 .centerbox table td[align="right"],
 #center3 .centerbox .right,
 #contact #center1 .right {
   text-align: right !important;
 }
 table#enter #center3 .centerbox .mainCont div.bordlayoutp3 td.backcolor1 {
   text-align: left;
 }
 /*ポップアップ マイページ center-seo*/
 #center-seo .centerbox .backcolor3 .deli_plan_box table.layoutp5 th,
 #center-seo .centerbox table th[align="left"],
 #center-seo .centerbox table td[align="left"],
 #center3 .centerbox .backcolor3 .deli_plan_box table.layoutp5 th,
 #center3 .centerbox table th[align="left"],
 #center3 .centerbox table td[align="left"],
 #contact #center1 .left {
   text-align: left !important;
 }
 #contact #center1 .middle {
   vertical-align: middle !important;
 }
 #contact #center1 .buttom {
   vertical-align: bottom !important;
 }
 #contact #center1 .float_left {
   float: left !important;
 }
 #contact #center1 .centerbox .title1 a {
   color: #333;
   text-decoration: underline;
 }
 #contact #center1 .centerbox .title1 a:hover {
   color: #333;
   text-decoration: underline;
   font-weight: bold;
 }
 #contact #center1 .centerbox .mainCont {
   margin: 0;
   width: auto;
   background-color: #FFF;
 }
 .backcolor1 strong, .backcolor2 strong {
   font-weight: bold;
 }
 #contact #center1 .centerbox .mainFrame .mainCont #confirm .right {
   width: 100% !important;
 }
 /*-- カート/レジ画面 --*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 {
   box-sizing: border-box;
   padding: 5px 0px;
   max-width: 1200px;
   width: 100%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table {
   font-size: 100%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 th {
   padding: 10px;
   border: 1px solid #999;
 }
 /*table中身*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td {
   padding: 1rem 1% !important;
   border: 1px solid #999;
   font-size: 0.75rem;
   /*background-color: #fff;*/
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td table.sps-inputTable-banchi tr td:first-child {
   padding: 0 !important;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.border_none td {
   border: none;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .side {
   padding: 10px 15px;
 }
 #contact #center1 .backcolor3 {
   background: #e1ecf7;
 }
 /*カート画面table見出し*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor1 {
   width: 35%;
   background: #DDD;
 }
 /*レジ画面table見出し*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.backcolor1 {
   background: #DDD;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.backcolor1 td {
   background: #DDD;
   text-align: center;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.border tbody tr td {
   width: 15%;
 }
 /*レジ画面table中身*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor2 {
   vertical-align: middle;
   background: #FFF;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor2[width="17%"] {
   width: 1%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor2 input[type="text"],
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor2 input[type="password"] {
   border: 1px solid #A9A9A9;
   padding: 2px 1px;
   height: 34px !important;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.sps-inputTable td input[type="text"],
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.sps-inputTable td input[type="password"] {
   height: 32px;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="NEXT"] .bordlayoutp3 table.border tbody tr td.backcolor1 {
   background: #DDD;
   padding: 1rem;
   width: 100% \9;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="NEXT"] .bordlayoutp3 table.border tbody tr td.backcolor1 input {
   height: auto;
   vertical-align: 0 !important;
 }
 #deli_scadule .deli_scadule_box div.backcolor3.deli_plan table.layoutp5.deli_plan_day tbody tr td.backcolor1 {
   width: 15% !important;
 }
 #deli_scadule .deli_scadule_box table.layoutp5.deli_cycle tbody tr td.backcolor1 {
   width: 35% \9 !important;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="PREV_ENQUETE"] + form[name="NEXT"] .bordlayoutp3 table.border tbody tr td.backcolor1 {
   background: #DDD;
   width: 30% \9 !important;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.border tbody tr td + td {
   width: 25%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.border tbody tr td + td + td {
   width: 12%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.border tbody tr td + td + td + td {
   width: 26%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.border tbody tr td + td + td + td + td {
   width: 12%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.border tbody tr td + td + td + td + td + td {
   width: 10%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.border tbody tr td + td + td + td {
   width: 8%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.border tbody tr td + td + td + td + td {
   width: 10%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 form[name="NEXTM"] table.border tbody tr td {
   width: auto;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="NEXTM"] .bordlayoutp3 table.border tbody tr td {
   width: auto;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="NEXTM"] table.border tbody tr td.backcolor1.center,
 #contact #center1 .centerbox .mainFrame .mainCont table.border tbody tr td[width="49%"],
 #contact #center1 .centerbox .mainFrame .mainCont table.border tbody tr td[width="49%"] + td + td[width="49%"] {
   width: 49%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="NEXTM"] table.border tbody tr td + td,
 #contact #center1 .centerbox .mainFrame .mainCont table.border tbody tr td[width="49%"] + td,
 #contact #center1 .centerbox .mainFrame .mainCont table.border tbody tr td.backcolor2 + td {
   width: 2%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="NEXTM"] table.border tbody tr td + td + td.backcolor1.center {
   width: 49%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.layoutp5.deli_cycle tbody tr td {
   width: auto;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.border tbody tr td[align=right] {
   text-align: right;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input {
   display: inline;
   padding: 0 2%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input[type="text"] {
   height: 30px;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input[value="変更"] {
   margin: 5px 0 5px 5px;
 }
 /*変更ボタン*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input[value="削除"] {
   margin: 5px 0;
   width: 60px;
 }
 /*削除ボタン*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input[type="submit"] {
   padding: 0 1%;
 }
 #deli_scadule table.deli_plan_cal tbody tr td {
   vertical-align: top;
 }
 #contact #center1 .centerbox .mainFrame .mainCont div.layoutp3 table.border {
   border: 1px solid #999;
   width: 100% !important;
 }
 #contact #center1 .centerbox .mainFrame .mainCont div.layoutp3 table.border tbody tr td.backcolor2 table.layoutp3 {
   margin-top: 0px;
 }
 #contact #center1 .centerbox .mainFrame .mainCont div.layoutp3 table.border tbody tr td {
   border: none;
   padding: 2px 9px;
 }
 /*カート注文内容table*/
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.cartItem tbody tr td {
   width: 17%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.cartItem tbody tr td + td {
   width: 25%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.cartItem tbody tr td + td + td {
   width: 13%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.cartItem tbody tr td + td + td + td {
   width: 20%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.cartItem tbody tr td + td + td + td + td {
   width: 13%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont form[name="MODIFY"] .bordlayoutp3 table.cartItem tbody tr td + td + td + td + td + td {
   width: 12%;
 }
 #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_day th {
   font-size: 100%;
   background-color: inherit;
   border: none;
 }
 table#enter #center3 .centerbox .mainCont div.bordlayoutp3 form[name=list] table[align=center],
 table#enter #center3 .centerbox .mainCont div.bordlayoutp3.center form table[align=center],
 table#enter #center3 .centerbox .mainCont div.bordlayoutp3.center table[align=center] {
   width: auto;
   margin: 0 auto;
 }
 table#enter #center3 .centerbox .mainCont div.bordlayoutp3 hr {
   display: block;
   margin: 5px;
 }
 table#enter #center-seo .centerbox .mainCont div.bordlayoutp3,
 table#enter #center3 .centerbox .mainCont div.bordlayoutp3 {
   padding: 5px 0 10px;
 }
 table#enter {
   background: none repeat scroll 0 0 #FFF;
   text-align: left;
   width: 100%;
 }
 table#enter #center3 .centerbox form[name="NEXT"] .mainCont .bordlayoutp3 table tbody td div.send_info {
   border: 1px solid #A9A9A9;
   box-sizing: border-box;
   margin: .5em 0 1em 25px !important;
   vertical-align: middle;
   padding: 10px !important;
   width: 60% !important;
 }
 #center-seo .centerbox a, #center3 .centerbox a {
   color: #6599cc;
 }
 #center-seo .centerbox a:hover, #center3 .centerbox a:hover {
   text-decoration: underline;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor3 div > table tr.backcolor2 td[align="center"],
 #center3 .centerbox .bordlayoutp3 table td[align="center"], #center3 .centerbox .bordlayoutp3 table tr.center td,
 #center-seo .centerbox .center, #center3 .centerbox .center, #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.border tbody tr td[align=center] {
   text-align: center;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.border tbody tr td[align=center] p font[color=red] strong {
   font-weight: bold;
 }
 #contact #center1 .centerbox .mainCont #card_info input[name=card_info_set].off {
   border: 1px solid #CCC;
 }
 #contact #center1 .centerbox .mainCont #card_info input[name=card_info_edit].off {
   border: 1px solid #CCC;
 }
 #contact #center1 .float_right, table#enter td#center3 div.centerbox form div.mainCont input.float_right {
   float: right !important;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.form_group {
   line-height: 200%;
 }
 /*会員登録*/
 #contact #center1 .centerbox .mainFrame .mainCont td#cartNewmem #newMemberTitle h3.title1 {
   border-top: 3px solid #333;
   font-size: 156%;
   font-weight: bold;
   letter-spacing: 0.05em;
   padding: 21px 0;
 }
 #contact #center1 .centerbox .mainFrame .mainCont td#cartNewmem #newMemberTitle .mainCont p {
   font-size: 145%;
   line-height: 165%;
   margin-bottom: 10px;
 }
 table#enter td#center3 div.centerbox form div.mainCont div {
   width: 100% !important;
 }
 table#enter td#center3 div.centerbox form div.mainCont input.float_right {
   position: absolute;
   right: 19px;
   top: 77px;
 }
 table#enter td#center3 div.centerbox form div.mainCont input#otherAddress {
   top: -50px !important;
 }
 #contact #center1 .centerbox .mainFrame {
   margin: 0px;
 }
 #contact #center1 .box_in_box {
   text-align: center !important;
 }
 #contact .layoutp3 table.layoutp3 tbody td,
 #contact .layoutp3 table.layoutp5 tbody td,
 #contact .bordlayoutp3 table tbody td {
   text-align: left;
 }
 #contact .layoutp3 table.layoutp3 tbody td,
 #contact .layoutp3 table.layoutp5 tbody td {
   padding: 5px;
 }
 #contact .mainFrame .mainCont .layoutp3 table.layoutp3 tbody td.center,
 #contact .mainFrame .mainCont .layoutp3 table.layoutp5 tbody td.center {
   width: 2%;
 }
 #contact .mainFrame .mainCont .layoutp3 table.layoutp3 tbody td label,
 #contact .mainFrame .mainCont .layoutp3 table.layoutp5 tbody td label strong {
   font-size: 0.75rem;
 }
 #contact .mainFrame .mainCont #tradesafe_div1 {
   font-size: 100% !important;
   line-height: 142% !important;
 }
 #center3 .centerbox .red, #contact #center1 .red {
   color: #C00;
   font-size: 0.75rem !important;
 }
 #contact #center1 .point {
   font-size: 120%;
   color: red;
   font-weight: 900;
 }
 #contact #center1 .pointline {
   font-size: 100%;
   color: #FFF;
   font-weight: 900;
   padding: 5px;
   padding-bottom: 5px;
   background-color: #666;
   margin-top: 5px;
   margin-bottom: 5px;
 }
 #contact .mainFrame .mainCont a {
   color: #333;
   font-size: 0.75rem;
 }
 #contact .mainFrame .mainCont div.layoutp3 table tbody tr td {
   border: none;
 }
 #contact .mainFrame .mainCont a:hover {
   color: #666;
   text-decoration: underline;
 }
 #contact .mainFrame .mainCont h4 {
   font-size: 120%;
   font-weight: normal;
 }
 #contents div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame {
   margin: 0;
   background-color: #fff;
 }
 #contents .enquete-section #contact table tbody tr td div.mainCont:first-child {
   padding: 0px;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] p {
   margin: 5px 0;
   padding: 2px 0;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr td:first-child {
   width: 40%;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] table {
   width: 50%;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] table tbody tr td {
   padding: 1px 1px 10px;
   text-align: left;
   vertical-align: top;
   word-break: break-all;
   border: none;
   font-size: 100%;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr td input {
   margin-left: 15px;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr:first-child td input {
   margin-left: 27px;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr:first-child td:nth-child(2) input {
   margin-left: 24px;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame {
   border: soild 1px;
 }
 div.enquete-section #contact {
   width: 100%;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] input[type="password"],
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] select {
   height: 40px;
   padding: 0;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] input[type="text"] {
   height: 40px;
   padding: 0;
   min-width: 120px;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] h4.title {
   background: none repeat scroll 0 0 transparent;
   border-bottom: 1px solid #cdcdcd;
   font-size: 110%;
   font-weight: bold;
   line-height: 200%;
   margin-bottom: 10px;
   padding: 0;
   width: 100%;
 }
 #enquete-header {
   width: 100%;
   margin: 0 auto;
   background-color: #FFF;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] input[type=radio] {
   margin: 10px 5px 13px 20px;
 }
 div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] input[type=checkbox] {
   margin: 10px 5px 10px 20px;
 }
 div.enquete-section {
   padding: 0;
 }
 div.enquete-section #contact table tbody tr td {
   width: 100%;
   border-top: none;
   padding: 0px;
 }
 div.enquete-section #contact .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] strong {
   font-size: 120%;
 }
 div.enquete-section #contact .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] textarea {
   height: 150px;
   padding: 5px;
   width: 70%;
 }
 div.enquete-section #contact .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] input[type=submit] {
   border: medium none;
   color: #FFF;
   font-size: 0.75rem;
   height: 25px;
   margin: 0 5px;
   padding: 0;
   width: 200px;
 }
 div.enquete-section #contact .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] input[type=submit]:hover {
   background: url(../img/btn-confirm_bg_on.gif) repeat-x left top;
 }
 .shoplogo {
   width: 340px;
 }
 /*
 #enquete-footer{
   width: 100%;
   margin: 0 auto;
   height: 57px;
   line-height: 100%;
   position: relative;
   clear: both;
 }
 #enquete-footer #footer-inner .footer_gnavi{float:left;}
 #enquete-footer #footer-inner .footer_gnavi ul li:first-child{
   background-image:none;
   padding:0 3px 0 0;
 }
 #enquete-footer #footer-inner .footer_gnavi ul li{
   background: url("../img/slash_ccc.gif") no-repeat scroll left center transparent;
   color: #999;
   display: inline-block;
   font-size: 60%;
   line-height: 100%;
   padding: 0 3px 0 18px;
 }
 #enquete-footer #footer-inner .footer_gnavi ul li a{color:#999;}
 #enquete-footer #footer-inner .footer_gnavi ul li a:hover{
   color:#fff;
   text-decoration: underline;
 }
 #enquete-footer #footer-inner .footer_gnavi ul li{float:left;}
 #enquete-footer #copyright{
   float: right;
   font-size: 67%;
   text-align: right;
   color: #FFF;
 }
 */
 #center-seo .centerbox, #center3 .centerbox {
   // font-size: 75%;
   font-size: 120%;
   margin: 0px;
   padding: 0 10px 10px;
   word-break: break-all;
 }
 #center-seo h3.title1, #center3 h3.title1 {
   /*border-top: 3px solid #333;
   color: #333;
   font-size: 120%;
   font-weight: bold;
   margin: 25px 0 0;
   padding: 19px 0;*/
   background: #222222;
   color: #FFF;
   // font-size: 120%;
   font-size: 150%;
   font-weight: bold;
   padding: 15px;
 }
 #center-seo #bread, #center3 #bread {
   color: #999;
   margin: 0px;
   padding: 5px 2% 15px;
   text-align: left;
   // font-size: 75%;
   font-size: 120%;
   line-height: 200%;
 }
 #center-seo .mainCont {
   margin: 0px;
   width: auto;
 }
 #center3 .mainCont {
   margin: 0 0 15px;
   width: auto;
 }
 #center3 .centerbox .caution, #EST_SPS_regi .caution {
   color: #d51414;
   font-weight: bold;
 }
 #center-seo .centerbox .caution {
   color: #d51414;
   font-weight: bold;
   text-align: left;
 }
 #center-seo .centerbox table th, #center-seo .centerbox .bordlayoutp3 table th, #center-seo .centerbox table td, #center3 .centerbox table th {
   border-bottom: 1px solid #ccc;
   border-top: 1px solid #ccc;
   color: #2B2B2B;
   font-weight: bold;
   text-align: center;
   vertical-align: middle;
   word-break: normal;
   min-width: 45px;
   padding: 1em 5px;
 }
 #center-seo .centerbox table td {
   text-align: left;
 }
 #center3 .centerbox table th {
   background-color: #efefef;
 }
 #center-seo .centerbox table tr.form_group label {
   white-space: initial !important;
 }
 #center3 .centerbox table th.sps-my-regular-order-number {
   width: 25% !important;
 }
 #center3 .centerbox table th.sps-my-regular-item {
   width: 50% !important;
 }
 #center3 .centerbox table td {
   border-bottom: 1px solid #ccc;
   border-top: 1px solid #ccc;
   overflow: hidden;
   text-align: left;
   min-width: 45px;
   padding: 1em 5px;
   vertical-align: middle;
   word-break: break-all;
 }
 #center3 .centerbox table td[width="5%"] {
   width: 10%;
 }
 #center-seo .centerbox .backcolor1, #center3 .centerbox .backcolor1 {
   background-color: #EEEEEE;
   min-width: 100px;
   text-align: center;
 }
 #center3 .layoutp3 table th,
 form[name$="FORM"] ~ tbody #center3 .layoutp3 table th {
   width: 30%;
   font-weight: bold;
   *width: 0%;
 }
 #center-seo .centerbox th.backcolor1,
 #center-seo .bordlayoutp3 th,
 #center-seo .bordlayoutp3 td,
 #center3 .centerbox th.backcolor1,
 #center3 .bordlayoutp3 th,
 #center-seo .bordlayoutp3 td, #center3 .bordlayoutp3 td {
   border: 1px solid #ccc;
   vertical-align: middle;
   white-space: initial !important;
 }
 #center3 .bordlayoutp3 .layoutp3 th,
 #center3 .bordlayoutp3 .layoutp3 td {
   padding: 0px;
   border: none;
 }
 #center3 .bordlayoutp3 .layoutp3 td.send_info {
   border: 1px solid #999;
 }
 #center3 .bordlayoutp3 .layoutp3 td.send_info + td {
   *width: 70%;
 }
 #center3 .centerbox strong,
 #center3 .centerbox h4 {
   text-align: left;
   font-weight: bold;
   width: 100% !important;
 }
 #center-seo .centerbox .layoutp3 + .bordlayoutp3 > table,
 #center3 .centerbox .layoutp3 + .bordlayoutp3 > table {
   width: 95%;
 }
 #center3 img#OffAmazonPaymentsWidgets1 {
   margin: 15px 0;
 }
 #center-seo select, #center-seo input[type="text"] {
   border: 1px solid #A9A9A9;
   height: 40px;
   line-height: 40px;
   margin: 2px 0px;
   max-width: 350px;
   min-width: inherit;
   width: 100%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor3 div > table tr.backcolor2 td div select {
   border: 1px solid #A9A9A9;
   color: #333;
   height: 40px;
   line-height: 40px;
   padding: 2px 4px;
   margin: 2px 0px;
   min-width: inherit;
 }
 #center3 input[type="text"],
 #center3 input[type="password"] {
   border: 1px solid #A9A9A9;
   color: #333;
   height: 40px;
   line-height: 40px;
   padding: 2px 4px;
   margin: 2px 0px;
   max-width: 350px;
   min-width: inherit;
   width: 100%;
 }
 #center3 select {
   height: 40px;
   line-height: 40px;
   padding: 2px 4px;
   margin: 2px 0px;
   max-width: 350px;
   min-width: inherit;
   width: auto;
 }
 #center3 textarea {
   padding: 2px 4px;
   margin: 2px 0px;
   min-width: inherit;
   width: 100%;
 }
 #center3 textarea[name="Naiyo"], #center3 textarea[name="Naiyo2"] {
   height: auto !important;
 }
 span.sps-requiredPopup.cart_float_right {
   border-radius: 4px;
   background: #e73656;
   color: #fff;
   float: right;
   font-size: 0.7rem;
   margin-left: 0.5em;
   padding: 0.2em 0.5em;
   white-space: nowrap;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor3 div > table tr.backcolor2 td div select,
 #center-seo select,
 #center-seo textarea {
   padding: 1px;
   margin: 2px 0px;
 }
 #center3 select[name="SEL_CARD"] {
   margin-left: 5px;
   padding: 1px;
 }
 #center-seo .centerbox table td input[type="radio"],
 #center3 .centerbox table td input[type="radio"] {
   margin: 0 3px 0 5px;
 }
 table#enter form[name="NEWMEM"] #center3 .centerbox .bordlayoutp3 table td[colspan="2"] input[type="checkbox"] {
   margin: -3px 4px 0px 0px;
 }
 #center-seo p, #center3 p {
   margin: 5px 0px;
   padding: 2px 0px;
 }
 #center3 .centerbox .bordlayoutp3 table td.backcolor2 div form {
   display: inline-block;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor3 div > table tr.backcolor2 td {
   background-color: #FFF;
 }
 #contact #center1 .centerbox .mainCont form[name="NEXT"] h4 + div.right input[type="button"] {
   height: 30px;
   margin: 10px 3px 0;
   padding: 0 1em;
 }
 #contact #center1 .centerbox .mainCont form[name="NEXT"] h4 + div.right input[type="submit"] {
   height: 30px;
   margin: 0px 3px;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.layoutp5.deli_cycle tbody tr td input[type="radio"] {
   margin: 0px 3px;
   height: auto;
 }
 #center-seo .bordlayoutp3 table tbody tr td input[type="button"],
 #center3 .bordlayoutp3 table tbody tr td input[type="button"] {
   margin: 7px auto 0;
 }
 #center3 .bordlayoutp3 table tbody tr td input[type="button"]:first-child {
   margin: 0 auto;
 }
 /*パスワード変更*/
 form[name="PWDCHG"] #center3 .centerbox table tr:first-child td,
 form[name="PWDCHG"] #center3 .centerbox table tr:nth-child(3) td {
   background-color: #EFEFEF;
 }
 form[name="PWDCHG"] #center3 .centerbox table tr:nth-child(3) td br {
   display: none;
 }
 /*メールアドレス変更*/
 form[name="MAILCHG"] #center3 .centerbox table th {
   background-color: #FFF;
 }
 form[name="MAILCHG"] #center3 .centerbox table .backcolor1 th {
   background-color: #efefef;
 }
 form[name="MAILCHG"] #center3 .centerbox table td.center {
   text-align: left;
 }
 /*会員情報の変更/お届け先情報*/
 #center3 .centerbox .bordlayoutp3 table.sps-inputTablePopup input,
 #center3 .centerbox .bordlayoutp3 table.sps-inputTablePopup select {
   height: 40px !important;
 }
 #center3 .centerbox .bordlayoutp3 table.sps-inputTablePopup input[type="checkbox"],
 #center3 .centerbox .bordlayoutp3 table.sps-inputTablePopup input[type="radio"] {
   height: auto !important;
 }
 #center3 .centerbox .bordlayoutp3 table.sps-inputTable-banchiPopup td {
   border: none;
   padding: 0;
 }
 #center3 .centerbox .bordlayoutp3 table.sps-inputTablePopup textarea {
   min-height: 160px;
 }
 #center3 .centerbox .bordlayoutp3 table.sps-inputTable-banchiPopup input[type="text"] {
   height: 40px !important;
 }
 table#enter #center-seo .centerbox .mainCont div.bordlayoutp3 .sps-inputTablePopup td.sps-inputItem,
 table#enter #center-seo .centerbox .mainCont div.bordlayoutp3 .sps-inputTablePopup tr td:first-child {
   background-color: #EFEFEF;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td p.wrapn_select,
 #center3 .centerbox .bordlayoutp3 table tbody tr td p.wrapn_select {
   margin: 0 0 5px;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="WRAP_N"],
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="WRAP_W"],
 #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="WRAP_N"],
 #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="WRAP_W"] {
   margin: 2px 0;
   min-width: 350px;
 }
 table#enter #center3 .centerbox form[name="NEXT"] .mainCont .bordlayoutp3 table tbody td div.send_info {
   width: 80% !important;
 }
 #center3 .centerbox .bordlayoutp3 table tbody tr td table {
   width: 100% !important;
 }
 #center3 .centerbox .bordlayoutp3 table tbody tr td table td {
   padding: 1em 0;
 }
 #center3 .centerbox .bordlayoutp3 table tbody tr td table div {
   margin: 0 0 5px !important;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="RECV_DATE_LIST"],
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="RECV_TIME"],
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td input[name="RECV_NGDAY"],
 #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="RECV_DATE_LIST"],
 #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="RECV_TIME"] {
   width: 350px;
 }
 #center3 .centerbox .bordlayoutp3 #RECVDATE table tbody tr td input[name="RECV_NGDAY"] {
   width: 350px !important;
   margin-left: 0;
 }
 #center3 .centerbox .bordlayoutp3 table tbody tr td input[name="RECV_NGDAY"],
 #center3 .centerbox .bordlayoutp3 #RECVDATE table tbody tr td select[name="RECV_TIME"] {
   width: 350px !important;
   margin-left: -4px;
 }
 /*お届け先情報*/
 .cart-section #contact #center1 .centerbox .mainCont input[value="住所変更"],
 .cart-section #contact #center1 .centerbox .mainCont input[value="その他の情報変更"] {
   background-color: #FFF;
   border: 1px solid #AAA;
   color: #333;
   height: 30px;
   margin: 0;
 }
 .cart-section #contact #center1 .centerbox .mainCont input[value="詳細表示"] {
   background-color: #FFF;
   border: 1px solid #AAA;
   color: #333;
   height: 30px;
   margin-right: 5px;
 }
 .cart-section #contact #center1 .centerbox .mainCont input[value="住所変更"]:hover,
 .cart-section #contact #center1 .centerbox .mainCont input[value="その他の情報変更"]:hover,
 .cart-section #contact #center1 .centerbox .mainCont input[value="詳細表示"]:hover {
   background-color: #FFF;
   border: 1px solid #AAA;
   font-weight: bold;
 }
 /*Amazonログイン*/
 .ui-dialog div[aria-describedby='amazon_dialog'] .ui-dialog-titlebar {
   border-radius: 0;
   border-bottom: 1px dotted #333 !important;
 }
 .ui-dialog div[aria-describedby='amazon_dialog'] .ui-dialog-titlebar .ui-dialog-title {
   font-size: 75%;
   color: #333;
   padding: 3px 0 4px 5px;
 }
 .ui-dialog #amazon_dialog #button_area .agree {
   text-align: center;
 }
 .ui-dialog #amazon_dialog #button_area input.button1 {
   background: none;
   background-color: #333;
   border: 1px solid #333;
   border-radius: 3px;
   color: #FFF;
   font-size: .8rem;
   height: 40px;
   min-width: 148px;
 }
 .ui-dialog #amazon_dialog #button_area input.button1:hover {
   background: none;
   background-color: #666;
   border: 1px solid #666;
 }
 div[aria-describedby='amazon_dialog'] .ui-widget-header,
 div[aria-describedby='amazon_dialog'] .ui-dialog-titlebar {
   background: none;
   border: none;
   border-radius: 0;
   border-bottom: 1px dotted #333 !important;
 }
 div[aria-describedby='amazon_dialog'] .ui-dialog-title {
   font-size: 75% !important;
   padding: 3px 0 4px 5px !important;
 }
 div[aria-describedby='amazon_dialog'] .ui-dialog-content {
   padding: .5em 0;
 }
 div[aria-describedby='amazon_dialog'] #amazon_dialog #button_area {
   text-align: center;
 }
 .leftSearch {
   // background-color: #F5F5F5;
   margin-bottom: 20px;
   overflow: hidden;
 }
 .leftSearch #searchbox_wrap {
   margin: 10px 2% 15px;
   overflow: hidden;
 }
 .leftSearch searchbox {
   color: #999;
   float: right;
   font-size: 65%;
   line-height: 160%;
   overflow: hidden;
   text-align: right;
 }
 .leftSearch #searchbox form {
   // border: 1px solid #777777;
   box-sizing: border-box;
   float: left;
   height: 35px;
   width: 100%;
 }
 .leftSearch #searchbox input.searchbox {
   border: none;
   box-sizing: border-box;
   float: left;
   height: 100%;
   padding: 0 5px;
   width: 80%;
   background-color: #fff;
 }
 .leftSearch #searchbox input.searchSubmit {
   padding: 0;
   width: 20%;
 }
 .leftSearch #searchbox p {
   display: block;
 }
 #header-inner h2 {
   max-width: 340px;
 }
 .siteName {
   margin: 0px;
   padding: 10px 10px 5px 10px;
   font-size: 100%;
   line-height: 1em;
 }
 .siteName a {
   color: black;
 }
 .siteName a:hover {
   color: blue;
   opacity: .70;
   filter: alpha(opacity=70);
   -ms-filter: "alpha(opacity=70)";
   -khtml-opacity: .70;
   -moz-opacity: .70;
 }
 .sitecopy {
   padding: 10px 10px 5px 5px;
   margin: 0px;
   color: #999;
   font-size: 80%;
   line-height: 1em;
 }
 .sitecopy a {
   color: black;
 }
 .sitecopy a:hover {
   color: blue;
   opacity: .70;
   filter: alpha(opacity=70);
   -ms-filter: "alpha(opacity=70)";
   -khtml-opacity: .70;
   -moz-opacity: .70;
 }
 .header_area {
   box-sizing: border-box;
   text-align: left;
   width: 100%;
   margin: 0 0 0px;
   padding: 0;
 }
 .footer_area {
   box-sizing: border-box;
   text-align: left;
   width: 100%;
   margin: 0;
   padding: 0;
 }
 .cart-section .header_area {
   margin: 0 auto;
 }
 .cart-section .footer_area {
   margin: 0 auto;
 }
 .pageNav form img {
   float: right;
 }
 // #sub-column .category .fukidashi nav,
 // #sub-column .category .tatenarabi nav,
 // #sub-column .category #sps-itemListAllCategory nav {
 //   margin-bottom: 0;
 // }
 // #sub-column .category .fukidashi nav:last-child,
 // #sub-column .category .tatenarabi nav:last-child,
 // #sub-column .category #sps-itemListAllCategory section nav:last-child {
 //   margin-bottom: 20px;
 // }
 // #sub-column .category #sps-itemListAllCategory section nav:first-child {
 //   margin-bottom: 0;
 // }
 // #sub-column .category .fukidashi ul {
 //   zoom: 1;
 // }
 // #sub-column .category .fukidashi nav {
 //   position: relative;
 // }
 // #sub-column .category .fukidashi nav .sps-itemCategorySub {
 //   width: 100%;
 //   position: absolute;
 //   top: 0;
 //   left: 100%;
 //   display: none;
 // }
 // #sub-column .category .fukidashi nav:hover .sps-itemCategorySub {
 //   display: block;
 //   z-index: 999;
 // }
 // #sub-column .category .fukidashi nav .sps-itemCategorySub {
 //   padding: 5px;
 //   border-top: 1px solid #DDD;
 //   border-left: 1px solid #DDD;
 //   border-right: 1px solid #aaa;
 //   border-bottom: 1px solid #aaa;
 //   background: #fff;
 // }
 // #sub-column .category .fukidashi nav .sps-itemCategorySub .cat2 a {
 //   color: #333;
 //   font-weight: normal;
 // }
 // #sub-column .category .fukidashi nav .sps-itemCategorySub .cat2 a:hover {
 //   color: #333;
 //   font-weight: normal;
 // }
 // #sub-column .category nav ul li.listcat2 a {
 //   zoom: 1;
 //   display: block;
 //   padding: 15px 12px 15px 2%;
 //   margin: 0px 0;
 //   line-height: 100%;
 //   color: #000;
 //   font-weight: bold;
 //   background: #EEF4FF;
 // }
 // #sub-column .category nav ul li.listcat3 a {
 //   zoom: 1;
 //   display: block;
 //   padding: 15px 12px 15px 6%;
 //   margin: 0px 0;
 //   line-height: 100%;
 //   color: #000;
 // }
 // #sub-column .category nav ul li.child a {
 //   color: #000;
 // }
 section.half-cont div.category section {
   margin: 0 0 23px;
 }
 section.half-cont div.category section:last-child {
   margin: 0;
 }
 section.half-cont div.category section nav ul {
   font-size: 100%;
 }
 section.half-cont div.category section nav ul li {
   font-size: 85%;
   line-height: 150%;
 }
 // #sub-column .category .sps-allList {
 //   margin-bottom: 20px;
 // }
 // #sub-column .category .sps-allList + #sps-itemListAllCategory {
 //   display: none;
 //   margin-top: -20px;
 // }
 #center1 .centerbox .mainCont h3 {
   border-top: 3px solid #333;
   color: #333;
   font-size: 130%;
   font-weight: bold;
   margin: 0;
   padding: 21px 0;
 }
 #contact #center1 .centerbox h3.title1 {
   border: none;
   font-size: 140%;
   color: #222222;
   border-bottom: 3px solid #222222;
   letter-spacing: 0.05em;
   padding: 0px 0 10px;
   margin-bottom: 30px;
   /*border-top: 3px solid #333;
   color: #333;
   font-size: 130%;
   font-weight: bold;
   margin: 30px 0 0 0;
   padding: 21px 0;*/
 }
 #center1 .centerbox .mainCont .column-set .column5 h3,
 #center1 .centerbox .mainCont .column-set .column5 h3 {
   border-top: none;
   font-weight: bold !important;
   margin: 0 0 7px !important;
 }
 .mainCont p.no2 {
   font-size: 130%;
   width: 900px !important;
 }
 /*-- お客様の声をお送りください --*/
 .sps-reviewComment .mainCont p.no2 {
   font-size: 130%;
   width: 900px !important;
   margin: 0 auto;
 }
 .mainCont > .comment_box {
   width: 900px !important;
   margin-top: 15px;
 }
 .mainCont .comment_box input[type="text"],
 .mainCont .comment_box select {
   height: 34px;
 }
 .mainCont .comment_box td.backcolor1 {
   background-color: #EEE;
   border: 1px solid #999;
   box-sizing: border-box;
   color: #2B2B2B;
   font-weight: bold;
   text-align: left;
   word-break: normal;
   width: 15em !important;
 }
 .mainCont .comment_box td.backcolor2 {
   background-color: #fff;
   border: 1px solid #999;
   color: #2B2B2B;
   font-weight: normal;
   padding: 1em;
   text-align: left;
   word-break: normal;
 }
 .mainCont .comment_box td {
   font-size: 100%;
 }
 .mainCont .comment_box td.backcolor2 div.float_left {
   text-align: left;
 }
 .mainCont .comment_box td.backcolor2 div.float_left a {
   text-decoration: underline;
 }
 .mainCont .comment_box td.center {
   text-align: center !important;
 }
 .mainCont .comment_box td.backcolor2 div.write_comment input[type="submit"],
 .mainCont .comment_box td.backcolor2 div.write_comment input[type="button"] {
   background-color: #333;
   border: 1px solid #333;
   border-radius: 3px;
   color: #FFF;
   cursor: pointer;
   font-size: 100%;
   font-weight: normal;
   height: 40px;
   padding: 0 2%;
   text-align: center;
   margin: 2px 2px;
   width: 150px;
 }
 .mainCont .comment_box td.backcolor2 div.write_comment input[type="submit"]:hover,
 .mainCont .comment_box td.backcolor2 div.write_comment input[type="button"]:hover {
   background-color: #666;
   border: 1px solid #666;
 }
 .mainCont .comment_box form[name="form3"] table td.backcolor2 table {
   margin: 0 auto;
   width: auto;
 }
 .mainCont .comment_box table td.backcolor2 input[type="submit"] {
   font-size: 100%;
   width: 150px;
 }
 .mainCont .comment_box td.backcolor2 table input[name="ACC"],
 .mainCont .comment_box td.backcolor2 table input[name="PWD"] {
   height: 32px;
   width: auto !important;
 }
 #center1 .mainCont input[value="商品選択に戻る"], #center1 .mainCont input[value="内容を確認する"] {
   min-width: 180px;
 }
 div.mainCont input[type="button"],
 div.mainCont input[type="submit"] {
   background-color: #333;
   border: 1px solid #333;
   border-radius: 3px;
   color: #FFF;
   cursor: pointer;
   font-size: 100%;
   height: 40px;
   padding: 0 2%;
   text-align: center;
   margin: 5px 2px;
 }
 div.mainCont input[type="button"]:hover,
 div.mainCont input[type="submit"]:hover {
   background-color: #666;
   border: 1px solid #666;
 }
 .itemDescFc {
   position: absolute;
   margin-left: 5px;
 }
 .itemThumb-wrap-right {
   word-break: break-all;
   margin-left: 26%;
 }
 /*まとめ買い*/
 #main-column #itemList .column4 h2.sps-add-to-cart-together, #main-column #itemList .column5 h2.sps-add-to-cart-together,
 #main-column #itemList2 .column4 h2.sps-add-to-cart-together, #main-column #itemList2 .column5 h2.sps-add-to-cart-together {
   font-size: 75%;
   margin: 15px 0 0;
   text-align: right;
 }
 #main-column #itemList .column4 h2.sps-add-to-cart-together input, #main-column #itemList .column5 h2.sps-add-to-cart-together input,
 #main-column #itemList2 .column4 h2.sps-add-to-cart-together input, #main-column #itemList2 .column5 h2.sps-add-to-cart-together input {
   text-align: right;
 }
 #enter #contents {
   width: auto;
 }
 /* フッタご利用ガイド調整用*/
 .footer_area .w100 .freeArea2 {
   border: none;
 }
 .footer_area .w100 .freeArea2 h2 {
   font-size: 90% !important;
 }
 /*まとめ割引詳細用*/
 div[aria-describedby='bundle_dialog'] * {
   font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
 }
 div[aria-describedby='bundle_dialog'] .ui-dialog-content {
   padding: 1em 0 0 !important;
 }
 div[aria-describedby='bundle_dialog'] .ui-dialog-title {
   color: #333;
   font-size: 0.7em;
   padding: 3px 0 4px;
 }
 #bundle_detail > h4 {
   font-size: 0.7em;
   font-weight: bold;
   color: #333;
   padding-bottom: 0.5em;
 }
 #bundle_detail > .bundle_table + h4,
 #bundle_detail > .bundle_table + .bundle_table {
   margin-top: 1.5em;
 }
 #bundle_detail > .bundle_table td {
   padding: 2px 4px;
   border: 1px solid #999;
   font-size: 0.7em;
   color: #333;
   word-break: break-all;
 }
 #bundle_detail > .bundle_table td.bundle_title,
 #bundle_detail > .bundle_table td.bundle_backcolor1 {
   background: #DDD;
 }
 #bundle_detail > .bundle_table td.bundle_backcolor1 {
   text-align: center;
 }
 #bundle_detail > .bundle_table td span.bundle_name {
   display: inline-block;
   margin-top: 3px;
   padding: 3px;
   border: 1px solid #6599cc;
   line-height: 1.3;
 }
 #bundle_detail > div:last-child {
   padding: 1.5em 0 1em;
   text-align: center;
 }
 #bundle_detail > div:last-child input {
   background-color: #333;
   border: 1px solid #333;
   border-radius: 3px;
   color: #FFF;
   font-size: 85%;
   height: 40px;
   padding: 0 2%;
   width: 30%;
 }
 #bundle_detail > div:last-child input:hover {
   background-color: #666;
   border: 1px solid #666;
 }
 div[aria-describedby='bundle_dialog'] .ui-widget-header {
   border: none;
   border-bottom: 1px dotted #333;
   border-radius: 0;
   background: #FFF;
   text-align: left;
   padding: 0;
 }
 #bundle_dialog table {
   border-collapse: collapse;
 }
 .bundle_name {
   color: #6599cc;
   display: inline-block;
   margin-top: 3px;
   padding: 5px;
   border: 1px solid #6599cc;
   border-radius: 3px;
   line-height: 1.3;
   text-decoration: none !important;
 }
 .bundle_name font {
   color: #6599cc;
 }
 #bundle_link {
   font-size: 90%;
 }
 #bundle_link a {
   font-weight: normal;
 }
 /*定期、頒布会商品初回価格*/
 p.rg_price span.regular_price_str {
   color: #2A78D0;
   margin-bottom: 3px;
 }
 p.rg_price span.regular_price {
   color: #2A78D0;
   margin-bottom: 3px;
 }
 /*価格と在庫一覧ポップアップ*/
 #variationPriceAnnounceMailDialog, #spsVarpriceStockDialog1 {
   padding: 1em 0 !important;
 }
 div[aria-describedby='variationPriceAnnounceMailDialog'], div[aria-describedby='spsVarpriceStockDialog1'] {
   padding: 1em;
 }
 #spsVarpriceStockDialog1 > div:last-child input {
   font-size: 90% !important;
   width: 30%;
 }
 div[aria-describedby='spsVarpriceStockDialog1'] #spsVarpriceStockDialog1 #itemStocklist table.contType {
   min-width: 50%;
 }
 div[aria-describedby='spsVarpriceStockDialog1'] #spsVarpriceStockDialog1 #itemStocklist table.contType th,
 div[aria-describedby='spsVarpriceStockDialog1'] #spsVarpriceStockDialog1 #itemStocklist table.contType td {
   font-size: 100%;
   vertical-align: middle;
 }
 div[aria-describedby='spsVarpriceStockDialog1'] #spsVarpriceStockDialog1 #itemStocklist table.contType td.backcolor1 {
   width: 25%;
 }
 div[aria-describedby='spsVarpriceStockDialog1'] #spsVarpriceStockDialog1 #itemStocklist table.contType p {
   font-size: 90%;
   margin: 0;
   padding: 10% 7%;
 }
 /*入荷連絡*/
 #variationPriceAnnounceMailDialog {
   padding: .5em 0 !important;
 }
 #variationPriceAnnounceMailDialog div.layoutp3 > div:last-child input {
   font-size: 100% !important;
   width: 30%;
 }
 #variationPriceAnnounceMailDialog div.layoutp3 + div input:hover,
 #variationPriceAnnounceMailDialog div.layoutp3 > div:last-child input:hover {
   background-color: #666;
   border: 1px solid #666;
 }
 #spsVarpriceStockDialog1 #itemStocklist table.contType {
   width: 100%;
 }
 #spsVarpriceStockDialog1 #itemStocklist td.backcolor2 p a.announceMail img {
   display: block;
   margin-top: 10px;
   width: 110px;
 }
 #spsVarpriceStockDialog1 .cartArea table.variation td a img {
   width: 110px;
 }
 /*-- マイページ --*/
 #center3 .centerbox form[name="Login"] table td {
   border-top: none;
   min-width: 60px;
 }
 #center3 .centerbox form[name="Login"] table tr:last-child td {
   border-bottom: none;
 }
 #center3 input.sps-my-display[type="button"],
 #center3 input.sps-my-change-display[type="button"] {
   background-color: #FFF;
   color: #333;
   height: 34px;
   margin: 5px 0 15px;
 }
 #center3 input.sps-my-display[type="button"]:hover,
 #center3 input.sps-my-change-display[type="button"]:hover {
   background-color: #AAA;
   border: 1px solid #AAA;
   color: #FFF;
 }
 #center3 .bordlayoutp3 input[value="次回から全て再開"] {
   height: 30px;
   max-width: 200px;
   min-width: 170px;
 }
 #center3 .bordlayoutp3 table tbody tr td input[value="再開"] {
   height: 30px;
   margin: 5px auto;
   max-width: 150px;
   min-width: 100px;
   padding: 0;
 }
 #center3 .bordlayoutp3 table tbody tr td input[value="休止"] {
   background-color: #fff;
   color: #333;
   height: 30px;
   margin: 5px auto;
   max-width: 150px;
   min-width: 100px;
   padding: 0;
 }
 #center3 .bordlayoutp3 h4 input {
   height: 30px;
 }
 #center3 .bordlayoutp3 table tbody tr td input[value="変更"] {
   height: 30px;
   max-width: 150px;
   min-width: 100px;
   padding: 0;
 }
 #center3 .bordlayoutp3 table tbody tr td input[type="button"][value="利用する"],
 #center3 .bordlayoutp3 table tbody tr td input[type="button"][value="利用しない"] {
   height: 30px;
   margin-left: 10px;
   max-width: 150px;
   min-width: 100px;
   padding: 0;
 }
 #center3 .centerbox .bordlayoutp3 table tbody tr td table tr td.send_info {
   width: 12em !important;
 }
 #center-seo input[type="submit"], #center-seo input[type="button"], #center3 input[type="submit"], #center3 input[type="button"],
 #contact #center1 .centerbox .mainCont input[type="button"],
 #contact #center1 .centerbox .mainCont input[type="submit"] {
   background: none;
   background-color: #333;
   border: 1px solid #333;
   border-radius: 3px;
   box-sizing: border-box;
   color: #FFF;
   cursor: pointer;
   // font-size: .75rem;
   font-size: 120%;
   height: 40px;
   padding: 0 2%;
   width: 42%;
 }
 #center3 input[type="button"], #center3 input[type="submit"] {
   min-width: 150px;
   width: auto;
 }
 /*-- カート(#EST_SPS_cart)/レジ(#EST_SPS_regi)/注文完了(#EST_SPS_order)アンケート --*/
 .cart-section #contact #center1 .centerbox .mainCont {
   margin-bottom: 20px;
 }
 #contact #center1 .centerbox .mainFrame .mainCont h4 {
   display: inline-block !important;
   font-weight: bold;
   margin: 35px 0 15px;
   border: none;
   font-size: 140%;
   color: #222222;
   border-bottom: 3px solid #222222;
   letter-spacing: 0.05em;
   padding: 0px 0 10px;
 }
 div.cart-section div#contact img[height="1"] {
   display: none;
 }
 .cart-section #contact table td {
   padding: 0;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.border_none td.send_info.border.center {
   text-align: left !important;
   background: no-repeat;
   font-size: 120%;
   font-weight: bold;
   min-width: 80px;
   width: 7% !important;
 }
 #contact #center1 .centerbox .mainCont input[type="button"],
 #contact #center1 .centerbox .mainCont input[type="submit"] {
   margin: 5px 0;
   padding: 0 2em;
   min-width: 100px;
   width: auto;
 }
 #center-seo input[type="submit"]:hover, #center-seo input[type="button"]:hover, #center3 input[type="submit"]:hover, #center3 input[type="button"]:hover,
 #contact #center1 .centerbox .mainCont input[type="button"]:hover,
 #contact #center1 .centerbox .mainCont input[type="submit"]:hover {
   background-color: #222222;
   background-image: none;
   border: 1px solid #FFF;
 }
 /*カゴ画面購入手続きへ進むボタン*/
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="購入手続きへ進む"],
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="確認画面に進む"] {
   background-color: #6599cc;
   border-color: #6599cc;
   color: #FFF;
   font-size: 135%;
   height: 56px;
   letter-spacing: 0.13em;
   padding: 0;
   vertical-align: middle;
   width: 330px;
 }
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="戻る"] {
   font-size: 135%;
   height: 56px;
   letter-spacing: 0.13em;
   margin: 5px 15px 5px 0;
   vertical-align: middle;
   width: 190px;
 }
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="　 次へ　 "],
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="　この内容で注文する　"] {
   background-color: #6599CC;
   border-color: #6599CC;
   color: #FFF;
   font-size: 135%;
   height: 56px;
   letter-spacing: 0.13em;
   padding: 0;
   vertical-align: middle;
   width: 330px;
 }
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="購入手続きへ進む"]:hover,
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="　 次へ　 "]:hover,
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="確認画面に進む"]:hover,
 .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="　この内容で注文する　"]:hover {
   background-color: #87AFD8;
   border-color: #87AFD8;
 }
 .cart-section #contact #center1 .centerbox .mainCont input[value="変更"],
 .cart-section #contact #center1 .centerbox .mainCont input[value="削除"] {
   height: 30px;
   margin: 5px auto;
   max-width: 150px;
   min-width: 100px;
   padding: 0;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor2 input[name="COUPONCODE"] {
   display: inline;
   height: 40px !important;
   margin-left: 5px;
   min-width: 48%;
 }
 /*カートのまとめ割引リンク用*/
 .bundle_display a {
   display: inline-block;
   margin-top: 3px;
   padding: 5px;
   border: 1px solid #6599cc;
   border-radius: 3px;
   line-height: 1.3;
   text-decoration: none !important;
   color: #6599cc;
 }
 .bundle_display a font {
   color: #6599cc;
 }
 .bundle_display a:hover {
   background: #f0f8ff;
 }
 #contact table.cartItem .backcolor2 a#bundle_desc {
   color: #6599cc;
 }
 /*カート内関連商品*/
 #contents .cart-section h4.SPS-cart-related_item {
   border-top: 3px solid #333;
   font-weight: bold;
   letter-spacing: 0.05em;
   padding: 21px 0;
   width: 100%;
 }
 div[aria-describedby='variationPriceAnnounceMailDialog'] .ui-dialog-titlebar-close,
 div[aria-describedby='spsVarpriceStockDialog1'] .ui-dialog-titlebar-close {
   top: 30%;
 }
 input#announceMailAddress {
   width: 99%;
 }
 .sps-enqueteTable input[type="radio"] {
   margin: 0 3px 2px 5px;
 }
 /*支払方法選択*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .side {
   padding: 0;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .point_use_table .side,
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .side .paytypewrap {
   padding: 10px 15px;
 }
 #EST_SPS_cart #contact div.mainCont h4.newMemberinfo {
   margin: 15px 0 0 0;
   font-size: 20px;
   font-weight: normal;
   line-height: 1.5em;
   overflow: hidden;
 }
 #contact #center3 .centerbox .mainCont .layoutp3 #userinfo td {
   padding: 1em 5px;
   text-align: left;
   width: 18%;
 }
 .cartTopMsg {
   margin-top: 1em;
 }
 form[name=COUPON] .backcolor2 {
   padding: 1em !important;
 }
 .login-next {
   margin-top: 1em;
 }
 /*レジ画面プルダウン*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select {
   height: 34px;
   margin: 5px 0;
 }
 .SPS-regi-item_detail {
   display: block !important;
   padding-top: 3em;
 }
 #contact table.cartItem {
   margin: 0;
 }
 #contact select[name^="enq_ans_month"],
 #contact select[name^="enq_ans_day"] {
   min-width: 60px;
 }
 #EST_SPS_cart #contact #center1 .centerbox .mainFrame .mainCont form[name="NEXTM"] table td hr {
   display: block;
   margin: 0 20px 1rem;
   height: 2px;
   background-color: #e0e0e0;
   border: 0;
 }
 .cartBtnPreviousBottom {
   display: inline-block;
   vertical-align: middle;
 }
 .itemPic img {
   height: auto;
   min-width: 50px;
 }
 .SPS-fontlarge {
   font-size: 1.2em;
   vertical-align: middle;
 }
 #EST_SPS_order .sps-noregist {
   cursor: pointer;
   display: inline-block;
   margin-top: 0.5em;
   font-size: 0.8em;
 }
 .sps-coupon_notice {
   font-size: 0.8em;
 }
 /*お届けスケジュールカレンダー表示*/
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 #deli_scadule .deli_plan_cal td.first {
   padding: 1rem 0.5% 1rem 1% !important;
   width: 32%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 #deli_scadule .deli_plan_cal td.second {
   padding: 1rem 0.5% !important;
   width: 32%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 #deli_scadule .deli_plan_cal td.third {
   padding: 1rem 1% 1rem 0.5% !important;
   width: 32%;
 }
 #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 #deli_scadule .deli_plan_cal th {
   padding: 20px;
   text-align: center;
 }
 /*会員登録完了*/
 #contact #center3 .centerbox h3.title1 {
   font-size: .9rem;
   letter-spacing: 0.05em;
   padding: 21px 0;
 }
 #contact #center3 .centerbox .mainCont p {
   font-size: .8rem;
 }
 /*-----------------------------------------------------*/
 /*  for -960px                  */
 /*-----------------------------------------------------*/
 @include s.media-origin-lg {
   #header-inner {
     box-sizing: border-box;
     // padding: 0 2%;
     // padding: 0;
   }
   input[type="text"] {
     display: block;
   }
   .column-set .quantityArea input[type="text"], div.enquete-section input[type="text"], #center1 input[name="RECV_NGDAY"], #center3 input[name="RECV_NGDAY"],
   h2.sps-add-to-cart-together input[type="text"], section#itemDetail-wrap table.spec td input, .column4 h2 input, .column5 h2 input {
     display: inline;
   }
 }
 /*-----------------------------------------------------*/
 /*  for -768px                  */
 /*-----------------------------------------------------*/
 @include s.media-sp {
   #wrapper {
     width: 100%;
     min-width: 100%;
   }
   #header-inner {
     background-size: cover !important;
     box-sizing: border-box;
     // padding: 0 2%;
     position: relative;
     width: 100%;
   }
   #header-inner #logo {
     display: block;
     // margin-bottom: 10px;
     // min-height: 46px;
     // padding: 20px 0 0;
     // text-align: left;
     // width: 33%;
   }
   #header-inner #hNav {
     display: block;
     // overflow: hidden;
     // margin: 15px 0 0;
     width: 100%;
   }
   // #hNav ul#keyword li a {
   //   background-color: #EFEFEF;
   //   border-radius: 3px;
   //   display: inline-block;
   //   float: none;
   //   margin: 0 0 2%;
   //   padding: 2%;
   // }
   /*メニュー(768px)*/
   // #hNav nav, #hNav .cart-nav {
   //   position: absolute;
   //   top: 20px;
   //   right: 2%;
   // }
   // #hNav ul#header-nav li {
   //   color: #333;
   //   font-size: 80%;
   //   margin-right: 62px;
   //   padding: 0 5px;
   // }
   // #hNav ul#header-nav li#header-nav-mypage a::before,
   // #hNav ul#header-nav li#header-nav-cart a::before {
   //   display: block;
   //   height: 28px;
   //   margin: 0 auto 3px;
   //   top: 0;
   //   width: 28px;
   // }
   // #hNav ul#header-nav li#header-nav-cart {
   //   margin-left: -62px;
   // }
   // #hNav ul#header-nav li#header-nav-drawer {
   //   background: #FFF;
   //   border: 1px solid #888;
   //   border-radius: 5px;
   //   box-sizing: border-box;
   //   cursor: pointer;
   //   display: inline-block;
   //   height: 46px;
   //   margin: 0;
   //   padding: 0;
   //   position: fixed;
   //   right: 2%;
   //   top: 20px;
   //   white-space: nowrap;
   //   width: 46px;
   //   z-index: 150;
   // }
   // #header-nav-drawer span {
   //   display: block;
   //   position: absolute;
   //   top: 50%;
   //   left: 50%;
   //   width: 24px;
   //   height: 3px;
   //   margin: -3px 0 0 -12px;
   //   background: #777;
   //   transition: .2s;
   // }
   // #header-nav-drawer span::before, #header-nav-drawer span::after {
   //   display: block;
   //   content: "";
   //   position: absolute;
   //   top: 50%;
   //   left: 0;
   //   width: 24px;
   //   height: 3px;
   //   background: #777;
   //   transition: .3s;
   // }
   // #header-nav-drawer span::before {
   //   margin-top: -10px;
   // }
   // #header-nav-drawer span::after {
   //   margin-top: 7px;
   // }
   // #header-nav-drawer.spsDrawerOpen span {
   //   background: transparent;
   // }
   // #header-nav-drawer.spsDrawerOpen span::before, #header-nav-drawer.spsDrawerOpen span::after {
   //   margin-top: 0;
   // }
   // #header-nav-drawer.spsDrawerOpen span::before {
   //   -webkit-transform: rotate(-45deg);
   //   transform: rotate(-45deg);
   // }
   // #header-nav-drawer.spsDrawerOpen span::after {
   //   transform: rotate(-135deg);
   //   -webkit-transform: rotate(-135deg);
   // }
   /*-- 検索ボックス(768px) --*/
   #hNav #searchbox_wrap {
     margin: 0 0 10px;
     width: 100%;
   }
   #hNav #searchbox {
     float: none;
     overflow: hidden;
     margin: 0px auto;
     width: 100%;
   }
   #hNav #searchbox p {
     display: none;
     margin: 0;
   }
   #hNav #searchbox form {
     height: 50px;
     position: relative;
     width: 100%;
   }
   #hNav #searchbox input.searchbox {
     height: 48px !important;
     padding: 0 70px 0 5px;
     width: 100%;
   }
   #hNav #searchbox input.searchSubmit {
     background-color: #777;
     height: 48px;
     position: absolute;
     right: 0;
     top: 0;
     width: 70px;
   }
   input[type="text"], input[type="password"], input[type="number"]  {
     border-radius: 0;
     // height: 40px !important;
     height: 6.4rem;
     box-sizing: border-box;
   }
   input[type="button"], input[type="submit"] {
     height: 40px;
     box-sizing: border-box;
   }
   input[type="image"] {
     // border-radius: 0;
     height: auto;
     box-sizing: border-box;
   }
   select {
     background-color: #FFF;
     border: 1px solid #A9A9A9;
     border-radius: 0;
     box-sizing: border-box;
     height: 40px;
   }
   /*グローバルナビ(768px)*/
   #gnav div.cart-nav, #gnav nav {
     width: 100%;
   }
   #contents {
     width: 100%;
   }
   #main-column {
     box-sizing: border-box;
     float: none;
     margin-bottom: 0;
     // padding: 0 2%;
     // padding: 0 4rem;
     width: 100%;
   }
   .w100, article section, section {
     box-sizing: border-box;
   }
   section section {
     padding: 0;
   }
   /*店長日記*/
   #main-column .freeArea2.shopDiary p a {
     margin: 15px 0 0 66%;
     padding: 1%;
     width: 34%;
   }
   .half-cont, .half-cont:nth-child(2n) {
     box-sizing: border-box;
     float: none;
     margin: 0;
     width: 100%;
   }
   .newItem, .pickup, .ranking {
     padding: 0;
     margin: 0 0 40px;
   }
   #main-column .half-cont_wrap01 .half-cont .category h2,
   #main-column .half-cont_wrap02 .half-cont .category h2,
   #main-column .half-cont_wrap01 .half-cont .group h2,
   #main-column .half-cont_wrap02 .half-cont .group h2 {
     padding: 21px 0;
     font-size: 100%;
   }
   /*-- 商品一覧(768px) --*/
   #bread-crumb {
     margin: 0;
   }
   .column4 .itemThumb, .column5 .itemThumb, .column4 .item-list-span-img,
   .column4 .itemThumb a, .column5 .itemThumb a, .column5 .item-list-span-img {
     height: 100%;
     width: 100%;
   }
   .column4 .itemThumb a img, .column5 .itemThumb a img {
     height: 100%;
     width: 100%;
     max-height: 100%;
     max-width: 100%;
   }
   .column4 p.sps-itemList-stockDisp a, .column5 p.sps-itemList-stockDisp a {
     font-size: 95%;
     margin: 0;
     padding: 1% 2%;
     width: 100%;
   }
   #itemList {
     margin: 30px 0 0 0;
   }
   #itemList_wrap #pager-top {
     padding: 20px 0 0 0;
   }
   #itemList_wrap #pager-top .pagerlist,
   #itemList_wrap #pager-bm .pagerlist{
     margin-top: 10px;
   }
   /*写真のみ(768px)*/
   // #itemList .column4 .sps-wishAddItem img, #itemList .column5 .sps-wishAddItem img,
   // #itemList .column4 .sps-wishAddItemOk img, #itemList .column5 .sps-wishAddItemOk img {
   //   margin: 10px 0 0 75%;
   //   width: 25%;
   // }
   /*説明付き(768px)*/
   // #itemList2 .column4 .sps-wishAddItem img, #itemList2 .column5 .sps-wishAddItem img,
   // #itemList2 .column4 .sps-wishAddItemOk img, #itemList2 .column5 .sps-wishAddItemOk img {
   //   margin-left: 88%;
   //   width: 12%;
   // }
   /*まとめ買い(768px)*/
   h2.sps-add-to-cart-together input[type="text"] {
     display: inline;
     height: 34px;
   }
   h2.sps-add-to-cart-together select {
     height: 34px;
   }
   .column4 h2 select, .column5 h2 select {
     height: 34px;
     width: 40px;
   }
   .column4 h2 input, .column5 h2 input {
     height: 34px;
     padding: 0 2%;
     text-align: right;
     width: 40px;
   }
   /* 入荷連絡(768px) */
   #variationPriceAnnounceMailDialog #center3 table.bordlayoutp3 th {
     border-left: none;
     border-right: none;
     box-sizing: border-box;
     display: block;
     padding: 10px 5px;
     width: 100%;
   }
   #variationPriceAnnounceMailDialog #center3 table.bordlayoutp3 td {
     box-sizing: border-box;
     display: block;
     border: none;
     padding: 10px 5px;
     width: 100%;
   }
   div[aria-describedby='variationPriceAnnounceMailDialog'], div[aria-describedby='spsVarpriceStockDialog1'] {
     left: 2% !important;
     right: 2%;
     position: absolute !important;
     width: 96% !important;
   }
   /*-- 商品詳細ページ(768px) --*/
   #itemDetail-cont .itemThumb-wrap {
     // margin: 0px 2% 0px 0px;
     // width: 40%;
   }
   #itemDetail-cont .itemThumb-wrap .itemThumb-main {
     height: auto;
   }
   // #itemDetail-cont .itemOutline {
   //   width: 58%;
   // }
   #itemDetail-cont .itemThumb-wrap .itemThumb ul li {
     margin-right: 1%;
     text-align: center;
     width: 24%;
   }
   .itemOutline ul.snsArea, article#Detail .itemOutline ul.infoArea {
     height: auto;
   }
   // section#itemDetail-wrap table.spec tr.option_area td select.option_parts {
   //   height: 34px;
   //   width: 85%;
   // }
   // section#itemDetail-wrap table.spec tr.option_area td input.option_parts,
   // section#itemDetail-wrap table.spec tr.option td input {
   //   height: 34px !important;
   //   width: 85%;
   // }
   // section#itemDetail-wrap table.spec tr.quantityArea td select.quantity {
   //   height: 34px;
   //   width: 85%;
   // }
   // section#itemDetail-wrap table.spec td input {
   //   height: 34px !important;
   //   width: 20%;
   // }
   /*関連商品(768px)*/
   .column5 {
     float: left;
     width: 22%;
     margin: 0 4% 10px 0;
     padding-bottom: 10px;
   }
   .column5:nth-child(3n+1) {
     clear: none;
   }
   .column5:nth-child(4n+1) {
     clear: both;
   }
   .column5:nth-child(4n) {
     margin: 0 0 10px 0;
   }
   .column5:nth-child(5n) {
     margin: 0 4% 10px 0;
   }
   .column5:nth-child(5n+1) {
     clear: inherit;
   }
   .column5:nth-child(20n) {
     margin: 0 0 10px 0;
   }
   .column5:nth-child(20n+1) {
     clear: both;
   }
   section.recommend .column-set .quantityArea input,
   section.recommend .column-set .quantityArea select {
     display: inline;
     height: 34px !important;
     width: 40px;
   }
   /*ポップアップ全般(768px)*/
   .ui-dialog {
     width: 96% !important;
     left: 2% !important;
     top: 20px !important;
     max-height: 80vh;
     overflow: auto;
   }
   .ui-dialog .ui-dialog-content, .ui-widget-content {
     max-height: 80vh !important;
   }
   div#sps-cartaddModal {
     width: 100% !important;
   }
   .ui-dialog #notChangeCycleDialog.ui-dialog-content {
     height: initial !important;
   }
   div[aria-describedby='notChangeCycleDialog'] {
     position: fixed;
   }
   div[aria-describedby='amazon_dialog'] {
     position: fixed !important;
   }
   /*入荷連絡/在庫一覧スクロール(768px)*/
   #variationPriceAnnounceMailDialog, #spsVarpriceStockDialog1 {
     height: 96% !important;
     max-height: 96% !important;
   }
   div[aria-describedby='variationPriceAnnounceMailDialog'], div[aria-describedby='spsVarpriceStockDialog1'] {
     position: fixed !important;
   }
   /*商品画像ポップアップ(768px)*/
   .ui-dialog div#dialog {
     height: 93% !important;
   }
   .fl_contents .fl_main {
     float: none;
     margin: 0 0 10px;
     overflow: initial;
   }
   .fl_main div {
     max-width: 85% !important;
     display: table-cell;
     vertical-align: middle;
     float: none !important;
   }
   .fl_contents .fl_thumbnail {
     box-sizing: border-box;
     float: none;
     max-width: none !important;
     overflow: hidden;
     width: 100%;
   }
   /*ダイアログ-商品を追加しました(768px)*/
   div.sps-cartaddBtnContent {
     width: 460px;
     margin: 0 auto;
   }
   div[aria-describedby='sps-cartaddModal'] {
     height: auto !important;
   }
   // section#itemDetail-wrap .cartArea table.variation th.sps-variation-cart {
   //   min-width: 69px;
   // }
   #spsVarpriceStockDialog1 table.variation th.sps-variation-stock {
     max-width: 120px;
     min-width: 100px;
     width: 121px !important;
   }
   // section#itemDetail-wrap .cartArea table.variation th.sps-variation-cart {
   //   max-width: 121px;
   //   min-width: 100px;
   //   width: 121px !important;
   // }
   section#itemDetail-wrap .cartArea table.variation td input[type="image"] {
     height: auto;
     // max-width: 110px;
   }
   #center3 .centerbox table td.backcolor2 input[type="text"] {
     width: 98%;
   }
   .fl_contents {
     width: auto !important;
   }
   .fl_main {
     display: table;
     width: 100% !important;
   }
   .fl_main div {
     left: 0 !important;
   }
   .fl_main_img {
     height: initial !important;
     padding-top: 0 !important;
     margin: 0 auto;
     max-width: 100% !important;
   }
   #thum_next, #thum_prev {
     min-width: 15px;
     padding-left: 5px !important;
     padding-top: 0 !important;
   }
   .fl_t_item {
     box-sizing: border-box;
     width: 18% !important;
     margin: 1% !important;
     padding: 2% !important;
   }
   .fl_img_selected {
     border: 1px solid #C9E1F4 !important;
     margin: 1% !important;
     padding: 2% !important;
   }
   /*-- サイドカラム(768px) --*/
   #sub-column {
     /*background-color: #F5F5F5;*/
     // background: #FFF;
     box-sizing: border-box;
     float: none;
     height: 100%;
     margin: 0;
     overflow-y: auto;
     // padding: 50px 4% 0;
     position: fixed;
     // right: -60%;
     right: -100%;
     top: 0;
     // width: 60%;
     width: 100%;
     background-color: s.$color-green;

   }
   /* サイドメニューオープン(768px) */
   #sub-column.spsDrawerOpen {
     transition: .5s transform ease-in-out;
     transform: translateX(-100%);
     -webkit-overflow-scrolling: touch;
     z-index: 101;
   }
   #wrapper.spsDrawerOpen::before {
     background: rgba(0, 0, 0, 0.6);
     content: "";
     height: 100%;
     left: 0;
     position: fixed;
     top: 0;
     width: 100%;
     z-index: 50;
   }
   #sub-column.spsDrawerClose {
     transition: .5s transform ease-in-out;
     transform: translateX(0);
     -webkit-overflow-scrolling: touch;
     z-index: 100;
   }
   // #sub-column .group, #sub-column #followUs, #sub-column .newItem, #sub-column .link {
   //   margin-bottom: 20px;
   // }
   // #sub-column h2 {
   //   font-size: 110%;
   //   /*background-color: #F5F5F5;*/
   //   padding: 0 0 10px;
   // }
   // #sub-column .category {
   //   margin-bottom: 40px;
   // }
   // #sub-column .group nav ul li a, #sub-column .category nav ul li a, #sub-column .link nav ul li a {
   //   font-size: 90%;
   // }
   // #sub-column .freeArea2 .free-contents {
   //   /*background-color: #FFF;
   //   font-size: 80%;
   //   padding: 2%;
   //   */
   // }
   // .freeArea2.shopDiary p {
   //   font-size: 100%;
   // }
   /*-- フッター/ドロワー内 検索ボックス(768px) --*/
   .leftSearch {
     box-sizing: border-box;
     margin: 0;
     overflow: hidden;
   }
   .leftSearch #searchbox_wrap {
     // margin: 15px 0;
     width: 100%;
     margin: 9rem 0 0;
   }
   .leftSearch #searchbox {
     overflow: hidden;
     margin: 0px auto;
     width: 100%;
   }
   .leftSearch #searchbox form {
     height: 50px;
     position: relative;
     width: 100%;
   }
   .leftSearch #searchbox input.searchbox {
     height: 48px !important;
     padding: 0 70px 0 5px;
     width: 100%;
   }
   .leftSearch #searchbox input.searchSubmit {
     background-color: #777;
     height: 48px;
     position: absolute;
     right: 0;
     top: 0;
     width: 70px;
   }
   /*ドロワー内 カレンダー(768px)*/
   #subc_calendar {
     box-sizing: border-box;
     margin-bottom: 0;
     padding: 0;
     width: 100%;
   }
   div.subc_cal_cont {
     box-sizing: border-box;
     margin-bottom: 30px;
     width: 100%;
   }
   div#subc_calendar h2 {
     box-sizing: border-box;
     font-size: 110%;
     padding: 10px 0;
     width: 100%;
   }
   div.subc_cal_cont .cap .cap-info {
     background-color: #FFF;
     padding: 10px 0;
   }
   /*ドロワー内 メルマガ(768px)*/
   #sub-column #snav_mailMag {
     box-sizing: border-box;
     width: 100%;
   }
   #sub-column div.snav_mailMag_cont {
     box-sizing: border-box;
     text-align: center;
     width: 100%;
   }
   #sub-column div.snav_mailMag_cont p:last-child {
     background-color: #FFF;
     height: auto;
     line-height: 130%;
     margin: 15px 0 10px 24%;
     padding: 10px 1%;
   }
   /*フッター/ドロワー内 注目キーワード(768px)*/
   #sub-column .link.keyword nav {
     padding: 5px 2% 0;
   }
   #sub-column .link.keyword nav ul li {
     border: none;
     background-color: inherit;
     display: inline;
   }
   #sub-column .link.keyword nav ul li a {
     background-color: #FFF;
     border-radius: 3px;
     display: inline-block;
     margin: 0 0 2%;
     padding: 3% 2%;
   }
   #sub-column .link.keyword nav ul li a:hover {
     color: #999;
   }
   #sub-column .keyword nav ul li a::after {
     display: none;
   }
   /*-- サイトマップ(768px) --*/
   section #document .txt-list03 section.tree00 {
     float: none;
     margin: 0 0 20px;
     padding: 0;
     width: 100%;
   }
   /*-- お客様の声(768px) --*/
   article #main-column section.rating .tmb {
     margin-right: 2%;
     width: 23%;
   }
   article #main-column section.rating .reviewTxt {
     width: 75%;
   }
   article #main-column section.rating h2 {
     display: block;
     width: 100%;
   }
   article #main-column section.rating .btnMore {
     display: block;
     margin: 10px 0 0 0;
     width: 170px;
   }
   article #main-column form[name="REVIEWLIST"] .btnMore img {
     width: 190px;
   }
   /*-- カート/アンケート(768px) --*/
   #cart-header #hNav ul#header-nav li#header-nav-cart,
   #enquete-header #hNav ul#header-nav li#header-nav-cart {
     margin-left: 0;
   }
   #cart-header #hNav ul#header-nav li,
   #enquete-header #hNav ul#header-nav li {
     margin-right: 0;
   }
   #main-column-full {
     box-sizing: border-box;
     padding: 0 2%;
     width: 100%;
   }
   #cart-header #header-inner {
     display: table;
   }
   #cart-header #main-nav {
     display: none;
   }
   #contact {
     width: 100%;
     margin: 0 auto;
   }
   #center1 .centerbox .mainCont {
     padding: 10px 0;
   }
   #contact #center1 .centerbox .mainCont .step_navi01, #contact #center1 .centerbox .mainCont .step_navi02,
   #contact #center1 .centerbox .mainCont .step_navi03, #contact #center1 .centerbox .mainCont .step_navi04 {
     background-size: 100%;
     margin: 0 auto;
   }
   .cart-section #contact #center1 .centerbox .mainCont input[type="submit"] {
     margin: 5px 0;
     padding: 0 1em;
     min-width: 50px;
     width: auto;
   }
   .cart-section #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input[type="submit"] {
     padding: 0 6%;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input {
     margin-right: 5px;
     max-width: 50px;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input[value="削除"] {
     max-width: 60px;
     min-width: 34px;
     width: auto;
   }
   /*削除ボタン*/
   .sps-coupon_notice {
     display: block;
   }
   /*SSL*/
   .sps-sslSticker-description {
     line-height: 140%;
     margin: 0 !important;
     width: auto !important;
   }
   /*カート内関連商品(768px)*/
   #contents .cart-section .column5 {
     float: left;
     width: 22% !important;
     margin: 0 4% 10px 0 !important;
     padding-bottom: 10px !important;
   }
   #contents .cart-section .column5:nth-child(4n) {
     margin: 0 0 10px 0 !important;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor2 input[type="text"]#PREPAIR_POINT {
     display: inline;
   }
   /*会員情報の変更/お届け先情報(768px)*/
   #contact #center1 .centerbox #cartNewmem #newMemberTitle h3.title1 {
     font-size: 150%;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td,
   #contact .sps-inputTable td, #center3 .sps-inputTablePopup td,
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td.sps-inputItem,
   #contact .sps-inputTable td.sps-inputContent, #center3 .sps-inputTablePopup td.sps-inputItem,
   table#enter #center-seo .centerbox .mainCont div.bordlayoutp3 .sps-inputTablePopup td {
     border-left: 0;
     border-right: 0;
     box-sizing: border-box;
     display: block;
     width: 100% !important;
   }
   table#enter #center-seo .centerbox .mainCont div.bordlayoutp3 .sps-inputTablePopup td:nth-child(2) {
     border: none;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td table.sps-inputTable-banchi td {
     border: none;
     width: 100% !important;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td table.sps-inputTable-banchi tr td:first-child {
     padding: 0 !important;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td table.sps-inputTable-banchi tr td:nth-child(2) {
     padding: 0 0 1rem !important;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable tr:last-child td {
     border: none;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td.sps-inputContent,
   #contact .sps-inputTable td.sps-inputConten,
   #center3 .sps-inputTablePopup td.sps-inputContent {
     border: none;
     box-sizing: border-box;
     display: block;
     width: 100% !important;
   }
   #center3 .sps-inputTablePopup td:first-child {
     font-weight: bold;
     text-align: left;
   }
   #center3 .sps-inputTablePopup input[type="text"],
   #center3 .sps-inputTablePopup input[type="password"],
   #center3 .sps-inputTablePopup select {
     max-width: 100%;
     width: 100%;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td:nth-child(2n),
   #center3 .sps-inputTablePopup td:nth-child(2n) {
     border: none;
   }
   #center3 .sps-inputTablePopup td.form_group {
     line-height: 225%;
   }
   #contact #center1 .centerbox .mainFrame .mainCont td#cartNewmem #newMemberTitle .mainCont input[type="text"],
   #contact #center1 .centerbox .mainFrame .mainCont td#cartNewmem #newMemberTitle .mainCont textarea {
     width: 100%;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 .sps-inputTable td.sps-inputContent input[type="text"],
   #center3 .sps-inputTablePopup .sps-inputContent input[type="text"],
   #center3 .sps-inputTablePopup td input[type="text"], #center3 .sps-inputTablePopup td select[name="Pref"] {
     max-width: initial;
     width: 100% !important;
   }
   #center3 table.sps-inputTablePopup table.sps-inputTable-banchiPopup td {
     background-color: #FFF;
     display: block;
     width: 100% !important;
   }
   #center3 table.sps-inputTablePopup table.sps-inputTable-banchiPopup tr td:first-child {
     padding: 0;
   }
   #center3 table.sps-inputTablePopup table.sps-inputTable-banchiPopup tr td:nth-child(2) {
     padding-top: 0;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td p.wrapn_select span,
   #center3 .centerbox .bordlayoutp3 table tbody tr td p.wrapn_select span {
     margin: 0 10px 0 0;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="RECV_TIME"],
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="RECV_DATE_LIST"],
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td input[name="RECV_NGDAY"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="RECV_TIME"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="RECV_DATE_LIST"],
   #center3 .centerbox .bordlayoutp3 #RECVDATE table tbody tr td select[name="RECV_TIME"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td input[name="RECV_NGDAY"] {
     width: 100% !important;
     margin-left: 0;
     max-width: inherit;
   }
   #center3 .centerbox .bordlayoutp3 table tbody tr td input[name="RECV_NGDAY"],
   #center3 .centerbox .bordlayoutp3 #RECVDATE table tbody tr td input[name="RECV_NGDAY"] {
     width: 100% !important;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="WRAP_N"],
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="WRAP_W"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="WRAP_N"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="WRAP_W"] {
     display: block;
     max-width: inherit;
     width: 100% !important;
   }
   /*お届けスケジュール(768px)*/
   #deli_scadule .deli_scadule_box div.backcolor3.deli_plan table.layoutp5.deli_plan_day tbody tr td.backcolor1 {
     width: 30% !important;
   }
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_scadule_box table.deli_cycle td {
     background-color: none;
     border: none;
     display: block;
     padding: 0 !important;
     width: 100%;
   }
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.first,
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.second,
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.third {
     box-sizing: border-box;
     display: block;
     padding: 1rem 1% !important;
     width: 100%;
   }
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.deli_plan_calday {
     box-sizing: border-box;
     display: block;
     width: 100%;
   }
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.first th,
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.second th,
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.third th,
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.first td,
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.second td,
   #contact #center1 .centerbox .mainCont .bordlayoutp3 #deli_scadule .deli_plan_box table.deli_plan_cal td.third td {
     box-sizing: border-box;
     padding: 3% !important;
     width: 13% !important;
   }
   #center-seo .centerbox .layoutp3 + .bordlayoutp3 > table, #center3 .centerbox .layoutp3 + .bordlayoutp3 > table {
     width: 100% !important;
   }
   /*SSL(768px)*/
   .sps-sslSticker-description {
     margin: 0 !important;
     padding: 2% 0 0;
     width: 100% !important;
   }
   /*お届けスケジュール確認ポップアップ(768px)*/
   div[aria-describedby='allChangeScheduleStatus'],
   div[aria-describedby='changeScheduleStatus'] {
     position: fixed !important;
   }
   /*新規会員登録table(768px)*/
   #contact #center3 .centerbox .mainCont .layoutp3 #userinfo td {
     box-sizing: border-box;
     display: block;
     padding: 1em 5px;
     text-align: left;
     width: 100% !important;
   }
   #contact #center3 .centerbox .mainCont .layoutp3 #userinfo td.backcolor1 {
     font-weight: bold;
   }
   #contact #center3 .centerbox .mainCont .layoutp3 #userinfo td.backcolor2,
   #contact #center3 .centerbox .mainCont .layoutp3 #userinfo td:nth-child(2) {
     border: none;
     min-height: 44px;
   }
   /*-- アンケート(768px) --*/
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr {
     padding-bottom: 10px;
   }
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr td input {
     width: 140px;
   }
   /*メールマガジン登録・解除(768px)*/
   #contact table.mailMF input.mailAddress, #contact table.mailMF input.mailAddress_new {
     width: 100%;
   }
   #contact table.mailMF input.btn-confirmNext {
     margin: 15px 0;
   }
   #contact.sps-mailMagazine .sps-mailMagazine-freeArea {
     margin: 60px 0 0;
     width: 100%;
   }
   #contact .mailm .txtBox h2 {
     padding: 15px;
     background-color: #EEE;
     border-top: 1px solid #DEDEDE;
     border-bottom: 1px solid #DEDEDE;
   }
   #contact table.mailMF tbody tr th {
     display: none;
   }
   #contact table.mailMF tbody tr td {
     border: none;
     box-sizing: border-box;
     display: block;
     width: 100%;
   }
   #contact table.mailMF tbody tr td input[type="text"] {
     border: 1px solid #A9A9A9;
     float: none;
   }
   #contact table.mailMF span.btn-confirm {
     float: none;
   }
   #contact table.mailMF tbody tr td input[type="button"] {
     float: none;
     margin: 15px auto;
   }
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr td input {
     margin-left: 0;
   }
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr:first-child td input {
     margin-left: 12px;
   }
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr:first-child td:nth-child(2) input {
     margin-left: 8px;
   }
   /*フッター(768px)*/
   div#cart-footer, footer {
     min-width: 100%;
     width: 100%;
   }
   /*
   #footer-inner {
    background-color: #222222;
    width: 100%;
   }
   div#cart-footer #footer-inner .footer_gnavi, footer #footer-inner .footer_gnavi {
    float: none;
   }
   div#cart-footer div.cart-nav, footer nav, #copyright {
    float: none;
   }
   */
   #contents #main-column .footer_area {
     margin: 0;
   }
   #subc_freeArea {
     margin: 0;
     padding: 5px 2% 20px;
   }
   #subc_freeArea img {
     margin: 0;
   }
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] table {
     width: 100%;
   }
   /*-- お問い合わせ/商品についてのお問い合わせ/お友達に教えるポップアップ --*/
   form[name$="FORM"] ~ tbody #center3 .layoutp3 table th {
     box-sizing: border-box;
     display: block;
     text-align: left;
     width: 100%;
   }
   form[name$="FORM"] ~ tbody #center3 .layoutp3 table td {
     border: none;
     box-sizing: border-box;
     display: block;
     width: 100%;
   }
   /*-- お客様の声をお送りください(768px) --*/
   .sps-reviewComment .mainCont p.no2 {
     width: 100% !important;
   }
   .mainCont .comment_box {
     text-align: left;
     width: 100% !important;
   }
   .mainCont .comment_box table {
     table-layout: fixed;
   }
   .mainCont .comment_box table td.backcolor1 {
     border-left: none;
     border-right: none;
     display: block;
     width: 100% !important;
   }
   .mainCont .comment_box table td.backcolor2 {
     border: none;
     display: block;
     padding: 1em 0;
     width: 100%;
   }
   .mainCont .comment_box form[name="form3"] table table td input[type="password"] {
     width: 100% !important;
   }
   .mainCont .comment_box form[name="form3"] table td.backcolor2 table input[type="text"] {
     width: 100% !important;
   }
   .mainCont .comment_box table td.backcolor2 br {
     display: none;
   }
   #to-cart a:hover img, #page-top a:hover img {
     opacity: 1;
   }
 }
 /*-----------------------------------------------------*/
 /*  for -580px                  */
 /*-----------------------------------------------------*/
 @media screen and (max-width: 580px) {
   /*商品一覧*/
   #itemList_wrap #pager-top .selectbox .select01 {
     // margin: 0 0 15px;
     width: 100%;
   }
   #center3 input[type="submit"] {
     display: block;
     margin: 0 auto 12px !important;
     min-width: 150px;
     width: auto;
   }
   #center3 input[type="submit"][value="　　決定　　"] {
     display: inline;
   }
   #center3 .layoutp3 input[type="button"][value="キャンセル"] {
     margin: 0 auto;
     min-width: 150px;
     width: auto;
   }
   #center3 .bordlayoutp3 table tbody tr td input[type="button"] {
     display: block;
     margin: 0;
     padding: 0 2%;
     width: 100%;
   }
   #center3 .bordlayoutp3 table tbody tr td input[type="button"]:nth-child(n+2) {
     margin: 7px auto 0;
   }
   /*カート(580px)*/
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.sps-inputTable td.sps-inputContent table.sps-inputTable-banchi td {
     display: block;
     padding: 0 !important;
     width: 100% !important;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.sps-inputTable td.sps-inputContent table.sps-inputTable-banchi tr:first-child td div {
     margin-bottom: 10px;
   }
   .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="購入手続きへ進む"] {
     margin: 0;
     width: 100%;
   }
   .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="戻る"] {
     margin: 10px 0 0;
     width: 100%;
   }
   .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="　 次へ　 "],
   .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="確認画面に進む"],
   .cart-section #contact #center1 .centerbox .mainCont input[type="button"][value="　この内容で注文する　"] {
     display: block;
     width: 100%;
     margin: 10px auto 0;
   }
   /*マイページ*/
   #center3 .bordlayoutp3 table tbody tr td input[type="button"][value="利用する"],
   #center3 .bordlayoutp3 table tbody tr td input[type="button"][value="利用しない"] {
     margin-left: 0;
   }
   /*お客様の声をお送りください*/
   .mainCont .comment_box form[name="form3"] table td.backcolor2 table {
     width: 100%;
     margin: 0 auto;
   }
   .mainCont .comment_box form[name="form3"] table td.backcolor2 table td:first-child {
     width: 90px;
   }
 }
 /*-----------------------------------------------------*/
 /*  for -480px                  */
 /*-----------------------------------------------------*/
 @include s.media-origin-sm {
   /*hover指定打消し*/
   a:hover {
     color: #333;
     text-decoration: none;
   }
   a:hover img, #hNav ul#header-nav li a:hover, .siteName a:hover, .sitecopy a:hover,
   #sub-column .group nav ul li a:hover, #sub-column .group nav ul li a:active, #sub-column .category nav ul li a:hover, #sub-column .category nav ul li a:active,
   #sub-column .link nav ul li a:hover, #sub-column .link nav ul li a:active, #sub-column .sitelink a:hover, #sub-column #snav_followUs nav ul li a:hover img,
   #itemDetail-cont .cartArea input:hover, #itemDetail-cont .cartArea .variation a:hover {
     opacity: 1;
     filter: alpha(opacity=100);
     -ms-filter: "alpha(opacity=100)";
     -khtml-opacity: 1;
     -moz-opacity: 1;
     cursor: auto;
   }
   #gnav div.cart-nav ul li a:hover, #gnav div.cart-nav ul li a:active,
   #gnav nav ul li a:hover, #gnav nav ul li a:active,
   article a:hover p, div.cart-article a:hover p,
   #sub-column div.snav_mailMag_cont input.submit_mailMag:hover, #Journal a .more:hover,
   input[type="submit"].btn-confirmPost:hover, input[type="button"].btn-confirmBack:hover,
   #contact input.btn-confirmNext:hover, #contact .btn-enq input.btn-confirmNext:hover,
   #contact .mailMF input.btn-confirmNext:hover, #contact .mailMC input.btn-confirmNext:hover,
   #sub-column .link.keyword nav ul li a:hover,
   #sub-column div.snav_mailMag_cont input.submit_mailMag:hover {
     background-color: #333;
   }
   .news-box dd a:hover {
     color: #666;
   }
   div#cart-footer #footer-inner .footer_gnavi ul li a:hover,
   footer #footer-inner .footer_gnavi ul li a:hover,
   #center-seo .centerbox a:hover, #center3 .centerbox a:hover {
     text-decoration: none;
   }
   .bundle_display a:hover {
     background: #FFF;
   }
   #contact input.btn-confirmBack:hover {
     background: url(../img/btn-back_bg.gif) no-repeat left top;
   }
   #contact .btn-confirmCancel:hover {
     background: url(../img/btn-confirm_bg.gif) repeat-x left top;
   }
   #contact .btn-confirmClose:hover {
     background: url(../img/btn-close_bg.gif) no-repeat left top;
   }
   #contact input.btn-confirm120:hover, #contact input.btn-confirm160:hover, #contact input.btn-confirmReg:hover {
     background: url(../img/btn-confirm_bg.gif) repeat-x left top;
   }
   #contact input.btn-confirmBackItem:hover {
     background: url(../img/btn-back_bg120.gif) no-repeat left top;
   }
   .wishAllDelBtn:hover {
     background-color: #333 !important;
     border: 1px solid #333 !important;
   }
   #contact #center1 .centerbox .title1 a:hover {
     font-weight: normal;
   }
   #contact .mainFrame .mainCont a:hover {
     color: #333;
     text-decoration: none;
   }
   div.enquete-section #contact .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] input[type=submit]:hover {
     background: url(../img/btn-confirm_bg.gif) repeat-x left top;
   }
   .cart-section #contact #center1 .centerbox .mainCont input[value="住所変更"]:hover,
   .cart-section #contact #center1 .centerbox .mainCont input[value="その他の情報変更"]:hover,
   .cart-section #contact #center1 .centerbox .mainCont input[value="詳細表示"]:hover {
     font-weight: normal;
   }
   .ui-dialog #amazon_dialog #button_area input.button1:hover,
   .mainCont .comment_box td.backcolor2 div.write_comment input[type="submit"]:hover,
   .mainCont .comment_box td.backcolor2 div.write_comment input[type="button"]:hover,
   div.mainCont input[type="button"]:hover, div.mainCont input[type="submit"]:hover,
   #bundle_detail > div:last-child input:hover,
   #variationPriceAnnounceMailDialog div.layoutp3 + div input:hover,
   #variationPriceAnnounceMailDialog div.layoutp3 > div:last-child input:hover,
   #center-seo input[type="submit"]:hover, #center-seo input[type="button"]:hover, #center3 input[type="submit"]:hover, #center3 input[type="button"]:hover,
   #contact #center1 .centerbox .mainCont input[type="button"]:hover,
   #contact #center1 .centerbox .mainCont input[type="submit"]:hover {
     background-color: #333;
     border: 1px solid #333;
   }
   #center3 input.sps-my-display[type="button"]:hover,
   #center3 input.sps-my-change-display[type="button"]:hover {
     background-color: #FFF;
     border: 1px solid #333;
     color: #333;
   }
   .bundle_display a:hover {
     background: #f0f8ff;
   }
   // #header-inner #logo {
   //   min-height: 40px;
   // }
   #hNav ul#keyword li a {
     padding: 3% 2%;
   }
   /*メニュー(480px)*/
   // #hNav nav, #hNav .cart-nav {
   //   min-height: 40px;
   // }
   // #hNav ul#header-nav li {
   //   font-size: 70%;
   //   letter-spacing: -1px;
   //   padding: 0;
   // }
   // #hNav ul#header-nav li#header-nav-mypage a::before {
   //   content: '';
   //   background: url(../img/rps_mypage_icon.png);
   //   background-size: cover;
   //   display: block;
   //   height: 24px;
   //   width: 24px;
   //   margin: 0 auto 3px;
   // }
   // #hNav ul#header-nav li#header-nav-cart {
   //   margin-right: 54px;
   // }
   // #hNav ul#header-nav li#header-nav-cart a::before {
   //   content: '';
   //   background: url(../img/rps_cart_icon.png);
   //   background-size: cover;
   //   display: block;
   //   height: 24px;
   //   width: 24px;
   //   margin: 0 auto 3px;
   // }
   // #hNav ul#header-nav li#header-nav-drawer {
   //   height: 38px;
   //   width: 38px;
   // }
   /*グローバルナビ(480px)*/
   // #gnav div.cart-nav ul li, #gnav nav ul li {
   //   font-size: 70%;
   // }
   // #gnav div.cart-nav ul li a, #gnav nav ul li a {
   //   padding: 8px 6px 5px;
   // }
   // #gnav div.cart-nav li a::before, #gnav nav li a::before {
   //   content: '';
   //   background-size: cover;
   //   display: block;
   //   height: 25px;
   //   width: 25px;
   //   margin: 0 auto 1px;
   // }
   /*店長日記(480px)*/
   #main-column .freeArea2.shopDiary p a,
   #main-column .half-cont_wrap01 .half-cont .freeArea2.shopDiary p a,
   #main-column .half-cont_wrap02 .half-cont .freeArea2.shopDiary p a {
     font-size: 90%;
     margin: 15px 0 0 48%;
     padding: 1%;
     text-align: center;
     width: 52%;
   }
   /*-- 商品一覧(480px) --*/
   .column4, .column5 {
     width: 47%;
     margin: 0 6% 10px 0;
     padding-bottom: 30px;
   }
   .column5:nth-child(5n) {
     margin: 0 2% 10px 0;
   }
   .column4:nth-child(2n), .column5:nth-child(2n) {
     clear: none;
     margin: 0 0 10px 0;
   }
   .column4:nth-child(2n+1), .column5:nth-child(2n+1) {
     clear: both;
   }
   /*説明付き(480px)*/
   #itemList2 .column4 .itemThumb-wrap, #itemList2 .column5 .itemThumb-wrap {
     float: none;
     max-width: initial;
     width: 100%;
   }
   #itemList2 .column4 .itemThumb-wrap-right, #itemList2 .column5 .itemThumb-wrap-right {
     margin-left: 0;
   }
   // #itemList2 .column4 .sps-wishAddItem img, #itemList2 .column5 .sps-wishAddItem img,
   // #itemList2 .column4 .sps-wishAddItemOk img, #itemList2 .column5 .sps-wishAddItemOk img {
   //   margin-left: 86%;
   //   width: 14%;
   // }
   article #main-column #itemList section.column4 h2, article #main-column #itemList section.column4 h2, article #main-column #itemList section.column4 h2 {
     font-size: 90%;
     line-height: 125%;
     font-weight: bold;
   }
   #itemList2 .column4 .itemThumb-wrap p.sps-itemList-stockDisp, #itemList2 .column5 .itemThumb-wrap p.sps-itemList-stockDisp {
     margin: 6px 0 20px;
   }
   #itemList2 .column4 .itemThumb-wrap p.sps-itemList-stockDisp a, #itemList2 .column5 .itemThumb-wrap p.sps-itemList-stockDisp a {
     font-size: 100%;
     margin: 0 0 0 54%;
     width: 46%;
   }
   /*写真のみ(480px)*/
   #itemList .column4 p.sps-itemList-stockDisp, #itemList .column5 p.sps-itemList-stockDisp {
     margin: 6px 0;
   }
   #itemList .column4 p.sps-itemList-stockDisp a, #itemList .column5 p.sps-itemList-stockDisp a {
     font-size: 100%;
     margin: 0;
     width: 100%;
   }
   /*-- 商品詳細ページ(480px) --*/
   #itemDetail-cont .itemThumb-wrap {
     float: none;
     width: 100%;
     margin: 0;
   }
   #itemDetail-cont .itemOutline {
     float: none;
     width: 100%;
     margin-top: 15px;
   }
   // section#itemDetail-wrap .cartArea table.variation td {
   //   font-size: 65%;
   //   padding: 3px;
   // }
   // table.variation span.selling_price, table.variation span.special_price {
   //   font-size: 110%;
   // }
   // section#itemDetail-wrap .cartArea table.variation th {
   //   min-width: 35px;
   // }
   #center-seo .mainCont, #center3 .mainCont font {
     font-size: 90%;
   }
   section#itemDetail-wrap .cartArea {
     overflow-x: scroll;
     -ms-overflow-style: none;
   }
   section#itemDetail-wrap .cartArea::-webkit-scrollbar {
     display: none;
   }
   @-moz-document url-prefix() {
     section#itemDetail-wrap .cartArea {
       overflow-x: auto;
     }
   }
   /*ダイアログ-商品を追加しました(480px)*/
   div.sps-cartaddBtnContent {
     width: auto;
   }
   /*関連商品(480px)*/
   section.recommend .column-set .quantityArea span:first-child {
     display: inline;
   }
   /*ポップアップ全般(480px)*/
   .ui-dialog {
     position: fixed !important;
     top: 20px !important;
   }
   .ui-dialog .ui-dialog-content, .ui-widget-content {
     max-height: 70vh !important;
   }
   /*入荷連絡/在庫一覧スクロール(480px)*/
   #variationPriceAnnounceMailDialog, #spsVarpriceStockDialog1 {
     height: 93% !important;
     max-height: 93% !important;
   }
   div[aria-describedby='variationPriceAnnounceMailDialog'], div[aria-describedby='spsVarpriceStockDialog1'] {
     position: fixed !important;
     top: 20px !important;
   }
   /*商品画像ポップアップ(480px)*/
   .ui-dialog div#dialog {
     height: 93% !important;
   }
   .fl_contents {
     width: auto !important;
   }
   .fl_main {
     display: table;
     width: 100% !important;
   }
   .fl_main_img {
     height: auto !important;
   }
   #thum_next, #thum_prev {
     padding-top: 0 !important;
     margin: 70px 0 !important;
     min-width: 15px;
   }
   .fl_t_item {
     box-sizing: border-box;
     width: 29% !important;
     margin: 2% !important;
     padding: 2% !important;
   }
   .fl_img_selected {
     border: 1px solid #C9E1F4 !important;
     margin: 2% !important;
     padding: 2% !important;
   }
   /*-- サイドカラム(480px) --*/
   // #sub-column {
   //   right: -80%;
   //   width: 80%;
   // }
   /*ドロワー内 メルマガ(480px)*/
   #sub-column div.snav_mailMag_cont p:last-child {
     margin: 15px 0 10px 16%;
     padding: 10px 1%;
   }
   /*-- お客様の声(480px) --*/
   article #main-column section.rating .tmb {
     float: none;
     margin: 0;
     text-align: center;
     width: 100%;
   }
   article #main-column section.rating .reviewTxt {
     float: none;
     margin: 5px 0 0 0;
     width: 100%;
   }
   article #main-column section.rating .ttlArea {
     display: block;
   }
   article #main-column section.rating h2 {
     display: block;
     width: 100%;
     padding: 0;
   }
   article #main-column section.rating .btnMore {
     text-align: right;
     width: 100%;
   }
   article #main-column #itemBox {
     position: relative;
     overflow: visible;
   }
   article #itemBox h2 {
     padding: 0 0 10px;
   }
   article div#main-column form[name="REVIEWLIST"] .btnMore {
     display: block;
     float: none;
     position: absolute;
     right: 0;
     top: -30px;
     padding: 0 0 10px;
   }
   article div#main-column div#itemBox p.review_txt {
     float: none;
   }
   article #main-column section.rating ul li:nth-child(6),
   article #main-column section.rating ul li:nth-child(7) {
     width: 100%;
   }
   /*マイページボタン(480px)*/
   #center3 input[type="button"], #center3 input[type="submit"] {
     min-width: 100px;
     width: auto;
   }
   #center3 input.sps-my-display[type="button"] {
     height: 40px;
   }
   /*-- カート/アンケート(480px) --*/
   #cart-header #hNav ul#header-nav li#header-nav-cart,
   #enquete-header #hNav ul#header-nav li#header-nav-cart {
     margin-left: 0;
   }
   #cart-header #hNav ul#header-nav li,
   #enquete-header #hNav ul#header-nav li {
     margin-right: 0;
   }
   #contact #center1 .centerbox .mainCont .step_navi01, #contact #center1 .centerbox .mainCont .step_navi02, #contact #center1 .centerbox .mainCont .step_navi03,
   #contact #center1 .centerbox .mainCont .step_navi04 {
     height: 100px;
     width: 100%;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td {
     padding: 1rem 3px;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input {
     margin-right: 2px;
     max-width: 40px;
     min-width: 35px;
   }
   #contact #center1 .centerbox .mainCont input[type="button"] {
     margin: 5px 0 5px 5px;
   }
   #contact #center1 .centerbox .mainCont input[type="button"]:first-child {
     margin: 0 0 5px 5px;
   }
   #contact #center1 .centerbox .mainCont input[type="button"].regi_next {
     margin: 5px 0 5px 5px;
   }
   .SPS-fontlarge {
     display: block;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td.backcolor2 input[name="COUPONCODE"] {
     margin-left: 0;
     width: 70% !important;
   }
   #contact #center1 .centerbox .mainFrame .mainCont form[name="COUPON"] .bordlayoutp3 td.backcolor2 input[type="button"][value="適用"] {
     margin: 0;
     min-width: auto;
     padding: 0 1em;
     width: 25%;
   }
   #cart-header #hNav ul#header-nav li#header-nav-cart {
     margin-left: 0;
   }
   #EST_SPS_order #contact #center1 .centerbox .mainCont input[type="button"] {
     margin: 5px 0;
   }
   .cart-section #contact #center1 .centerbox .mainCont input[value="住所変更"],
   .cart-section #contact #center1 .centerbox .mainCont input[value="その他の情報変更"] {
     margin: 5px 0 10px;
     padding: 0 1.5em;
   }
   .cart-section #contact #center1 .centerbox .mainCont input[value="詳細表示"] {
     margin: 5px 5px 10px 0;
     padding: 0 1.5em;
   }
   .cart-section #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.sps-inputTable input[id="spsLname"],
   .cart-section #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.sps-inputTable input[id="spsLnameKana"] {
     margin: 0 0 15px;
   }
   .cart-section #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input[type="submit"][value="削除"] {
     font-size: .7rem;
     padding: 0 2%;
     margin: 0;
     min-width: auto;
     width: 100%;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 table.cartItem tbody tr td input[value="変更"] {
     display: block;
     font-size: .7rem;
     margin: 8px auto 0;
   }
   /*カート内関連商品(480px)*/
   #contents .cart-section .column5 {
     width: 49% !important;
     margin: 0 2% 20px 0 !important;
     padding-bottom: 20px !important;
   }
   #contents .cart-section .column5:nth-child(5n) {
     margin: 0 2% 20px 0 !important;
   }
   #contents .cart-section .column5:nth-child(2n) {
     margin: 0 0 20px 0 !important;
   }
   /*お届けスケジュール(480px)*/
   #deli_scadule .deli_scadule_box div.backcolor3.deli_plan table.layoutp5.deli_plan_day tbody tr td.backcolor1 {
     width: 40% !important;
   }
   /*アンケート(480px)*/
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name=ENQ] input[type="text"] {
     margin-left: 0;
     min-width: 56px;
   }
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr td,
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr td:first-child {
     display: block;
     padding-bottom: 10px;
     width: 100%;
   }
   div.enquete-section #contact #center1 .centerbox .mainCont .mainFrame .mainCont form[name="ENQ"] table tbody tr:last-child td:last-child {
     padding-bottom: 0;
   }
   /*新規会員登録(480px)*/
   div[aria-describedby='amazon_dialog'] .ui-dialog-content {
     padding: .5em 0 !important;
   }
   /*-- お客様の声をお送りください(480px) --*/
   .mainCont .comment_box form[name="form3"] table table td {
     box-sizing: border-box;
     display: block;
     width: 100% !important;
   }
   /*会員情報の変更/お届け先情報(480px)*/
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="WRAP_N"],
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 td select[name="WRAP_W"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="WRAP_N"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="WRAP_W"] {
     max-width: inherit;
     min-width: auto;
   }
   #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="RECV_DATE_LIST"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td select[name="RECV_TIME"],
   #center3 .centerbox .bordlayoutp3 table tbody tr td input[name="RECV_NGDAY"] {
     display: block;
     width: 100% !important;
   }
   #center3 .layoutp3 input[type="button"][value="キャンセル"] {
     min-width: 100px;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.border_none td {
     display: block;
     width: 100%;
   }
   #EST_SPS_regi #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.border_none td {
     padding: 0 !important;
   }
   #EST_SPS_regi #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.border_none td input[type="button"][value="編集"] {
     height: 30px;
   }
   #contact #center1 .centerbox .mainFrame .mainCont .bordlayoutp3 tr.border_none td.send_info.border.center {
     width: 30% !important;
   }
   /*-- フリーページ(480px) --*/
   #Journal textarea {
     box-sizing: border-box;
     width: 100%;
   }
 }
 /*Ｅストアーモバイル*/
 #sps-menu img, #global-navi img {
   width: 100%;
 }
 body {
   font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", メイリオ, Meiryo, sans-serif;
 }
 a:hover,
 a:active {
   color: #666;
 }
 /*ヘッダー------------------------------*/
 .shop_header_block {
   position: relative;
 }
 .shop_header_block .wrap {
   margin: 10px auto;
   width: 1200px;
   max-width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: space-between;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   -ms-align-items: center;
   -webkit-align-items: center;
   align-items: center;
 }
 @include s.media-sp {
   .shop_header_block .wrap {
     width: 100%;
     height: 60px;
     margin: 0 auto;
   }
 }
 .shop_header_block .logo a {
   display: block;
   color: #111;
   text-decoration: none;
 }
 @include s.media-sp {
   .shop_header_block .logo a {
     padding: 10px;
   }
 }
 .shop_header_block .logo img {
   width: auto;
   height: 36px;
   display: inline-block;
   vertical-align: bottom;
   opacity: 1 !important;
 }
 @include s.media-sp {
   .shop_header_block .logo img {
     width: auto;
     height: 28px;
   }
 }
 .shop_header_block .logo span {
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-size: 15px;
   line-height: 1;
   display: inline-block;
   margin-bottom: 5px;
   vertical-align: bottom;
   margin-left: 5px;
 }
 @include s.media-sp {
   .shop_header_block .logo span {
     font-size: 13px;
   }
 }
 .shop_header_block .gnav {
   margin-left: auto;
 }
 @include s.media-sp {
   .shop_header_block .gnav {
     display: none;
   }
 }
 .shop_header_block .gnav > ul {
   height: 100%;
   margin: 0 auto;
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: center;
   -webkit-justify-content: center;
   justify-content: center;
   -ms-align-items: center;
   -webkit-align-items: center;
   align-items: center;
 }
 .shop_header_block .gnav > ul > li {
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: space-between;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   -ms-align-items: center;
   -webkit-align-items: center;
   align-items: center;
   height: 60px;
   position: relative;
   width: 122px;
   text-align: center;
   display: block;
   line-height: 60px;
 }
 .shop_header_block .gnav > ul > li:last-child {
   width: 60px;
 }
 .shop_header_block .gnav > ul > li::before {
   content: "";
   display: block;
   position: absolute;
   left: 0;
   top: 20%;
   width: 1px;
   height: 60%;
   background: #EEE;
 }
 .shop_header_block .gnav > ul > li > a {
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-size: 13px;
   letter-spacing: 1px;
   font-weight: bold;
   display: block;
 }
 .shop_header_block .gnav > ul > li:hover .menu {
   max-height: 9999px !important;
   opacity: 1 !important;
 }
 .shop_header_block .gnav .menu {
   display: block;
   max-height: 0;
   opacity: 0;
   overflow: hidden;
   width: 100%;
   left: 0;
   top: 60px;
   text-align: center;
   position: absolute;
   background: rgba(0, 0, 0, 0.6);
   z-index: 100;
   transition: opacity .5s, height .5s;
 }
 .shop_header_block .gnav .menu ul {
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: space-between;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   -ms-align-items: flex-start;
   -webkit-align-items: flex-start;
   align-items: flex-start;
   width: 100%;
   margin: 0 auto;
 }
 .shop_header_block .gnav .menu ul li {
   width: 100%;
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-size: 13px;
   line-height: 1.8;
   font-weight: normal;
   border-bottom: solid 1px #333;
 }
 .shop_header_block .gnav .menu ul li a {
   color: #FFF;
   display: block;
   padding: 10px 10px;
   letter-spacing: 1px;
 }
 .shop_header_block .gnav .menu ul li a:hover {
   color: #FFF;
 }
 .shop_header_block .search_btn {
   position: relative;
 }
 .shop_header_block .search_btn a {
   display: block;
   padding: 50px 10px 10px 50px;
   min-height: 22px;
   cursor: pointer;
 }
 .shop_header_block #header-nav-drawer {
   display: block;
 }
 @include s.media-sp {
   .shop_header_block #header-nav-drawer {
     background: transparent;
     /*border: 1px solid #888;*/
     border-radius: 0px;
     box-sizing: border-box;
     cursor: pointer;
     display: inline-block;
     height: 46px;
     margin: 0;
     padding: 0;
     position: fixed;
     right: 2%;
     top: 5px;
     white-space: nowrap;
     width: 46px;
     z-index: 150;
   }
   .shop_header_block #header-nav-drawer span {
     display: block;
     position: absolute;
     top: 50%;
     left: 50%;
     width: 24px;
     height: 2px;
     margin: -3px 0 0 -12px;
     background: #111;
     transition: .2s;
   }
   .shop_header_block #header-nav-drawer span::before,
   .shop_header_block #header-nav-drawer span::after {
     display: block;
     content: "";
     position: absolute;
     top: 50%;
     left: 0;
     width: 24px;
     height: 2px;
     background: #111;
     transition: .3s;
   }
   .shop_header_block #header-nav-drawer span::before {
     margin-top: -10px;
   }
   .shop_header_block #header-nav-drawer span::after {
     margin-top: 7px;
   }
   .shop_header_block #header-nav-drawer.spsDrawerOpen span {
     background: transparent;
   }
   .shop_header_block #header-nav-drawer.spsDrawerOpen span::before,
   .shop_header_block #header-nav-drawer.spsDrawerOpen span::after {
     margin-top: 0;
   }
   .shop_header_block #header-nav-drawer.spsDrawerOpen span::before {
     -webkit-transform: rotate(-45deg);
     transform: rotate(-45deg);
   }
   .shop_header_block #header-nav-drawer.spsDrawerOpen span::after {
     transform: rotate(-135deg);
     -webkit-transform: rotate(-135deg);
   }
 }
 /*フッターー------------------------------*/
 .shop_footer_block {
   background: #EFEFEF;
   width: 100%;
   height: auto;
   padding: 40px 0px;
   margin: 0;
   text-align: center;
 }
 .shop_footer_block div p {
     padding-top: 5px;
     font-size: 12px;
 }
 .shop_footer_block p img {
     width: 230px;
     height: 30px;
     margin-bottom: 15px;
 }
 @include s.media-sp {
   .shop_footer_block{
     padding: 20px 40px;
   }
   .shop_footer_block div p {
     padding-top: 0;
   }
   .shop_footer_block div p span {
     white-space: nowrap;
   }
   .shop_footer_block p img {
       margin-bottom: 10px;
   }
 }
 @include s.media-sp {
   .shop_footer_block .wrap {
     padding: 0px;
     border-top: solid 1px #CCC;
   }
 }
 .shop_footer_bottom_block {
   background: #111111;
   color: #FFF;
   width: 100%;
   height: auto;
   padding: 0;
   margin: 0;
   font-size: 12px;
 }
 .shop_footer_bottom_block a {
   color: #FFF;
   text-decoration: underline;
 }
 .shop_footer_bottom_block .wrap {
   width: 1200px;
   max-width: 100%;
   margin: 0 auto;
   padding: 20px 0;
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: space-between;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   -ms-align-items: flex-start;
   -webkit-align-items: flex-start;
   align-items: flex-start;
 }
 @include s.media-sp {
   .shop_footer_bottom_block .wrap {
     padding: 15px;
   }
 }
 .shop_footer_bottom_block .footer_nav {
   text-align: center;
   width: 100%;
 }
 @include s.media-sp {
   .shop_footer_bottom_block .footer_nav {
     width: 100%;
     text-align: center;
   }
 }
 .shop_footer_bottom_block .footer_nav ul {
   font-size: 0;
   margin: 8px 0px;
 }
 @include s.media-sp {
   .shop_footer_bottom_block .footer_nav ul {
     display: none;
   }
 }
 .shop_footer_bottom_block .footer_nav ul li {
   font-size: 12px;
   display: inline-block;
   border-right: solid 1px #FFF;
   line-height: 100%;
 }
 .shop_footer_bottom_block .footer_nav ul li:last-child {
   border-right: none;
 }
 .shop_footer_bottom_block .footer_nav ul li a {
   display: block;
   padding: 0 5px;
   text-decoration: none;
 }
 /*サイドナビ・バナー------------------------------*/
 .side_banner_block {
   margin-bottom: 50px;
 }
 .side_banner_block ul li {
   display: block;
   margin-bottom: 15px;
   text-align: center;
 }
 .side_banner_block ul li:last-of-type {
   margin-bottom: 0;
 }
 /*サイドナビ・SPボタン------------------------------*/
 .side_btn_block {
   display: none;
 }
 @include s.media-sp {
   .side_banner_block {
     margin-bottom: 40px;
   }
   .side_btn_block {
     display: block;
     margin-bottom: 20px;
   }
   .side_btn_block ul {
     display: -webkit-box;
     display: -ms-flexbox;
     display: -webkit-flex;
     display: flex;
     -ms-flex-flow: row wrap;
     -webkit-flex-flow: row wrap;
     flex-flow: row wrap;
     -ms-justify-content: space-between;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -ms-align-items: stretch;
     -webkit-align-items: stretch;
     align-items: stretch;
     margin: 0 -15px 0;
   }
   .side_btn_block ul li {
     width: 50%;
     border-right: solid 1px #CCC;
   }
   .side_btn_block ul li:last-of-type {
     flex: 1 1 0%;
     border-right: none;
   }
   .side_btn_block ul li a {
     display: -webkit-box;
     display: -ms-flexbox;
     display: -webkit-flex;
     display: flex;
     -ms-flex-flow: row wrap;
     -webkit-flex-flow: row wrap;
     flex-flow: row wrap;
     -ms-justify-content: center;
     -webkit-justify-content: center;
     justify-content: center;
     -ms-align-items: center;
     -webkit-align-items: center;
     align-items: center;
     background: #000;
     color: #FFF;
     // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
     font-size: 12px;
     padding: 10px 0px;
     height: 100%;
   }
 }
 /*サイドナビ・検索ボックス------------------------------*/
 .side_search_block {
   margin-bottom: 30px;
 }
 .side_search_block .keyword {
   width: 100%;
   border: none !important;
   border-bottom: solid 1px #111 !important;
   padding: 3px 10px !important;
   height: 35px !important;
   padding-right: 30px;
 }
 .side_search_block form {
   position: relative;
   padding: 0;
 }
 .side_search_block button {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   position: absolute;
   right: 0px;
   top: 0px;
   height: 35px !important;
   width: 35px !important;
   border: 0;
   display: block;
   outline: none;
 }
 /*カレンダー修正------------------------------*/
 div.cal_wrapper table.cal tr th input.prev {
   background-image: url("../img/calendar_pleft.gif");
 }
 div.cal_wrapper table.cal tr th input.next {
   background-image: url("../img/calendar_pright.gif");
 }
 div#subc_calendar h2::before {
   content: "Calendar";
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-size: 100%;
   text-align: left;
 }
 div#subc_calendar h2::after {
   content: "";
   display: none;
 }
 div.cal_wrapper table.cal tr th p.month {
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
 }
 /*アロー修正------------------------------*/
 #sub-column .group nav ul li a::after,
 #sub-column .category nav ul li a::after,
 #sub-column .link nav ul li a::after {
   background-image: url("../img/rsp_arrow01.png");
 }
 #sub-column .category h2 a::after {
   background-image: url("../img/rsp_arrow01.png");
 }
 /*ページアップ、toカートの画像変更------------------------------*/
 #page-top a,
 #to-cart a {
   position: relative;
   background: #6aa1cc;
 }
 // #to-cart a::before {
 //   content: "";
 //   width: 100%;
 //   height: 100%;
 //   display: block;
 // }
 // #page-top a::before {
 //   content: "";
 //   width: 100%;
 //   height: 100%;
 //   display: block;
 // }
 #to-cart img,
 #page-top img {
   // display: none;
   opacity: 1;
 }
 #page-top a:hover,
 #to-cart a:hover {
   background: #6aa1cc;
 }
 #page-top a:hover img,
 #to-cart a:hover img {
   opacity: .8;
 }
 /*サーチボタン*/
 #header_search_form {
   display: none;
   width: 300px;
   position: absolute;
   right: 10px;
   top: 60px;
   border: solid 1px #CCC;
   background: #FFF;
   z-index: 1000;
   line-height: 100%;
   text-align: left;
 }
 #header_search_form input[type="text"] {
   width: 90% !important;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   border: none !important;
   height: 35px !important;
   padding: 5px !important;
 }
 #header_search_form button {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   position: absolute;
   right: 0px;
   top: 0px;
   height: 35px !important;
   width: 55px !important;
   border: 0;
   text-align: center;
   display: block;
   outline: none;
   color: #FFF;
 }
 /*トップスライダー------------------------------*/
 .top_slider_block {
   width: 100%;
   max-width: 100%;
   margin: 0 auto 40px;
   display: none;
 }
 @include s.media-sp {
   .top_slider_block {
     margin: 0 -2% 20px;
     width: 104%;
     max-width: 104%;
     padding: 0px 2%;
   }
 }
 /*トップバナーー------------------------------*/
 .top_banner_block {
   background: #EFEFEF;
   display: none;
   margin-bottom: 20px;
 }
 @include s.media-sp {
   .top_banner_block {
     margin: 0 -2% 20px;
     width: 104%;
     max-width: 104%;
   }
 }
 .top_banner_block .wrap {
   // width: 1160px;
   // max-width: 100%;
   width: 100%;
   max-width: 120rem;
   margin: 0 auto;
   padding: 80px 0;
 }
 @include s.media-sp {
   .top_banner_block .wrap {
     padding: 40px;
   }
 }
 .top_banner_block ul {
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: space-between;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   -ms-align-items: flex-start;
   -webkit-align-items: flex-start;
   align-items: flex-start;
   margin-bottom: -40px;
 }
 @include s.media-sp {
   .top_banner_block ul {
     margin-bottom: -20px;
   }
 }
 .top_banner_block ul li {
   width: 47%;
   margin-bottom: 40px;
 }

 @include s.media-sp {
   .top_banner_block ul li {
     width: 100%;
     text-align: center;
     margin-bottom: 20px;
   }
 }
 /*新着・おすすめ、ランキング------------------------------*/
 .newItem,
 .pickup,
 .ranking {
   margin: 60px auto !important;
 }
 @include s.media-sp {
   .newItem,
   .pickup,
   .ranking {
     width: 100% !important;
     padding: 0 !important;
     margin: 20px auto !important;
   }
 }
 .newItem h2,
 .pickup h2,
 .ranking h2 {
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
 }
 .newItem .badge,
 .pickup .badge {
   display: none;
 }
 /*新着情報------------------------------*/
 .news {
   margin: 60px auto !important;
   position: relative;
   padding: 0 320px 0 0 !important;
   min-height: 300px;
 }
 @include s.media-sp {
   .news {
     width: 100% !important;
     margin: 20px auto !important;
     min-height: auto;
     padding: 0 !important;
   }
 }
 .news h2 {
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
   letter-spacing: 2px;
 }
 .news #subc_calendar {
   width: 280px;
   position: absolute;
   right: 0;
   top: 0;
 }
 @include s.media-sp {
   .news #subc_calendar {
     display: none;
   }
 }
 .news #subc_calendar h2 {
   padding: 0px 0 30px;
   letter-spacing: 1px;
   text-align: left;
 }
 dl.news-box {
   padding: 0;
   border: none;
 }
 dl.news-box > dd {
   padding: 0;
   border: none;
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-size: 110%;
 }
 dl.news-box > dd dl {
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: space-between;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   -ms-align-items: flex-start;
   -webkit-align-items: flex-start;
   align-items: flex-start;
   border-bottom: 1px solid #DDD;
   width: 100%;
   padding: 20px 15px;
 }
 @include s.media-sp {
   dl.news-box > dd dl {
     padding: 10px 5px;
     font-size: 100%;
   }
 }
 dl.news-box > dd dl dt {
   width: 15%;
   text-align: left;
   font-weight: bold;
 }
 @include s.media-sp {
   dl.news-box > dd dl dt {
     width: 100%;
     padding-bottom: 5px;
   }
 }
 dl.news-box > dd dl dd {
   width: 85%;
   text-align: left;
 }
 @include s.media-sp {
   dl.news-box > dd dl dd {
     width: 100%;
   }
 }
 dl.news-box > dd dl + br {
   display: none;
 }
 dl.news-box > dd a {
   text-decoration: underline;
 }
 /*商品ページ---------------------------------------------*/
 #itempage_contents,
 #itempage_contents * {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   list-style-type: none;
 }
 #itempage_contents {
   font-size: 16px;
   margin-top: 80px;
 }
 @include s.media-origin-x-sm {
   #itempage_contents {
     font-size: 14px;
     margin-top: 20px;
   }
 }
 #itempage_contents p {
   font-size: 100%;
   line-height: 1.8;
 }
 #itempage_contents p + p {
   margin-top: 1em;
 }
 #itempage_contents .main_catch {
   font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", メイリオ, Meiryo, sans-serif;
   letter-spacing: 1px;
   font-size: 130%;
   line-height: 1.3;
   margin: 0 0 30px;
   padding: 0;
   border: none;
   background: none;
   color: #333;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .main_catch {
     margin-bottom: 20px;
   }
 }
 #itempage_contents .desc {
   margin-bottom: 50px;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .desc {
     margin-bottom: 20px;
   }
 }
 #itempage_contents .icon {
   margin-bottom: 15px;
 }
 #itempage_contents .icon ul {
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
 }
 #itempage_contents .icon li {
   margin-right: 10px;
   margin-bottom: 5px;
   border: solid 1px #111;
   color: #111;
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-size: 120%;
   line-height: 1.1;
   padding: 5px 10px;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .icon li {
     padding: 3px 5px;
     margin-right: 5px;
   }
 }
 #itempage_contents .icon li.icon_blue {
   color: #1a5aa9;
   border-color: #1a5aa9;
 }
 #itempage_contents .icon li.icon_green {
   color: #1aa967;
   border-color: #1aa967;
 }
 #itempage_contents .icon li.icon_orange {
   color: #ff7f50;
   border-color: #ff7f50;
 }
 #itempage_contents .icon li.icon_black {
   color: #111111;
   border-color: #111111;
 }
 #itempage_contents .icon li.icon_not20age {
   color: #1a5aa9;
   border-color: #1a5aa9;
 }
 #itempage_contents .sub_ttl {
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-size: 124%;
   line-height: 1.4;
   letter-spacing: 1px;
   background: none;
   border: none;
   margin: 0 0 10px;
   text-align: left;
 }
 #itempage_contents .tbl {
   margin: 20px auto;
 }
 #itempage_contents .tbl table {
   border-collapse: collapse;
   table-layout: fixed;
   border: solid 1px #CBCBCB;
   width: 100%;
 }
 #itempage_contents .tbl th, #itempage_contents .tbl td {
   padding: 10px;
   border: solid 1px #CBCBCB;
   font-size: 15px;
   line-height: 1.6;
   vertical-align: middle;
 }
 #itempage_contents .tbl th {
   width: 25%;
   background: #F4F6F9;
   text-align: left;
   font-weight: normal;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .tbl tr, #itempage_contents .tbl td, #itempage_contents .tbl th, #itempage_contents .tbl tbody {
     width: 100%;
     display: block;
     border: none;
     font-size: 13px;
   }
   #itempage_contents .tbl th {
     border-bottom: solid 1px #CBCBCB;
     text-align: left;
     padding: 5px;
   }
   #itempage_contents .tbl td {
     border-bottom: solid 1px #CBCBCB;
     padding: 5px;
   }
   #itempage_contents .tbl table {
     border: solid 1px #CBCBCB;
     border-bottom: none;
   }
 }
 #itempage_contents .howto_wrap {
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: space-between;
   -webkit-justify-content: space-between;
   justify-content: space-between;
   -ms-align-items: flex-start;
   -webkit-align-items: flex-start;
   align-items: flex-start;
   margin: 80px auto;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .howto_wrap {
     margin: 40px auto;
   }
 }
 #itempage_contents .howto_wrap .howto {
   flex: 1 0 0%;
   margin-right: 100px;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .howto_wrap .howto {
     width: 100%;
     margin: 0;
     margin-bottom: 30px;
   }
 }
 #itempage_contents .howto_wrap .howto .list-howto-img {
   margin-top: 80px;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .howto_wrap .howto .list-howto-img {
     margin-top: 20px;
   }
 }
 #itempage_contents .howto_wrap .howto .list-howto {
   display: table;
   width: 100%;
   position: relative;
   margin-top: 80px;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .howto_wrap .howto .list-howto {
     margin-top: 20px;
   }
 }
 #itempage_contents .howto_wrap .howto .list-howto::before {
   content: "";
   display: block;
   position: absolute;
   bottom: 10px;
   width: 100%;
   border-bottom: 2px solid #d9d9d9;
   z-index: 1;
 }
 #itempage_contents .howto_wrap .howto .list-howto li {
   display: table-cell;
   position: relative;
   z-index: 2;
 }
 #itempage_contents .howto_wrap .howto .list-howto li .rank {
   width: 30px;
   margin: 0 auto;
 }
 #itempage_contents .howto_wrap .howto .list-howto li .index {
   width: 30px;
   margin: 0 auto;
   margin-bottom: 18.518518519%;
 }
 #itempage_contents .howto_wrap .taste {
   width: 300px;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .howto_wrap .taste {
     width: 100%;
     text-align: center;
   }
   #itempage_contents .howto_wrap .taste img {
     display: inline-block;
     width: 80%;
     margin: 0 auto;
   }
 }
 #itempage_contents .youtube {
   width: 100%;
   margin: 60px auto;
   text-align: center;
 }
 @include s.media-origin-x-sm {
   #itempage_contents .youtube {
     position: relative;
     width: 100%;
     margin: 40px auto;
     padding-top: 56.25%;
   }
   #itempage_contents .youtube iframe {
     position: absolute;
     top: 0;
     right: 0;
     width: 100% !important;
     height: 100% !important;
   }
 }
 /*リコメンドタイトル---------------------------------------------*/
 .recommend h2 {
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
   font-size: 20px !important;
   line-height: 1.4 !important;
   letter-spacing: 1px !important;
   background: none !important;
   border: none !important;
   margin: 40px 0 10px !important;
   text-align: left !important;
 }
 /*フリーページ---------------------------------------------*/
 #Journal h2 {
   // font-size: 140%;
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
 }
 #Journal h1 {
   display: none;
 }
 #JournalList .journal-cont ul, #Journal .journal-cont ul {
   list-style-type: none;
   margin: 0;
   padding: 0;
 }
 #JournalList .journal-cont ul li, #Journal .journal-cont ul li {
   list-style-type: none;
   margin: 0;
   padding: 0;
 }
 .state_r {
   display: none;
 }
 /*FAQページ---------------------------------------------*/
 #faq_contents,
 #faq_contents * {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   list-style-type: none;
 }
 #faq_contents {
   font-size: 16px;
 }
 @include s.media-origin-x-sm {
   #faq_contents {
     font-size: 14px;
   }
 }
 #faq_contents h3 {
   background: #111;
   color: #FFF;
   text-align: center;
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-weight: normal;
   letter-spacing: 1px;
   padding: 10px 0;
   margin-bottom: 20px;
 }
 @include s.media-sp {
   #faq_contents h3 {
     margin-bottom: 10px;
   }
 }
 #faq_contents dl dt {
   position: relative;
   border-bottom: solid 1px #CCC;
   cursor: pointer;
   padding: 15px 40px 15px 15px;
   font-size: 90%;
 }
 @include s.media-sp {
   #faq_contents dl dt {
     padding: 10px 30px 10px 5px;
   }
 }
 #faq_contents dl dt::before {
   content: 'Q.';
   font-weight: bold;
   display: inline-block;
   margin-right: 5px;
 }
 #faq_contents dl dt::after {
   display: block;
   content: "";
   position: absolute;
   top: 50%;
   right: 15px;
   width: 8px;
   height: 8px;
   margin: -8px 0 0 0;
   border-top: solid 2px #111;
   border-right: solid 2px #111;
   -webkit-transform: rotate(135deg);
   transform: rotate(135deg);
 }
 #faq_contents dl dt.active::after {
   margin: -4px 0 0 0;
   border-top: solid 2px #111;
   border-right: solid 2px #111;
   -webkit-transform: rotate(315deg);
   transform: rotate(315deg);
 }
 #faq_contents dl dd {
   display: none;
   padding: 15px 15px 30px;
   font-size: 90%;
   line-height: 1.6;
   border-bottom: solid 1px #CCC;
 }
 #faq_contents dl dd b {
   font-weight: bold;
 }
 #faq_contents dl dd a {
   text-decoration: underline;
 }
 #faq_contents .tabswitch .tabnav {
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -ms-flex-flow: row wrap;
   -webkit-flex-flow: row wrap;
   flex-flow: row wrap;
   -ms-justify-content: center;
   -webkit-justify-content: center;
   justify-content: center;
 }
 @include s.media-sp {
   #faq_contents .tabswitch .tabnav {
     -ms-justify-content: space-between;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     -ms-align-items: stretch;
     -webkit-align-items: stretch;
     align-items: stretch;
   }
   #faq_contents .tabswitch .tabnav::after {
     content: "";
     width: 32%;
     order: 100;
   }
 }
 #faq_contents .tabswitch .tabnav li {
   border-right: solid 1px #999;
   margin-bottom: 5px;
 }
 @include s.media-sp {
   #faq_contents .tabswitch .tabnav li {
     width: 32%;
     border: none;
   }
 }
 #faq_contents .tabswitch .tabnav li:last-of-type {
   border-right: none;
 }
 #faq_contents .tabswitch .tabnav a {
   display: block;
   color: #666;
   padding: 0px 15px;
   // font-family: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
   font-weight: normal;
   letter-spacing: 1px;
   font-size: 16px;
   text-align: center;
   position: relative;
   cursor: pointer;
 }
 @include s.media-sp {
   #faq_contents .tabswitch .tabnav a {
     display: -webkit-box;
     display: -ms-flexbox;
     display: -webkit-flex;
     display: flex;
     -ms-flex-flow: row wrap;
     -webkit-flex-flow: row wrap;
     flex-flow: row wrap;
     -ms-justify-content: center;
     -webkit-justify-content: center;
     justify-content: center;
     -ms-align-items: center;
     -webkit-align-items: center;
     align-items: center;
     padding: 10px 5px;
     font-size: 11px;
     line-height: 1.2;
     width: 100%;
     height: 100%;
     letter-spacing: 0;
     border: solid 1px #999;
     background: #EEE;
   }
 }
 #faq_contents .tabswitch .tabnav a.active {
   color: #111;
   font-weight: bold;
 }
 @include s.media-sp {
   #faq_contents .tabswitch .tabnav a.active {
     background: #FFF;
   }
 }
 #faq_contents .tabswitch .tab_content {
   margin-top: 30px;
 }
 @include s.media-sp {
   #faq_contents .tabswitch .tab_content {
     margin-top: 0;
   }
 }
 /*標識ページ--------------------------------------------*/
 #hyousiki_contents,
 #hyousiki_contents * {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   list-style-type: none;
 }
 #hyousiki_contents {
   font-size: 16px;
 }
 @include s.media-origin-x-sm {
   #hyousiki_contents {
     font-size: 14px;
   }
 }
 #hyousiki_contents .tbl {
   margin: 20px auto;
 }
 #hyousiki_contents .tbl table {
   border-collapse: collapse;
   table-layout: fixed;
   border: solid 1px #CBCBCB;
   width: 100%;
 }
 #hyousiki_contents .tbl th, #hyousiki_contents .tbl td {
   padding: 10px;
   border: solid 1px #CBCBCB;
   font-size: 15px;
   line-height: 1.6;
   vertical-align: middle;
 }
 #hyousiki_contents .tbl th {
   width: 25%;
   background: #F4F6F9;
   text-align: left;
   font-weight: normal;
 }
 @include s.media-origin-x-sm {
   #hyousiki_contents .tbl tr, #hyousiki_contents .tbl td, #hyousiki_contents .tbl th, #hyousiki_contents .tbl tbody {
     width: 100%;
     display: block;
     border: none;
     font-size: 13px;
   }
   #hyousiki_contents .tbl th {
     border-bottom: solid 1px #CBCBCB;
     text-align: left;
     padding: 5px;
   }
   #hyousiki_contents .tbl td {
     border-bottom: solid 1px #CBCBCB;
     padding: 5px;
   }
   #hyousiki_contents .tbl table {
     border: solid 1px #CBCBCB;
     border-bottom: none;
   }
 }
 /*small--------------------------------*/
 .small {
   font-size: 88%;
 }
 /*align---------------------------------*/
 .alL {
   text-align: left !important;
 }
 .alR {
   text-align: right !important;
 }
 .alC {
   text-align: center !important;
 }
 /*margin---------------------------------*/
 .mb0 {
   margin-bottom: 0 !important;
 }
 @include s.media-sp {
   .mb0 {
     margin-bottom: 0 !important;
   }
 }
 .mb10 {
   margin-bottom: 10px !important;
 }
 @include s.media-sp {
   .mb10 {
     margin-bottom: 5px !important;
   }
 }
 .mb20 {
   margin-bottom: 20px !important;
 }
 @include s.media-sp {
   .mb20 {
     margin-bottom: 10px !important;
   }
 }
 .mb30 {
   margin-bottom: 30px !important;
 }
 @include s.media-sp {
   .mb30 {
     margin-bottom: 15px !important;
   }
 }
 .mb40 {
   margin-bottom: 40px !important;
 }
 @include s.media-sp {
   .mb40 {
     margin-bottom: 20px !important;
   }
 }
 .mb40 {
   margin-bottom: 50px !important;
 }
 @include s.media-sp {
   .mb40 {
     margin-bottom: 25px !important;
   }
 }
 .mb60 {
   margin-bottom: 60px !important;
 }
 @include s.media-sp {
   .mb60 {
     margin-bottom: 30px !important;
   }
 }
 .mb80 {
   margin-bottom: 80px !important;
 }
 @include s.media-sp {
   .mb80 {
     margin-bottom: 40px !important;
   }
 }
 .mb100 {
   margin-bottom: 100px !important;
 }
 @include s.media-sp {
   .mb100 {
     margin-bottom: 50px !important;
   }
 }
 .mt0 {
   margin-top: 0 !important;
 }
 @include s.media-sp {
   .mt0 {
     margin-top: 0 !important;
   }
 }
 .mt10 {
   margin-top: 10px !important;
 }
 @include s.media-sp {
   .mt10 {
     margin-top: 5px !important;
   }
 }
 .mt20 {
   margin-top: 20px !important;
 }
 @include s.media-sp {
   .mt20 {
     margin-top: 10px !important;
   }
 }
 .mt30 {
   margin-top: 30px !important;
 }
 @include s.media-sp {
   .mt30 {
     margin-top: 15px !important;
   }
 }
 .mt40 {
   margin-top: 40px !important;
 }
 @include s.media-sp {
   .mt40 {
     margin-top: 20px !important;
   }
 }
 .mt40 {
   margin-top: 50px !important;
 }
 @include s.media-sp {
   .mt40 {
     margin-top: 25px !important;
   }
 }
 .mt60 {
   margin-top: 60px !important;
 }
 @include s.media-sp {
   .mt60 {
     margin-top: 30px !important;
   }
 }
 .mt80 {
   margin-top: 80px !important;
 }
 @include s.media-sp {
   .mt80 {
     margin-top: 40px !important;
   }
 }
 .mt100 {
   margin-top: 100px !important;
 }
 @include s.media-sp {
   .mt100 {
     margin-top: 50px !important;
   }
 }
 /*padding---------------------------------*/
 .pb0 {
   padding-bottom: 0 !important;
 }
 @include s.media-sp {
   .pb0 {
     padding-bottom: 0 !important;
   }
 }
 .pb10 {
   padding-bottom: 10px !important;
 }
 @include s.media-sp {
   .pb10 {
     padding-bottom: 5px !important;
   }
 }
 .pb20 {
   padding-bottom: 20px !important;
 }
 @include s.media-sp {
   .pb20 {
     padding-bottom: 10px !important;
   }
 }
 .pb30 {
   padding-bottom: 30px !important;
 }
 @include s.media-sp {
   .pb30 {
     padding-bottom: 15px !important;
   }
 }
 .pb40 {
   padding-bottom: 40px !important;
 }
 @include s.media-sp {
   .pb40 {
     padding-bottom: 20px !important;
   }
 }
 .pb50 {
   padding-bottom: 40px !important;
 }
 @include s.media-sp {
   .pb50 {
     padding-bottom: 25px !important;
   }
 }
 .pb60 {
   padding-bottom: 60px !important;
 }
 @include s.media-sp {
   .pb60 {
     padding-bottom: 30px !important;
   }
 }
 .pb80 {
   padding-bottom: 80px !important;
 }
 @include s.media-sp {
   .pb80 {
     padding-bottom: 40px !important;
   }
 }
 .pb100 {
   padding-bottom: 100px !important;
 }
 @include s.media-sp {
   .pb100 {
     padding-bottom: 50px !important;
   }
 }
 .pt0 {
   padding-top: 0 !important;
 }
 @include s.media-sp {
   .pt0 {
     padding-top: 0 !important;
   }
 }
 .pt10 {
   padding-top: 10px !important;
 }
 @include s.media-sp {
   .pt10 {
     padding-top: 5px !important;
   }
 }
 .pt20 {
   padding-top: 20px !important;
 }
 @include s.media-sp {
   .pt20 {
     padding-top: 10px !important;
   }
 }
 .pt30 {
   padding-top: 30px !important;
 }
 @include s.media-sp {
   .pt30 {
     padding-top: 15px !important;
   }
 }
 .pt40 {
   padding-top: 40px !important;
 }
 @include s.media-sp {
   .pt40 {
     padding-top: 20px !important;
   }
 }
 .pt50 {
   padding-top: 50px !important;
 }
 @include s.media-sp {
   .pt50 {
     padding-top: 25px !important;
   }
 }
 .pt60 {
   padding-top: 60px !important;
 }
 @include s.media-sp {
   .pt60 {
     padding-top: 30px !important;
   }
 }
 .pt80 {
   padding-top: 80px !important;
 }
 @include s.media-sp {
   .pt80 {
     padding-top: 40px !important;
   }
 }
 .pt100 {
   padding-top: 100px !important;
 }
 @include s.media-sp {
   .pt100 {
     padding-top: 50px !important;
   }
 }
 /*only---------------------------------*/
 .pc_only {
   display: block;
 }
 @include s.media-origin-x-sm {
   .pc_only {
     display: none !important;
   }
 }
 .sp_only {
   display: none;
 }
 @include s.media-sp {
   .sp_only {
     display: block !important;
   }
 }
 @include s.media-pc {
   div#main-column > section {
       // width: 1160px;
       // max-width: 100%;
       width: 100%;
       // max-width: 120rem;
       // margin: auto;
   }
   div.half-cont_wrap01, div.half-cont_wrap02 {
       // width: 1160px;
       // max-width: 100%;
       width: 100%;
       max-width: 120rem;
       margin: auto;
   }
 }
